/* eslint-disable no-console */
import React from 'react';
import {
  RadialBarChart,
  RadialBar,
  Legend,
  ResponsiveContainer,
  Tooltip
} from 'recharts';

import {RadialChartStyle} from './Constants/RadialChartStyle';
import RadialChartProps from './Interfaces/RadialChartProps';
import RadialCustomizedLabel from './Layouts/RadialCustomizedLabel';
import RadialCustomizedLegend from './Layouts/RadialCustomizedLegend';

const RadialChart: React.FC<{data: RadialChartProps[]}> = ({data}) => {
  return (
    <ResponsiveContainer width='100%' aspect={1} className='radialBarChart'>
      <RadialBarChart
        cx='125'
        cy='125'
        innerRadius={50}
        outerRadius={125}
        barSize={5}
        data={data}
        startAngle={450}
        endAngle={90}
      >
        <RadialBar
          background
          dataKey='value'
          strokeLinecap='round'
          strokeLinejoin='round'
          label={(props) => <RadialCustomizedLabel {...props} />}
        />
        <Legend
          iconSize={7}
          layout='vertical'
          verticalAlign='middle'
          wrapperStyle={RadialChartStyle}
          iconType='circle'
          content={<RadialCustomizedLegend data={data} />}
        />
        <Tooltip
          // separator=':'
          cursor={false}
          wrapperStyle={{
            backgroundColor: 'white',
            borderRadius: '25px'
          }}
          filterNull
          itemStyle={{
            borderRadius: '25px'
          }}
          formatter={(value) => {
            return [`${value} %`, 'Emissions'];
          }}
          labelFormatter={(_, name: any) => {
            return `${name?.[0]?.payload?.name || ''}`;
          }}
        />
      </RadialBarChart>
    </ResponsiveContainer>
  );
};

export default RadialChart;
