import FootPrintData, {Locations} from 'Components/Footprint/Interfaces/FootPrintData';

import ListWidgetData from '../../Charts/Interfaces/ListWidgetData';
import SimpleCardProps from '../../Charts/Interfaces/SimpleCardProps';
import {Tabs} from '../Interfaces/Widget';
import {WidgetConfigItem} from '../Interfaces/WidgetConfig';

/**
 * Retrieves the data for the cards widget based on the provided configuration and footprint data.
 *
 * @param item - The array of tabs.
 * @param chartConfig - The chart configuration.
 * @param year - The selected year.
 * @param footPrintData - The footprint data.
 * @param locationId - The selected location ID.
 * @returns The data for the cards widget.
 */
const getCardsData = (
  item: Tabs[],
  chartConfig: WidgetConfigItem[],
  year: string,
  footPrintData: FootPrintData,
  locationId: string
): SimpleCardProps => {
  const cardData: SimpleCardProps = {emissionData: [], locations: []};
  const arrayData: ListWidgetData[] = footPrintData.emissionsData?.[year] ?? [];

  const newArray = arrayData.filter((i) => i.locationId === locationId);
  const locationGroup = footPrintData.locations.reduce((r: { [x: string]: Locations[] }, item: Locations) => {
    const {title, addressLine1, addressLine2, city, state, country, zipCode, _id} = item;
    r[city] = [...(r[city] || []), {title, addressLine1, addressLine2, city, state, country, zipCode, _id}];

    return r;
  }, {});

  cardData.locations = locationGroup;
  chartConfig.forEach((l: WidgetConfigItem) => {
    const label = item.find((c) => c.dataId === l.id)?.label ?? '';
    const fill = l.color;
    const child = newArray.filter((c) => l.category?.includes(c.dataId));

    cardData.emissionData.push({label, fill, child});
  });

  return cardData;
};

export default getCardsData;
