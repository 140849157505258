import {Box} from '@mui/material';
import {options} from 'Components/Shared/Constants/FormBuilder';
import FormBuilder from 'Components/Shared/FormBuilder';
import React from 'react';

const Principle7: React.FC = () => {
  const companyId = window?.bharatCarbon?.company?._id;
  const handleSubmit = (data: any) => {
    data.companyId = companyId;
    // eslint-disable-next-line no-console
    console.log(data);
  };
  const {Sr_no} = options;

  return (
    <Box sx={{padding: '30px 24px 24px 24px'}}>
      <FormBuilder
        fields={[
          {type: 'div', label: 'ESSENTIAL INDICATORS', key: ''},
          {
            type: 'textarea',
            label:
              'Number of affiliations with trade and industry chambers/ associations.',
            key: 'numberOfAffiliations'
          },
          {
            type: 'table',
            label: `List the top 10 trade and industry chambers/ associations (determined based on the total members
                of such body) the Company is a member of/ affiliated to.`,
            key: 'top10Trade',
            options: [
              {
                type: 'text',
                label: Sr_no
              },
              {
                type: 'textarea',
                label: `Name of the trade and industry 
                chambers/ associations`,
                key: 'tradeName'
              },
              {
                type: 'select',
                label: `Reach of trade and industry chambers/
                associations`,
                key: 'tradeReach',
                selectOption: ['National', 'State']
              }
            ]
          },
          {
            type: 'textarea',
            label: `Details of corrective action taken or underway on any issues related to anticompetitive conduct by
            the Company, based on adverse orders from regulatory authorities.`,
            key: 'adverseOrders'
          },
          {
            type: 'div',
            label: 'LEADERSHIP INDICATORS',
            key: ''
          },
          {
            type: 'textarea',
            label: `Details of public policy positions
             advocated by the Company.`,
            key: 'publicPolicyPositions'
          }
        ]}
        title='Principle 7 : Businesses, When Engaging In Influencing
         Public And Regulatory Policy, Should Do So In A Manner That Is Responsible And Transparent'
        onSubmit={handleSubmit}
      />
    </Box>
  );
};

export default Principle7;
