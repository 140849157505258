import {Box} from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import * as React from 'react';

interface Column {
  id:
    | 'Sr'
    | 'title'
    | 'buildUpInft'
    | 'basePrice'
    | 'additionalFees'
    | 'amount';
  label: string;
  minWidth?: number;
  align: 'center' | 'right' | 'left';
  format?: (value: number) => string;
}

const columns: readonly Column[] = [
  {id: 'Sr', label: '#', minWidth: 170, align: 'center'},
  {id: 'title', label: 'Locations', minWidth: 100, align: 'center'},
  {
    id: 'buildUpInft',
    label: 'Total Area (Sq ft)',
    minWidth: 170,
    align: 'center',
    format: (value: number) => {
      return `${Math.round(value).toLocaleString('en-IN')} `;
    }
  },
  {
    id: 'basePrice',
    label: 'Base Price',
    minWidth: 170,
    align: 'center',
    format: (value: number) => {
      return `₹ ${Math.round(value).toLocaleString('en-IN')} `;
    }
  },
  {
    id: 'additionalFees',
    label: 'Additional Fee',
    minWidth: 170,
    align: 'center',
    format: (value: number) => {
      return `₹ ${Math.round(value).toLocaleString('en-IN')} `;
    }
  },
  {
    id: 'amount',
    label: 'Total',
    minWidth: 170,
    align: 'center',
    format: (value: number) => {
      return `₹ ${Math.round(value).toLocaleString('en-IN')} `;
    }
  }
];

const LocationPayments: React.FC<{data: any[]}> = ({data}) => {
  return (
    <Box className='payTable'>
      <TableContainer sx={{maxHeight: 150}}>
        <Table stickyHeader aria-label='sticky table'>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column.id} align={column.align}>
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map((row, index) => {
              return (
                <TableRow hover role='checkbox' tabIndex={-1} key={index}>
                  {columns.map((column) => {
                    const value =
                      column.id === 'title'
                        ? row.locationId[column.id]
                        : row[column.id];

                    return (
                      <TableCell key={column.id} align={column.align}>
                        {column.id === 'Sr'
                          ? index + 1
                          : column.format
                          ? column.format(value)
                          : value}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {/* <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component='div'
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      /> */}
    </Box>
  );
};

export default LocationPayments;
