import React from 'react';

import RenderCustomizedLabelScopeProps from '../Interfaces/RenderCustomizedLabelScopeProps';

const RenderCustomizedLabelScope: React.FC<RenderCustomizedLabelScopeProps>  = ({
    cx,
    cy,
    midAngle,
    outerRadius,
    payload,
    value
}) => {
    const RADIAN = Math.PI / 180;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 2) * cos;
    const sy = cy + (outerRadius + 2) * sin;
    const mx = cx + (outerRadius + 10) * cos;
    const my = cy + (outerRadius + 10) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 20;
    const ey = my;
    const textAnchor = cos >= 0 ? 'start' : 'end';

  return (
    <g>
      <path
        d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
        stroke={'#99B1C7'}
        fill="none"
		strokeDasharray='2 2'
      />
      <circle cx={ex} cy={ey} r={2} fill={'#99B1C7'} stroke="none" />
      <text
        style={{fontWeight: 'bold', fontSize: '8px', textTransform: 'uppercase'}}
        x={ex + (cos >= 0 ? 1 : -1) * 5}
        y={ey}
        textAnchor={textAnchor}
        fill={'#99B1C7'}
      >
        {payload.name}
      </text>
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 5}
        y={ey}
        dy={18}
        textAnchor={textAnchor}
        fill={'#99B1C7'}
		style={{fontWeight: '300',fontSize: '18px'}}
      >
        {value}%
      </text>
    </g>
  );
};

export default RenderCustomizedLabelScope;
