/* eslint-disable no-console */
import InitiativeValues from 'Components/Shared/Interfaces/Initiatives';
import {Kpi} from 'Components/Shared/Interfaces/Kpis';
import {Dispatch} from 'redux';
import getInitiativeScore from 'Services/GetInitiativeScore';
import createProgressStore from 'State/Stores/Progress/createProgressStore';

import createInitiavtiveValueObject from './createInitiavtiveValueObject';

/**
 * Handles the progress store by updating the values based on the provided data.
 *
 * @param dispatch - The dispatch function from Redux.
 * @param data - An array of Kpi objects.
 * @returns A Promise that resolves once the store has been updated.
 */
const handleProgressStore: (dispatch: Dispatch, data: Kpi[]) => Promise<void> = async (
  dispatch: Dispatch,
  data: Kpi[]
) => {
  const initiativeScore = await getInitiativeScore();

  data.forEach((item) => {
    const kpi = initiativeScore?.find((i) => i._id === item._id);
    let initialValues = [] as InitiativeValues[];
    if (kpi !== undefined) {
      initialValues = createInitiavtiveValueObject(kpi.initiatives, item.initiatives, item.displayIndex);
    }

    dispatch(
      createProgressStore?.actions?.updateValues({
        _id: item._id,
        initiativeValues: initialValues,
        kpiName: item.kpiName,
        y: item.displayIndex * 20,
        icon: item.icon,
        priority: kpi?.priority as string
      })
    );
  });
};

export default handleProgressStore;
