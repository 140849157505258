/* eslint-disable no-console */
import {useAuth0} from '@auth0/auth0-react';
import {Box, Grid} from '@mui/material';
import Action from 'Components/Action/Action';
import Dashboard from 'Components/Dashboard/Dashboard';
import BRSR from 'Components/ESG/Framework/BRSR/Index';
import SectionA from 'Components/ESG/Framework/BRSR/SectionA/SectionA';
import SectionB from 'Components/ESG/Framework/BRSR/SectionB/SectionB';
import Principle1 from 'Components/ESG/Framework/BRSR/SectionC/Principle1';
import Principle2 from 'Components/ESG/Framework/BRSR/SectionC/Principle2';
import Principle3 from 'Components/ESG/Framework/BRSR/SectionC/Principle3';
import Principle4 from 'Components/ESG/Framework/BRSR/SectionC/Principle4';
import Principle5 from 'Components/ESG/Framework/BRSR/SectionC/Principle5';
import Principle6 from 'Components/ESG/Framework/BRSR/SectionC/Principle6';
import Principle7 from 'Components/ESG/Framework/BRSR/SectionC/Principle7';
import Principle8 from 'Components/ESG/Framework/BRSR/SectionC/Principle8';
import Principle9 from 'Components/ESG/Framework/BRSR/SectionC/Principle9';
import Reporting from 'Components/ESG/Framework/Index';
import Footprint from 'Components/Footprint/Footprint';
import GuestDash from 'Components/Guest/GuestDashboard';
import Help from 'Components/Help/Help';
import PaymentSuccessful from 'Components/Payment/Layouts/PaymentSuccessful';
import PaymentUnSuccessful from 'Components/Payment/Layouts/PaymentUnSuccessful';
import Payment from 'Components/Payment/Payment';
import Progress from 'Components/Progress/Progress';
import ScheduleCall from 'Components/ScheduleCall/ScheduleCall';
import RoutesURLs from 'Components/Shared/Constants/Routes';
import SnackbarMessages from 'Components/Shared/Constants/SnackbarMessages';
import SnackbarStatus from 'Components/Shared/Constants/SnackbarStatus';
import AuthUserInterface from 'Components/Shared/Interfaces/AuthUserInterface';
import SnackbarInterface from 'Components/Shared/Interfaces/Snackbar';
import Loader from 'Components/Shared/Loader';
import Snackbar from 'Components/Shared/Snackbar';
import Topbar from 'Components/Shared/Topbar';
import SideNav from 'Components/SideNav/SideNav';
import SubscriptionInfo from 'Components/SubscriptionInfo/SubscriptionInfo';
import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {Navigate, Route, Routes, useLocation} from 'react-router-dom';
import connectSubscriptions, {
  connectWidgetSub
} from 'State/connectSubscriptions';

import 'Styles/App.scss';
import 'Libraries/I18N/config';

const App: React.FC = () => {
  const {
    user,
    error,
    isLoading,
    isAuthenticated,
    getIdTokenClaims,
    loginWithRedirect
  } = useAuth0();

  const location = useLocation();

  const currentPath = location.pathname;

  const [validated, setValidated] = React.useState<boolean>(false);
  const [isGuest, setIsGuest] = React.useState<boolean>(true);
  const [showTour, setShowTour] = React.useState<boolean>(false);

  const dispatch = useDispatch();
  const {state} = useLocation();

  const [snackbarMessage, setSnackbarMessage] = useState<SnackbarInterface>({
    open: false,
    status: SnackbarStatus.SUCCESS,
    message: SnackbarMessages.NONE
  });

  const withoutAuth = RegExp(/payment/).test(window.location.href);

  useEffect(() => {
    if (withoutAuth) {
      setValidated(true);

      return;
    }
    if (!isLoading) {
      if (error) {
        setSnackbarMessage({
          open: true,
          status: SnackbarStatus.WARNING,
          message: SnackbarMessages.COMMONERROR
        });
        setTimeout(() => {
          loginWithRedirect();
        }, 5000);
      }

      if (isAuthenticated) {
        if (!localStorage.getItem('isLogged')) {
          localStorage.setItem('isLogged', 'true');
          setSnackbarMessage({
            open: true,
            status: SnackbarStatus.SUCCESS,
            message: SnackbarMessages.LOGINSUCCESS
          });
        }
        connectSubscriptions(
          dispatch,
          user as AuthUserInterface,
          getIdTokenClaims
        ).then(() => {
          if (!window.bharatCarbon.userInfo.isGuest) {
            connectWidgetSub(dispatch).then(() => {
              setIsGuest(window.bharatCarbon.userInfo.isGuest);
              setValidated(true);
            });
          } else {
            setIsGuest(window.bharatCarbon.userInfo.isGuest);
            setValidated(true);
          }
        });
      } else {
        loginWithRedirect();
      }
    }
  }, [
    dispatch,
    error,
    getIdTokenClaims,
    isAuthenticated,
    isLoading,
    loginWithRedirect,
    user,
    state
  ]);

  return !validated ? (
    <Loader />
  ) : (
    <Box className='wrapper'>
      <Snackbar open={snackbarMessage} setOpen={setSnackbarMessage} />
      {isAuthenticated && !withoutAuth && <SideNav setShowTour={setShowTour} />}
      <Grid container className='content-section'>
        {isAuthenticated && !withoutAuth && <Topbar />}
        {isAuthenticated && !withoutAuth && showTour && (
          <Help
            currentPath={currentPath}
            isGuest={isGuest}
            setShowTour={setShowTour}
            showTour={showTour}
          />
        )}
        <Routes>
          <Route path={RoutesURLs.PAYMENT} element={<Payment />} />
          <Route
            path={RoutesURLs.PAYMENT_SUCCESSFULL}
            element={<PaymentSuccessful />}
          />
          <Route
            path={RoutesURLs.PAYMENT_FAILED}
            element={<PaymentUnSuccessful />}
          />
          {isGuest && isAuthenticated ? (
            <>
              <Route path={RoutesURLs.DASHBOARD} element={<GuestDash />} />
              <Route
                path={RoutesURLs.SCHEDULE_CALL}
                element={
                  <ScheduleCall setSnackbarMessage={setSnackbarMessage} />
                }
              />
              <Route
                path={RoutesURLs.HOME}
                element={<Navigate to={RoutesURLs.DASHBOARD} />}
              />
              <Route path='/try' element={<SectionA />} />
            </>
          ) : (
            <>
              <Route path={RoutesURLs.DASHBOARD} element={<Dashboard />} />
              <Route path={RoutesURLs.FOOTPRINT} element={<Footprint />} />
              <Route path={RoutesURLs.YOUR_PROGRESS} element={<Progress />} />
              <Route path={RoutesURLs.ACTION} element={<Action />} />
              <Route
                path={RoutesURLs.SUBSCRIPTION_INFO}
                element={<SubscriptionInfo />}
              />
              <Route path={RoutesURLs.ESG_REPORTING}>
                <Route index element={<Reporting />} />
                <Route path={`${RoutesURLs.BRSR}`}>
                  <Route index element={<BRSR />} />
                  <Route path={RoutesURLs.SECTIONA} element={<SectionA />} />
                  <Route path={RoutesURLs.SECTIONB} element={<SectionB />} />
                  <Route path={RoutesURLs.SECTIONC}>
                    <Route path={RoutesURLs.P1} element={<Principle1 />} />
                    <Route path={RoutesURLs.P2} element={<Principle2 />} />
                    <Route path={RoutesURLs.P3} element={<Principle3 />} />
                    <Route path={RoutesURLs.P4} element={<Principle4 />} />
                    <Route path={RoutesURLs.P5} element={<Principle5 />} />
                    <Route path={RoutesURLs.P6} element={<Principle6 />} />
                    <Route path={RoutesURLs.P7} element={<Principle7 />} />
                    <Route path={RoutesURLs.P8} element={<Principle8 />} />
                    <Route path={RoutesURLs.P9} element={<Principle9 />} />
                  </Route>
                </Route>
              </Route>
            </>
          )}
        </Routes>
      </Grid>
    </Box>
  );
};

export default App;
