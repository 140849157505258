import {configureStore, combineReducers} from '@reduxjs/toolkit';
import {ToolkitStore} from '@reduxjs/toolkit/dist/configureStore';

/** Importing the different store configurations */
import createActionStore from './Stores/Action/createActionStore';
import createContinentStore from './Stores/Action/createContinentStore';
import createCountryStore from './Stores/Action/createCountryStore';
import createIndustryTypeStore from './Stores/Action/createIndustryTypeStore';
import createProjectStore from './Stores/Action/createProjectStore';
import createFootprintStore from './Stores/Footprint/createFootprintStore';
import createLocationStore from './Stores/Footprint/createLocationStore';
import createProgressStore from './Stores/Progress/createProgressStore';
import createCompanyStore from './Stores/User/createCompanyStore';
import createUserStore from './Stores/User/createUserStore';
import createCommunityStore from './Stores/Widget/createCommunityStore';
import createWidgetConfigurationStore from './Stores/Widget/createWidgetConfigurationStore';
import createWidgetDataStore from './Stores/Widget/createWidgetDataStore';
import createWidgetStore from './Stores/Widget/createWidgetStore';
import createYearStore from './Stores/Widget/createYearStore';

/**
 * Creates the Redux state and returns the store.
 * @returns {ToolkitStore} The Redux store.
 */
const createState = (): ToolkitStore => {
  /** Combining the reducers from different store configurations */
  const rootReducer = combineReducers({
    user: createUserStore.reducer,
    company: createCompanyStore.reducer,
    kpiValues: createProgressStore.reducer,
    widgets: createWidgetStore.reducer,
    widgetConfig: createWidgetConfigurationStore.reducer,
    widgetData: createWidgetDataStore.reducer,
    years: createYearStore.reducer,
    footprintData: createFootprintStore.reducer,
    locations: createLocationStore.reducer,
    continent: createContinentStore.reducer,
    country: createCountryStore.reducer,
    industryTypes: createIndustryTypeStore.reducer,
    projects: createProjectStore.reducer,
    actionData: createActionStore.reducer,
    communityStore: createCommunityStore.reducer
  });

  /**  Configuring the store with the combined reducer */
  const store = configureStore({
    reducer: rootReducer
  });

  return store;
};

export default createState;
