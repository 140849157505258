import helpConstants from '../Constants/HelpConstant';

const getHelp: (currentPath: string, isGuest: boolean) => void = (
  currentPath,
  isGuest
) => {
  const guest = isGuest ? 'guest' : 'premium';

  const steps = helpConstants
    .filter((item) => {
      return item.type === guest;
    })?.[0]
    .state?.filter((item: any) => {
      return item?.location === currentPath;
    })?.[0].steps;

  return steps;
};

export default getHelp;
