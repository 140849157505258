import {calculatedKpiScore, Kpis} from 'Components/Shared/Interfaces/Kpis';

/**
 * Calculates the KPI score based on the provided KPI values.
 *
 * @param kpiValues - An array of KPI values.
 * @returns An array of calculated KPI scores.
 */
const calculateKPIScore = (kpiValues: Kpis[]): calculatedKpiScore[] => {
  const kpiTotal: calculatedKpiScore[] = [];

  kpiValues.forEach((item) => {
    let totalScore = 0;

    item.initiativeValues.forEach((initiative) => {
      if (initiative.checked) {
        const weightedScore = (initiative.weightage * initiative.value) / 100;
        totalScore += weightedScore;
      }
    });

    kpiTotal.push({
      _id: item._id,
      score: Math.round(totalScore),
      kpiName: item.kpiName,
      icon: item.icon,
      priority: item.priority
    });
  });

  return kpiTotal;
};

/**
 * Calculates the total average score from an array of calculated KPI scores.
 *
 * @param inputArray - An array of calculated KPI scores.
 * @returns The total average score.
 */
export const getTotalAverage = (inputArray: calculatedKpiScore[]): number => {
  const totalScore = inputArray.reduce((total, item) => total + item.score, 0);

  return Math.round(totalScore / inputArray.length);
};

export default calculateKPIScore;
