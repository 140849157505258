import {CircularProgress} from '@mui/material';
import React from 'react';

/**
 * Custom Loader Component
 * @component
 */
const Loader: React.FC = () => {
	return (
		<div className="loader">
			<CircularProgress color="success" className="flex" />
		</div>
	);
};

export default Loader;
