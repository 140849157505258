import PieChartScopeProps, {labelData} from '../../Charts/Interfaces/PieChartScopeProps';
import getChartDataProps from '../Interfaces/getChartDataProps';

import getDataBydataId from './getDataByDataId';

/**
 * Retrieves data based on the provided item, chartConfig and widget data.
 * The data retrieved is provided to the PieChart
 * 
 * @param item - widget item provided.
 * @param chartConfig - Configuration of the chart.
 * @param widgetData - The widget data.
 * @returns The retrieved data.
 */
const getPieChartScopeData = ({item, chartConfig, widgetData}: getChartDataProps): any => {
    const pieChartData: PieChartScopeProps[] = [];

    const arrayData: any[] = getDataBydataId(item?.dataId, item?.dataType, item?.chartType, widgetData);

    const totalPercentValue = arrayData?.reduce(function (acc: number, item: any) {
        return acc + item?.emissionIntensity;
    }, 0);

    chartConfig?.forEach(e => {
        const value = arrayData.find((o: {dataId: string}) => o.dataId === e.id).emissionIntensity;
        const unit = arrayData.find((o: {dataId: string}) => o.dataId === e.id).unit;
        const percentageValue = Math.round((value * 100) / totalPercentValue);
        const itemCatsIds = arrayData.find((o: {dataId: string}) => o.dataId === e.id).category;
        const itemCatsData: labelData[] = [];

        if (item?.dataType === 'kpiList') {
            itemCatsIds.forEach((l: string) => {
                const cat = (widgetData.emissionsData.categories)
                .find((o: {dataId: string}) => o.dataId === l);
                const catConfig = (e?.categoryData as any)
                .find((o: {id: string}) => o.id === l);
                const catData = Object.assign({name: catConfig.name}, cat);
                itemCatsData.push(catData);
            });
        } else if (item?.dataType === 'scopeList') {
            itemCatsIds.forEach((l: string) => {
                const cat = (widgetData.emissionsData.consumptionList)
                .find((o: {dataId: string}) => o.dataId === l);
                const catConfig = (e?.categoryData as any)
                .find((o: {id: string}) => o.id === l);
                const catData = Object.assign({name: catConfig.name}, cat);
                itemCatsData.push(catData);
            });
        }
        pieChartData.push({
            name: e?.name,
            fill: e?.color,
            value: percentageValue,
            labelData: itemCatsData,
            emissionIntensity: value,
            unit: unit
        });
    });

    return pieChartData;
};

export default getPieChartScopeData;
