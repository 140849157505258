import CloseIcon from '@mui/icons-material/Close';
import {Button} from '@mui/material';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import * as React from 'react';

import BasicModalProps from './Interfaces/BasicModalProps';

const style = {
    position: 'absolute' as const,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4
};


/**
 * Modal Component with the child component as ReactNode which will be shown
 * @component
 */
const BasicModal: React.FC<BasicModalProps> = ({open, max, setOpen, children}) => {
    const handleClose = () => setOpen(false);

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style} className={`modal-popup ${max ? 'modalpopupIMP' : null}`}>
                    <>
                        <Button className="modal-close" onClick={handleClose}>
                            <CloseIcon />
                        </Button>
                        {children}
                    </>
                </Box>
            </Modal>
        </div>
    );
};

export default BasicModal;
