import {Grid, Input, Slider, Checkbox} from '@mui/material';
import React, {useEffect, useState} from 'react';
import postInitiativeScore from 'Services/PostInitiativeScore';

import {marks, valueLabelFormat} from '../Constants/Slider';
import SliderProps from '../Interfaces/SliderProps';

/**
 * Initiative Component is for single initiative
 * It displays Initiative name, value and slider to change value
 * @component
 */
const InitiativeComponent: React.FC<SliderProps> = ({
  _id,
  item,
  handleInitiavtiveValue,
  changedInitiavtive
}) => {
  const [checked, setChecked] = useState(changedInitiavtive?.checked || false);
  const [valueNew, setValueNew] = useState<number | number[]>(
    changedInitiavtive?.value || 0
  );

  const handleSliderChange = (event: Event, newValue: number | number[]) => {
    setValueNew(newValue);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
    const score = event.target.checked ? valueNew : 0;
    !event.target.checked &&
      postInitiativeScore({kpiId: _id, initiativeId: item._id, score});
    valueNew !== 0 &&
      event.target.checked &&
      postInitiativeScore({kpiId: _id, initiativeId: item._id, score});
    handleInitiavtiveValue({
      value: Number(valueNew),
      checked: event.target.checked,
      _id: item?._id,
      weightage: item.weightage,
      initiativeName: item?.name
    });
  };

  const finalValue = () => {
    handleInitiavtiveValue({
      value: Number(valueNew),
      checked,
      _id: item?._id,
      weightage: item.weightage,
      initiativeName: item?.name
    });
    postInitiativeScore({
      kpiId: _id,
      initiativeId: item._id,
      score: valueNew
    });
  };

  useEffect(() => {
    setValueNew(changedInitiavtive?.value || 0);
    setChecked(changedInitiavtive?.checked || false);
  }, [changedInitiavtive]);

  return (
    <Grid className='tabsection' spacing={1} container display='flex'>
      <Grid display='flex' alignItems='center' item xs={12} sm={8}>
        <Checkbox
          checked={checked}
          onChange={handleChange}
          inputProps={{'aria-label': 'controlled'}}
        />
        <div>{item.name}</div>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Grid display='flex' justifyContent='space-between' alignItems='center'>
          <Grid className='sliderDiv' item xs>
            <Slider
              disabled={!checked}
              size='small'
              aria-label='Small'
              value={valueNew}
              valueLabelDisplay='auto'
              onChange={handleSliderChange}
              valueLabelFormat={valueLabelFormat}
              onChangeCommitted={finalValue}
              marks={marks}
              min={0}
              max={100}
            />
          </Grid>
          <Grid className='sliderInput' item>
            <Input size='small' value={valueNew} disabled />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default InitiativeComponent;
