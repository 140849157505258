/* eslint-disable no-console */
import {
  FormControl,
  Select as SelectComponent,
  SelectChangeEvent,
  ListSubheader,
  MenuItem
} from '@mui/material';
import {Locations} from 'Components/Footprint/Interfaces/FootPrintData';
import React from 'react';

import Tooltip from './Tooltip';

interface GroupSelectProps {
  selectedValue: string;
  setSelectedValue: React.Dispatch<React.SetStateAction<string>>;
  options: {[key: string]: Locations[]};
  disabled?: boolean;
}

/**
 * GroupSelect component renders the select tag with
 * options grouped, for example here locations are shown by grouping into cities.
 * @component
 */
const GroupSelect: React.FC<GroupSelectProps> = ({
  selectedValue,
  setSelectedValue,
  options,
  disabled
}) => {
  const handleSelectChange = (event: SelectChangeEvent) => {
    setSelectedValue(event?.target?.value);
  };

  const renderSelectGroup = (product: [string, Locations[]]) => {
    const items = product[1].map((p) => {
      return (
        <MenuItem key={p._id} value={String(p._id)}>
          {p.title}{' '}
          <Tooltip
            message={
              <span style={{whiteSpace: 'pre-line'}}>
                {p.title +
                  '\n' +
                  (p.addressLine1 ? p.addressLine1 : p.addressLine2) +
                  '\n' +
                  p.city +
                  ' ' +
                  p.state +
                  ' ' +
                  p.zipCode +
                  ' ' +
                  p.country}
              </span>
            }
          />
        </MenuItem>
      );
    });

    return [<ListSubheader>{product[0]}</ListSubheader>, items];
  };

  return (
    <FormControl sx={{m: 1, minWidth: 120}} className='groupSelect'>
      <SelectComponent
        value={selectedValue || ''}
        onChange={handleSelectChange}
        disabled={disabled}
        displayEmpty
        inputProps={{'aria-label': 'Without label'}}
      >
        {Object.entries(options)?.map((p) => renderSelectGroup(p))}
      </SelectComponent>
    </FormControl>
  );
};

export default GroupSelect;
