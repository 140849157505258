import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import YearState from 'Components/Widgets/Constants/YearState';
import WidgetData from 'Components/Widgets/Interfaces/WidgetData';


/**
 * Redux slice for the year store.
 */
const createYearStore = createSlice({
	name: 'years',
	initialState: YearState,
	/**
	 * Sets the widget in the state.
	 * @param {YearState} state - The current state.
	 * @param {PayloadAction<WidgetData>} action - The action containing the widget payload.
	 */
	reducers: {
		setYears: (state, action: PayloadAction<WidgetData[]>) => {

			action?.payload?.sort(function(a, b) {
				const textA = a.year;
				const textB = b.year;
				
				return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
			});

			action?.payload?.forEach(i => {
                state.push(i.year);
            });
		}
	}
});

// Export the store
export default createYearStore;
