import {Box, Typography} from '@mui/material';
import {Locations} from 'Components/Footprint/Interfaces/FootPrintData';
import GroupSelect from 'Components/Shared/GroupSelect';
import Select from 'Components/Shared/Select';
import Tooltip from 'Components/Shared/Tooltip';
import React from 'react';
import {useSelector} from 'react-redux';
import StoreInterface from 'State/Interfaces/StoreInterface';

import WidgetTitileProps from '../Interfaces/WidgetTitleProps';

/**
 * WidgetTitle Component is the common used for all the widgets
 * which shows title, year and
 * @component
 */
const WidgetTitle: React.FC<WidgetTitileProps> = ({
  name,
  yearValue,
  setYearValue,
  locationEnabled,
  locationData,
  locationId,
  setLocationId,
  TooltipMessage
}) => {
  const years = useSelector((state: StoreInterface) => state.years);

  return (
    <>
      <Box className='card-title'>
        <Typography>{name}</Typography>
        <Box className='flex-acenter dashTitle-dropdown'>
          {locationEnabled ? (
            <GroupSelect
              selectedValue={locationId as string}
              setSelectedValue={
                setLocationId as React.Dispatch<React.SetStateAction<string>>
              }
              options={locationData as {[key: string]: Locations[]}}
            />
          ) : (
            <></>
          )}
          <Select
            selectedValue={yearValue}
            setSelectedValue={setYearValue}
            options={years}
          />
          <Tooltip message={TooltipMessage} />
        </Box>
      </Box>
    </>
  );
};

export default WidgetTitle;
