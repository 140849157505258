import {Box, Typography} from '@mui/material';
import TooltipMessage from 'Components/Shared/Constants/TooltipMessages';
import React from 'react';
import {useSelector} from 'react-redux';
import StoreInterface from 'State/Interfaces/StoreInterface';

import Widget from '../Interfaces/Widget';
import WidgetData from '../Interfaces/WidgetData';
import checkFootprinting from '../Utils/checkFootprinting';
import filterBenchmarksByIndustry from '../Utils/filterBenchmarksByIndustry';

import ReturnChartComponent from './ReturnChartComponent';
import WidgetTitle from './WidgetTitle';

/**
 * Multigraph Widget Component
 * @component
 */
const MultiGraphWidget: React.FC<Widget> = (widget) => {
  const years = useSelector((state: StoreInterface) => state.years);
  const industry = useSelector((state: StoreInterface) => state.company);
  const [yearValue, setYearValue] = React.useState<string>(
    years?.[years.length - 1]
  );
  const widgetData = useSelector(
    (state: StoreInterface) => state.widgetData
  ).find((i) => i.year === yearValue) as WidgetData;

  const notFootprinting = checkFootprinting(widgetData.emissionsData.scopeList);

  return (
    <Box className='dash-cards multi-graph' id='dash-third'>
      <WidgetTitle
        name={`Our ${
          industry.category.charAt(0).toUpperCase() + industry.category.slice(1)
        } Benchmarks`}
        yearValue={yearValue}
        setYearValue={setYearValue}
        TooltipMessage={TooltipMessage.DASHBOARD_BENCHMARK}
      />
      <Box className='card-data'>
        {!notFootprinting ? (
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              height: '250px',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            Please Be patient , your data collection is on
          </Box>
        ) : (
          widget?.graphs?.map((item, index) => {
            const newItem = filterBenchmarksByIndustry(industry.category, item);

            return newItem === null ? null : (
              <Box key={index} className='multi-graph-item'>
                <ReturnChartComponent
                  year={yearValue}
                  item={newItem.tabs[0]}
                  widget={widget}
                />
              </Box>
            );
          })
        )}
      </Box>
      {widget?.graphs ? (
        <>
          <Box display='flex' marginTop='10px' marginLeft='20px'>
            <Box className='flex-acenter'>
              <Box className='listIcon'></Box>
              <Typography
                sx={{fontSize: '12px', color: '#B3B8BD', marginRight: '20px'}}
              >
                Industry Standards
              </Typography>
            </Box>
            <Box className='flex-acenter'>
              <Box className='listIcon stripe'></Box>
              <Typography
                sx={{fontSize: '12px', color: '#B3B8BD', marginRight: '20px'}}
              >
                Above Industry Standards
              </Typography>
            </Box>
          </Box>
        </>
      ) : (
        <></>
      )}
    </Box>
  );
};

export default MultiGraphWidget;
