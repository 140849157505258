/* eslint-disable max-len */

const TooltipMessage = {
  DASHBOARD_GHG_EMISSIONS: `The company's Total GHG Emissions is a 
  measurement of TCO2e. CO2 Equivalent Measure is used to standardize the global warming effects of various greenhouse gases.`,
  DASHBOARD_GHG_BY_SCOPE: `The company's operational activities 
  are clustered based on scopes. Scope Emissions are classified in three scopes, each representing who “owns” the emissions.`,
  DASHBOARD_SCOPE_1:
    'Scope 1 emissions result from the direct combustion of fuels, such as operating gasoline-powered cars or heating buildings with oil or natural gas, and from the use of certain chemicals',
  DASHBOARD_SCOPE_2:
    'Scope 2 emissions are associated with the purchase of electricity, steam, heating, and cooling.',
  DASHBOARD_SCOPE_3:
    'Scope 3 emissions are the activities and assets not owned or controlled by an organization, but on which the organization relies to operate.',
  DASHBOARD_BENCHMARK:
    'The benchmark is to provide the company with additional metrics and scales to compare its performance with industry averages.',
  // DASHBOARD_BENCHMARK_RETAIL:
  //   'The benchmark is to provide the company with additional metrics and scales to compare its performance with industry averages.',
  // DASHBOARD_BENCHMARK_RESTAURANT:
  //   'The benchmark is to provide the company with additional metrics and scales to compare its performance with industry averages.',
  PROGRESS_KPI: `Keep track of how your initiatives improve over time with 
  an easy progression from 'needs improvement' to 'prioritizing earth'.`,
  PROGRESS_SUSTANIBILITY_SCORE: `The sustainability score gives the initiatives undertaken by your company combined with tracking your progress under each initiative, a score of 1 to 100. It is a cumulative scale 
  of what your company does to ensure sustainability within the organization.`,
  PROGRESS_IMPACT_GRAPH: `Keep track of how your initiatives improve over time with an 
  easy progression from 'needs improvement' to 'prioritizing earth.'`,
  CALCULATE_FOOTPRINT:
    'Test out our calculator. Input your electricity consumption and see how much your Scope 2 emissions are.',
  GUEST_BENCHMARK: `The benchmark represents a (hotel, restaurant or retail) outlet based on your specific size or rating. Find out how you 
  compare to a similiar (hotel, restaurant or retail) outlet.`,
  FOOTPRINT_GHG_EMISSION: `The depiction in this graph is to showcase carbon 
  emissions throughout time. This representation can uncover activities, or events which produced emissions at a company level.`,
  FOOTPRINT_LOCATION_PERFORMANCE: `The company's carbon 
  footprint per individual activity 
  per location and scope is shown in this graph.`,
  FOOTPRINT_LIST_OF_ACTIVITIES: `The company's carbon footprint 
  per individual activity per location and as a percentage of the total Scope and benchmark.`,
  TAKE_ACTION_EMISSIONS_REDUCTIONS:
    'This graph shows your journey to net zero and shows you how your reduction activities affect your company`s emissions positively.',
  ACTION_UNF_PROJECT:
    'This map shows all the global carbon offset projects and initiatives we have in our inventory for your company to purchase and reduce emissions today.',
  CARBON_POSITIVE:
    'This outlet has lesser emissions compared to the company & industry benchmark.',
  CARBON_NEGATIVE:
    'This outlet has higher emissions compared to the company & industry benchmark.'
};

export default TooltipMessage;
