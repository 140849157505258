/* eslint-disable no-console */
import {Grid} from '@mui/material';
import calculateTotal from 'Components/Dashboard/Utils/calulateTotal';
import WidgetData from 'Components/Widgets/Interfaces/WidgetData';
import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {connectAction} from 'State/connectSubscriptions';
import StoreInterface from 'State/Interfaces/StoreInterface';

import ActionDataInterface from './Interface/ActionData';
import Credits from './Layouts/Credits';
import ReductionChart from './Layouts/ReductionChart';
import UNFProjects from './Layouts/UNFProjects';
import getActionChart from './Utils/getActionChart';

/**
 * Action component.
 * @component
 */
const Action: React.FC = () => {
  const dispatch = useDispatch();
  const actionCheck = useSelector((state: StoreInterface) => state.actionData);
  useEffect(() => {
    if (actionCheck) {
      connectAction(dispatch);
    }
  }, []);
  const years = useSelector((state: StoreInterface) => state.years);
  const [yearValue, setYearValue] = React.useState<string>(
    years?.[years.length - 1]
  );

  const widgetData = useSelector(
    (state: StoreInterface) => state.widgetData
  ).find((i) => i.year === yearValue) as WidgetData;

  const actionData = useSelector(
    (state: StoreInterface) => state.actionData
  ).find((action) => action.year === yearValue) as ActionDataInterface;

  const totalAndUnit = calculateTotal(widgetData.emissionsData.scopeList);

  const chartData = getActionChart(actionData, totalAndUnit);

  return (
    <Grid container>
      <Grid container>
        <Grid item xs={12} sm={7} id='tc-first'>
          <Credits totalAndUnit={totalAndUnit} actionData={actionData} />
        </Grid>
        <Grid item xs={12} sm={5} id='tc-second'>
          <ReductionChart
            yearValue={yearValue}
            setYearValue={setYearValue}
            chartData={chartData}
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid
          className='dash-container'
          item
          xs={12}
          sx={{padding: '5px 10px 10px 20px'}}
          id='tc-third'
        >
          <UNFProjects />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Action;
