import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {Tooltip as TooltipComponent} from '@mui/material';
import React from 'react';

import TooltipProps from './Interfaces/TooltipProps';

/**
 * Customized Tooltip with message as prop
 * @component
 */
const Tooltip: React.FC<TooltipProps> = ({message}) => {
  return (
    <TooltipComponent title={message} arrow disableFocusListener>
      <InfoOutlinedIcon />
    </TooltipComponent>
  );
};

export default Tooltip;
