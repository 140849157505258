import {BarChartProps} from '@charts/Interfaces/BarChartProps';
import {Paper} from '@mui/material';
import React from 'react';

const BarCustomizedTooltip: React.FC<{
  data: BarChartProps[];
  active: boolean;
}> = ({data, active}) => {
  if (active) {
    return (
      <Paper sx={{borderRadius: '8px', padding: '20px'}}>
        <div className='custom-tooltip'>
          <p className='label'>{`${data[0]?.year}`}</p>
          {data?.map((entry: BarChartProps, index) => {
            return (
              <p
                key={index}
                className='label'
              >{`${entry?.name} - ${entry?.emissionIntensity} ${entry?.unit}`}</p>
            );
          })}
        </div>
      </Paper>
    );
  }

  return null;
};

export default BarCustomizedTooltip;
