// Import AxiosResponse to add type to response data
import axios, {AxiosResponse} from 'axios';
import WidgetConfig from 'Components/Widgets/Interfaces/WidgetConfig';

interface ResponseType {
	data: WidgetConfig;
}

/**
 * Retrieves widget configuration.
 * @returns {Promise<WidgetConfig[]>} A promise that resolves to an array of widget configuration.
 */

// Add Promise type and define return type as BenchMark[]
const getWidgetConfigService = async (): Promise<WidgetConfig> => {
	const API_BASE_PATH = process.env.REACT_APP_API_PATH;
	const API_GET_WIDGET_CONFIG = `${API_BASE_PATH}${process.env.REACT_APP_API_GET_WIDGET_CONFIGURATION}`;
	try {
		// Use AxiosResponse to add type to response data
		const response: AxiosResponse<ResponseType> = await axios.get(API_GET_WIDGET_CONFIG, {
			headers: {
				authorization: `Bearer ${window?.bharatCarbon?.auth?.token}`
			}
		});

		return response?.data?.data;
	} catch (error) {
		console.error('Error fetching data:', error);
		// Throw the error to be caught by the calling function
		throw error;
	}
};

export default getWidgetConfigService;
