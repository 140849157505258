import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import WidgetState from 'Components/Widgets/Constants/Widget';
import Widget from 'Components/Widgets/Interfaces/Widget';
/*
 * The store for progress page is created here.
 * You can read more about Redux Toolkit's slices in the docs:
 * https://redux-toolkit.js.org/api/createSlice
 */

/**
 * Redux slice for the widget store.
 */
const createWidgetStore = createSlice({
	name: 'widgets',
	initialState: WidgetState,
	/**
	 * Sets the widget in the state.
	 * @param {WidgetState} state - The current state.
	 * @param {PayloadAction<Widget>} action - The action containing the widget payload.
	 */
	reducers: {
		setWidgets: (state, action: PayloadAction<Widget>) => {
			const index = state.findIndex((item) => item?.id === action?.payload.id);
			if (index !== -1) {
				state[index] = action?.payload;
			} else {
				state.push(action?.payload);
			}
		}
	}
});

// Export the store
export default createWidgetStore;
