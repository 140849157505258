const APP_STATUSES = {
  DOC_PENDING: 'documents pending',
  CALL_SCHEDULED: 'call scheduled',
  INVITE_AND_PAY: 'invite and pay',
  VERIFICATION_PENDING: 'verification pending',
  FOOTPRINTING_PENDING: 'footprinting pending',
  COMPLETED: 'completed',
  CANCELLED: 'cancelled'
};

export default APP_STATUSES;
