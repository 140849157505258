import {BarChartProps} from '../../Charts/Interfaces/BarChartProps';
import ChartType from '../Constants/ChartType';
import {Tabs} from '../Interfaces/Widget';
import {WidgetConfigItem} from '../Interfaces/WidgetConfig';
import WidgetData from '../Interfaces/WidgetData';

import getDataBydataId from './getDataByDataId';

/**
 * Retrieves the bar chart data based on the provided configuration and widget data.
 *
 * @param item - The array of tabs.
 * @param chartConfig - The chart configuration.
 * @param widgetData - The data for the widget.
 * @param year - The selected year.
 * @returns The bar chart data.
 */
const getBarChartData = (
  item: Tabs[],
  chartConfig: WidgetConfigItem[],
  widgetData: WidgetData,
  year: string
): BarChartProps[] => {
  const barChartData: BarChartProps[] = [];

  for (const l of item) {
    if (l.chartType === ChartType.BAR_CHART) {
      const arrayData: any[] = getDataBydataId(l.dataId, l.dataType, l.chartType, widgetData);

      const filterFill = (string: string) => {
        return chartConfig.find((value) => value.id === string)?.color;
      };

      const filterName = (string: string) => {
        return chartConfig.find((value) => value.id === string)?.name;
      };

      arrayData.forEach(({...rest}) => {
        barChartData.push({
          ...rest,
          fill: filterFill(rest.dataId),
          name: filterName(rest.dataId),
          year: year
        });
      });
    }
  }

  return barChartData;
};

export default getBarChartData;
