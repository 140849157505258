import KeyValueType from 'Components/Shared/Interfaces/KeyValueType';

import ChartType from '../Constants/ChartType';
import WidgetData from '../Interfaces/WidgetData';

/**
 * Retrieves data based on the provided data ID, data type, chart type, and widget data.
 *
 * @param dataId - The data ID.
 * @param dataType - The data type.
 * @param chartType - The chart type.
 * @param widgetData - The widget data.
 * @returns The retrieved data.
 */
const getDataBydataId: (dataId: string, dataType: string, chartType: string, widgetData: WidgetData) => any = (
  dataId,
  dataType,
  chartType,
  widgetData
) => {
  if (dataId === 'default') {

    return (widgetData?.emissionsData !== undefined) ? (widgetData?.emissionsData as KeyValueType)[dataType] : [];
  } else if (chartType === ChartType.BAR_CHART) {

    return (widgetData?.emissionsData !== undefined)
      ? (widgetData?.emissionsData as KeyValueType)[dataType].filter((o: { dataId: string }) => o.dataId === dataId)
      : [];
  } else if (dataType === 'kpiList') {

    return widgetData?.emissionsData?.categories;
  } else if (dataType === 'scopeList') {

    return widgetData?.emissionsData?.consumptionList;
  } else if (dataType === 'industryBenchmark') {
    
    return (widgetData?.emissionsData !== undefined)
      ? widgetData?.emissionsData[dataType].filter((o: { dataId: string }) => o.dataId === dataId)
      : [];
  }
};

export default getDataBydataId;
