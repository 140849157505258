/* eslint-disable no-console */
import {Box, Typography} from '@mui/material';
import Loader from 'Components/Shared/Loader';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import StoreInterface from 'State/Interfaces/StoreInterface';

import CommunityProps from '../Interfaces/CommunityProp';

import CommunityBox from './CommunityBox';

/**
 * Community component displays community-related information and boxes.
 * @component
 */
const Community: React.FC = () => {
  const {t} = useTranslation();

  const communityData = useSelector(
    (state: StoreInterface) => state.communityStore
  );

  return !communityData ? (
    <Loader />
  ) : (
    <Box
      id='splitreporting1'
      className='community-cards'
      sx={{maxHeight: '750px', overflow: 'auto'}}
    >
      <Typography
        variant='h4'
        sx={{
          position: 'sticky',
          top: '0',
          zIndex: '10px',
          background: '#F8FAFF'
        }}
      >
        {t('COMMUNITY')}
      </Typography>
      <Box>
        {communityData?.length > 0 ? (
          communityData?.map((article: CommunityProps, index: number) => {
            return (
              <CommunityBox
                key={index}
                header={article.header}
                author={article.author}
                description={article.description}
                date={article.date}
                read_time={article.read_time}
                link={article.link}
                img={article.img}
              />
            );
          })
        ) : (
          <Box>No Records</Box>
        )}
      </Box>
    </Box>
  );
};

export default Community;
