import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import FootPrintData from 'Components/Footprint/Interfaces/FootPrintData';
import LocationState from 'Components/Widgets/Constants/LocationState';

/**
 * Redux slice for location data.
 */
const createLocationStore = createSlice({
  name: 'locations',
  initialState: LocationState,
  reducers: {
    /**
     * Sets the locations in the state.
     * @param {LocationState} state - The current state.
     * @param {PayloadAction<FootPrintData>} action - The action containing the footprint data payload.
     */
    setLocations: (state, action: PayloadAction<FootPrintData>) => {
      action?.payload?.locations.forEach(i => {
        state.push(i);
      });
    }
  }
});

export default createLocationStore;
