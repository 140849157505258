import MyDispatch from 'Components/Shared/Interfaces/Dispatch';
import {Kpi, Kpis} from 'Components/Shared/Interfaces/Kpis';
import getKPIs from 'Services/Progress';

import handleProgressStore from './handleProgressStore';

/**
 * Retrieves the KPI data asynchronously and handles it based on the selected KPIs.
 *
 * @param dispatch - The dispatch function from Redux.
 * @param selectedKpis - An array of selected KPIs.
 * @returns A Promise that resolves to an array of KPIs or undefined if an error occurs.
 */
const getKPIData = async (dispatch: MyDispatch, selectedKpis: Kpis[]): Promise<Kpi[] | undefined> => {
  try {
    const res = await getKPIs();
    if (res) {
      if (selectedKpis.length === 0) {
        await handleProgressStore(dispatch, res);
      }
      
      return res;
    }
  } catch (error) {
    console.error('Error fetching KPI data:', error);
  }
};

export default getKPIData;
