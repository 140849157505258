import axios, {AxiosResponse} from 'axios';

interface ResponseType {
  data: any;
}

/**
 * Retrieves continents
 * @returns {Promise<any>} A promise that resolves to an array of continents.
 */

/** Add Promise type and define return type as BenchMark[] */
const CountryService = async (): Promise<any> => {
  const API_BASE_PATH = process.env.REACT_APP_API_PATH;
  const API_GET_COUNTRIES = `${API_BASE_PATH}${process.env.REACT_APP_API_GET_COUNTRIES}`;
  try {
    // Use AxiosResponse to add type to response data
    const response: AxiosResponse<ResponseType> = await axios.get(
      API_GET_COUNTRIES,
      {
        headers: {
          authorization: `Bearer ${window?.bharatCarbon?.auth?.token}`
        }
      }
    );

    return response?.data?.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    /** Throw the error to be caught by the calling function */
    throw error;
  }
};

export default CountryService;
