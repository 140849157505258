import {
  Box,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography
} from '@mui/material';
import InitiativeValues from 'Components/Shared/Interfaces/Initiatives';
import {Kpi} from 'Components/Shared/Interfaces/Kpis';
import WidgetData from 'Components/Widgets/Interfaces/WidgetData';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector, useDispatch} from 'react-redux';
import postInitiativeScore from 'Services/PostInitiativeScore';
import postPriority from 'Services/PostPriority';
import StoreInterface from 'State/Interfaces/StoreInterface';
import createProgressStore from 'State/Stores/Progress/createProgressStore';

import Standard from '../Constants/Standard';

import InitiativeComponent from './Initiative';

/**
 * TabSection Component is the section of initiatives of each kpi
 * It displays all initiatives of respective kpi through InitiativeComponent component
 * @component
 */
const TabSection: React.FC<Kpi> = ({
  _id,
  dataId,
  initiatives,
  kpiName,
  minRange,
  maxRange,
  displayIndex
}) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const [value, setValue] = useState<InitiativeValues[]>([]);
  const [priorityValue, setPriorityValue] = useState<string>('');
  const [emissionIntensity, setEmissionIntensity] = useState<{
    intensity: number;
    unit: string;
  }>({intensity: 0, unit: 'TCO2'});

  const selectedKpis = useSelector((state: StoreInterface) => state.kpiValues);
  const widgetData: WidgetData[] = useSelector(
    (state: StoreInterface) => state.widgetData
  );

  const handleInitiavtiveValue = (InitiativeValues: InitiativeValues) => {
    setValue([
      ...value.filter((obj) => obj._id !== InitiativeValues._id),
      {...InitiativeValues, y: displayIndex * 20}
    ]);
    dispatch(
      createProgressStore?.actions?.updateValues({
        _id: _id,
        initiativeValues: [
          ...value.filter((obj) => obj._id !== InitiativeValues._id),
          {...InitiativeValues, y: displayIndex * 20}
        ],
        kpiName: kpiName,
        priority: priorityValue
      })
    );
  };

  const handlePriorityChange = (event: SelectChangeEvent) => {
    setPriorityValue(event?.target?.value);
    if (value.length === 0) {
      postInitiativeScore({
        kpiId: _id,
        initiativeId: initiatives[0]._id,
        score: 0
      });
    }

    postPriority({priority: event?.target?.value, kpiId: _id});
    dispatch(
      createProgressStore?.actions?.updateValues({
        _id: _id,
        initiativeValues: value,
        kpiName: kpiName,
        priority: event?.target?.value
      })
    );
  };

  useEffect(() => {
    setValue(
      !!selectedKpis?.filter((kpi) => kpi._id === _id)[0]?.initiativeValues
        ? selectedKpis?.filter((kpi) => kpi._id === _id)[0]?.initiativeValues
        : value
    );
  }, [kpiName]);

  useEffect(() => {
    if (selectedKpis) {
      const userPriority = selectedKpis.find((i) => i._id === _id)?.priority;
      setPriorityValue(userPriority ? userPriority : Standard.HIGH);
    }
  }, selectedKpis);

  useEffect(() => {
    const kpi = widgetData[widgetData.length - 1].emissionsData.kpiList.find(
      (i) => i.dataId === dataId
    );

    setEmissionIntensity((prev) =>
      kpi
        ? {
            intensity: kpi?.emissionIntensity as number,
            unit: kpi?.unit as string
          }
        : prev
    );
  }, [widgetData]);

  return (
    <>
      <Box className='centre marginX smallFont'>
        <FormControl
          variant='standard'
          sx={{minWidth: 120, mb: '10px'}}
          size='small'
        >
          <Select
            onChange={handlePriorityChange}
            inputProps={{'aria-label': 'Without label'}}
            value={priorityValue}
            sx={{color: '#677489'}}
          >
            <MenuItem sx={{color: '#677489'}} value={Standard.LOW}>
              {Standard.LOW}
            </MenuItem>
            <MenuItem sx={{color: '#677489'}} value={Standard.MEDIUM}>
              {Standard.MEDIUM}
            </MenuItem>
            <MenuItem sx={{color: '#677489'}} value={Standard.HIGH}>
              {Standard.HIGH}
            </MenuItem>
          </Select>
        </FormControl>
        <Typography
          sx={{
            font: 'normal normal medium 16px/18px Quicksand',
            color: '#677489'
          }}
        >
          {Math.round(emissionIntensity.intensity)} {emissionIntensity?.unit}
          /YRLY
        </Typography>
      </Box>
      <div className='tabsectionHead'>
        <Typography variant='subtitle2'>{t('TARGET_IMPLEMENTED')}</Typography>
        {initiatives.map((item, index) => {
          return (
            <InitiativeComponent
              _id={_id}
              key={index}
              range={{minRange, maxRange}}
              item={item}
              handleInitiavtiveValue={handleInitiavtiveValue}
              changedInitiavtive={
                value.filter((initiatives) => initiatives?._id === item?._id)[0]
              }
            />
          );
        })}
      </div>
    </>
  );
};

export default TabSection;
