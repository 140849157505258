/* eslint-disable no-console */
import {Box, Grid, Typography} from '@mui/material';
import React from 'react';
import {useTranslation} from 'react-i18next';

import SimpleCardProps from './Interfaces/SimpleCardProps';

const SimpleCard: React.FC<{data: SimpleCardProps}> = ({data}) => {
  const {t} = useTranslation();

  return (
    <>
      <Grid container>
        {data.emissionData.map((item, index: number) => {
          let totalEmission = 0;
          item?.child?.map((c) => (totalEmission += c?.value));

          return (
            <Grid key={index} item sm={4} xs={12}>
              <Box sx={{backgroundColor: item?.fill}} className='activityCard'>
                <Box>
                  <Typography className='start'>{item.label}</Typography>
                  <Typography className='totalHead'>
                    {t('TOTAL')}
                    <br></br>
                    {t('CARBON_EMISSION')} TCo2
                  </Typography>
                  <Typography className='value'>
                    {Math.round(totalEmission)}
                  </Typography>
                </Box>

                <Box className='list-section'>
                  <Box
                    sx={{backgroundColor: 'transparent !important'}}
                    className='list'
                  >
                    <Box sx={{textTransform: 'uppercase'}}>
                      {t('EMISSION_BY')}
                    </Box>
                    <Box>(TCo2)</Box>
                  </Box>

                  {item?.child
                    ?.sort((a, b) => b.value - a.value)
                    .map((l, index: number) => (
                      <Box key={index} className='list' color='#fff'>
                        <Box>{l?.dataId}</Box>
                        <Box>{Math.round(l?.value)}</Box>
                      </Box>
                    ))}
                </Box>
              </Box>
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};

export default SimpleCard;
