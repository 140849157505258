/* eslint-disable no-console */
import InitiativeValues from 'Components/Shared/Interfaces/Initiatives';

import {Initiatives} from '../Interfaces/SliderProps';

/**
 * Creates an array of InitiativeValues objects based on the provided parameters.
 *
 * @param kpi - An array of KPI objects with _id and score properties.
 * @param item - An array of Initiatives objects.
 * @param displayIndex - The display index value.
 * @returns An array of InitiativeValues objects.
 */
const createInitiavtiveValueObject = (
  kpi: {_id: string; score: number}[],
  item: Initiatives[],
  displayIndex: number
): InitiativeValues[] => {
  const initiativeValue: InitiativeValues[] = [];

  item.forEach((initiative) => {
    const foundInitiative = kpi.find((i) => i._id === initiative._id);

    if (foundInitiative) {
      initiativeValue.push({
        _id: initiative._id,
        value: foundInitiative.score,
        weightage: initiative.weightage,
        checked: foundInitiative.score !== 0,
        y: displayIndex * 20,
        initiativeName: initiative.name
      });
    }
  });

  return initiativeValue;
};

export default createInitiavtiveValueObject;
