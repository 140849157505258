/* eslint-disable no-console */
import {Box, Button} from '@mui/material';
import UpgradeToPremium from 'Components/Shared/Constants/UpgradeToPremiumContent';
import React from 'react';
import {useCalendlyEventListener} from 'react-calendly';
import scheduleCall from 'Services/scheduleCall';

import PayIcon from '../../Assets/sh-call-icon.svg';
import CalendlyPopUp from '../Shared/CalendlyPopUp';
import SnackbarMessages from '../Shared/Constants/SnackbarMessages';
import SnackbarStatus from '../Shared/Constants/SnackbarStatus';

import Calendly from './Constants/Calendly';
import ScheduleCallProps from './Interfaces/ScheduleCallProp';

/**
 * ScheduleCall Component is for scheduling call with partner through guest dashboard.
 * It displays ScheduleCall button and includes Calendly integration
 * @component
 */
const ScheduleCall: React.FC<ScheduleCallProps> = ({setSnackbarMessage}) => {
  const [calendlyPopUp, openCalendlyPopup] = React.useState(false);

  useCalendlyEventListener({
    onProfilePageViewed: () => console.log('onProfilePageViewed'),
    onDateAndTimeSelected: () => console.log('onDateAndTimeSelected'),
    onEventTypeViewed: () => console.log('onEventTypeViewed'),
    onEventScheduled: async () => {
      const code: number = await scheduleCall();
      if (code === 200) {
        setSnackbarMessage({
          open: true,
          status: SnackbarStatus.SUCCESS,
          message: SnackbarMessages.SCHEDULED_CALL
        });
        // navigate(RoutesURLs.DASHBOARD);
      } else {
        setSnackbarMessage({
          open: true,
          status: SnackbarStatus.ERROR,
          message: SnackbarMessages.COMMONERROR
        });
      }
    }
  });
  const handleClick = () => {
    openCalendlyPopup(true);
  };

  return (
    <>
      <Box className='dash-container newplan-page'>
        {/* <Box position='relative' className='page-header'>
          <Box className='breadcrumb'>
            <Box>Subscription Info</Box>
            <Box className='highlight'>Payment Info</Box>
          </Box>
          <Button className='btn btn-primary'>Contact Support</Button>
        </Box> */}
        <Box className='page-data'>
          <h1>{UpgradeToPremium.title}</h1>
          <p>{UpgradeToPremium.subTitle}</p>

          <Button className='btn' onClick={handleClick}>
            {UpgradeToPremium.scheduleButtonName}
          </Button>
        </Box>
        <Box className='pp-packages'>
          <Box className='pa-list'>
            <Box className='pa-number'>1</Box>
            <img src={PayIcon} alt='icon'></img>
            <h4>{UpgradeToPremium.step1}</h4>
            <p>{UpgradeToPremium.step1Desc}</p>
            <p>{UpgradeToPremium.step1SubDesc}</p>
          </Box>
          <Box className='pa-list'>
            <Box className='pa-number'>2</Box>
            <img src={PayIcon} alt='icon'></img>
            <h4>{UpgradeToPremium.step2}</h4>
            <p>{UpgradeToPremium.step2Desc}</p>
            <p>{UpgradeToPremium.step2SubDesc}</p>
          </Box>
          <Box className='pa-list'>
            <Box className='pa-number'>3</Box>
            <img src={PayIcon} alt='icon'></img>
            <h4>{UpgradeToPremium.step3}</h4>
            <p>{UpgradeToPremium.step3Desc}</p>
            <p>{UpgradeToPremium.step3SubDesc}</p>
          </Box>
          <Box className='pa-list'>
            <Box className='pa-number'>4</Box>
            <img src={PayIcon} alt='icon'></img>
            <h4>{UpgradeToPremium.step4}</h4>
            <p>{UpgradeToPremium.step4Desc}</p>
            <p>{UpgradeToPremium.step4SubDesc}</p>
          </Box>
          <Box className='pa-list'>
            <Box className='pa-number'>5</Box>
            <img src={PayIcon} alt='icon'></img>
            <h4>{UpgradeToPremium.step5}</h4>
            <p>{UpgradeToPremium.step5Desc}</p>
            <p>{UpgradeToPremium.step5SubDesc}</p>
          </Box>
        </Box>
        <CalendlyPopUp
          open={calendlyPopUp}
          setOpen={openCalendlyPopup}
          meetingType={Calendly.PAYMENT_QUERY}
        />
      </Box>
    </>
  );
};

export default ScheduleCall;
