import {Box} from '@mui/material';
import TooltipMessage from 'Components/Shared/Constants/TooltipMessages';
import WidgetTitle from 'Components/Widgets/Layouts/WidgetTitle';
import React from 'react';

import ActionChartProps from '../Interface/ActionChartProps';

import NoReductionChart from './NoReductionChart';
import OnReductionChart from './OnReductionChart';

const ReductionChart: React.FC<{
  yearValue: string;
  setYearValue: React.Dispatch<React.SetStateAction<string>>;
  chartData: ActionChartProps[];
}> = ({yearValue, setYearValue, chartData}) => {
  return (
    <Box className='dash-container' sx={{padding: '15px 10px 15px 10px'}}>
      <Box className='dash-cards' sx={{height: '374px !important'}}>
        <WidgetTitle
          name='Emissions Reduction'
          yearValue={yearValue}
          setYearValue={setYearValue}
          TooltipMessage={TooltipMessage.TAKE_ACTION_EMISSIONS_REDUCTIONS}
        />
        <Box className='card-data'>
          <OnReductionChart chartData={chartData} />
          {false && <NoReductionChart />}
        </Box>
      </Box>
    </Box>
  );
};

export default ReductionChart;
