import CalculateProps from '../Interfaces/Calculate';

/**
 * Calculates the value based on the provided parameters.
 *
 * @param value - The initial value to calculate.
 * @param period - The period of calculation ('monthly' or 'annual').
 * @param emmitor - The emitter value for the calculation.
 * @returns The calculated value as a number rounded to 2 decimal places.
 */
const calculateValue = ({value, period, emmitor}: CalculateProps): number => {
  const calculatedValue = value ? (period === 'monthly' ? value * emmitor : value * emmitor * 12) : 0;

  return Number(calculatedValue.toFixed(2));
};

export default calculateValue;
