export const marks = [
	{
		value: 0,
		label: '0'
	},
	{
		value: 50,
		label: '50'
	},
	{
		value: 100,
		label: '100'
	}
];

export const valueLabelFormat = (value: number) : number => {
	return value;
};