import {Graphs} from 'Components/Widgets/Interfaces/Widget';

import IndustryCategory from '../Constants/IndustryCategory';

const filterBenchmarksByIndustry = (
  industry: string,
  benchmarkObj: Graphs | any
): any => {
  if (benchmarkObj.tabs) {
    if (industry === IndustryCategory.hotel) {
      const filtered =
        benchmarkObj.tabs[0].dataId === 'wasteBenchmark' ? null : benchmarkObj;

      return filtered;
    } else {
      const filtered =
        benchmarkObj.tabs[0].dataId === 'waterBenchmark' ? null : benchmarkObj;

      return filtered;
    }
  } else {
    if (industry === IndustryCategory.hotel) {
      const filtered =
        benchmarkObj.id === 'wasteBenchmark' ? null : benchmarkObj;

      return filtered;
    } else {
      const filtered =
        benchmarkObj.id === 'waterBenchmark' ? null : benchmarkObj;

      return filtered;
    }
  }
};

export default filterBenchmarksByIndustry;
