import axios, {AxiosResponse} from 'axios';
import PostPriorityProps from 'Components/Shared/Interfaces/PostPriorityProps';

/**
 * Posts a priority.
 * @param {PostPriorityProps} PostData - The data of the priority to be posted.
 * @returns {Promise<PostPriorityProps[]>} A promise that resolves to an array of posted priorities.
 */
const postPriority = async (PostData: PostPriorityProps): Promise<PostPriorityProps[]> => {
  const API_BASE_PATH = process.env.REACT_APP_API_PATH;
  const API_SAVE_KPI_PRIORITY = `${API_BASE_PATH}${process.env.REACT_APP_API_SAVE_PRIORITY_SCORE}`;

  try {
    /** Use AxiosResponse to add type to response data */
    const response: AxiosResponse<PostPriorityProps[]> = await axios.post(API_SAVE_KPI_PRIORITY, PostData, {
      headers: {
        authorization: `Bearer ${window?.bharatCarbon?.auth?.token}`
      }
    });

    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    /** Throw the error to be caught by the calling function */
    throw error;
  }
};

export default postPriority;
