import WidgetConfig from '../Interfaces/WidgetConfig';

const WidgetConfigState: WidgetConfig = {
    scopeList: [],
    kpiList: [],
    consumptionList: [],
    categories: [],
    industryBenchmark: []
};

export default WidgetConfigState;