import {Locations} from 'Components/Footprint/Interfaces/FootPrintData';

const getLocationsGroup = (locations: Locations[]): {[key: string]: Locations[]} => {

    const locationGroup = locations.reduce(
        (
            r: {[x: string]: Locations[]},
            item: Locations
        ) => {
            const {title, addressLine1, addressLine2, city, state, country, zipCode, _id} = item;
            r[city] = [...(r[city] || []), {title, addressLine1, addressLine2, city, state, country, zipCode, _id}];

            return r;
        },
        {}
    );

    return locationGroup;
};

export default getLocationsGroup;