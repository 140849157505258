import getUserData from 'Services/getUserData';
import UserState from 'State/Constants/UserState';
import UserInfo from 'State/Interfaces/UserInfo';

/**
 * Retrieves the UserInfo by calling getUserData service asynchronously.
 *
 * @returns A Promise that resolves to the UserInfo.
 */
const getUserInfo = async (): Promise<UserInfo> => {
    try {
        const res = await getUserData();
        if (res) {
            
            return res;
        }
    } catch (error) {
        console.error('Error fetching user data:', error);
    }

    return UserState;
};

export default getUserInfo;
