import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import WidgetConfigState from 'Components/Widgets/Constants/WidgetConfig';
import WidgetConfig from 'Components/Widgets/Interfaces/WidgetConfig';

/**
 * Redux slice for the widget configuration store.
 */
const createWidgetConfigurationStore = createSlice({
  name: 'widgetConfig',
  initialState: WidgetConfigState,
  reducers: {
    /**
     * Sets the widget configuration in the state.
     * @param {WidgetConfigState} state - The current state.
     * @param {PayloadAction<WidgetConfig>} action - The action containing the widget configuration payload.
     */
    setWidgetConfig: (state, action: PayloadAction<WidgetConfig>) => {
      state.categories = action?.payload?.categories;
      state.consumptionList = action?.payload?.consumptionList;
      state.kpiList = action?.payload?.kpiList;
      state.scopeList = action?.payload?.scopeList;
      state.industryBenchmark = action?.payload?.industryBenchmark;
    }
  }
});

export default createWidgetConfigurationStore;
