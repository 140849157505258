import React from 'react';
import {useMap} from 'react-leaflet';

import ZoomEventProps from '../Interface/ZoomEventProps';

const ZoomEventComponent: React.FC<ZoomEventProps> = ({targetLatLng, zoomLevel}) => {
  const map = useMap();

  React.useEffect(() => {
    const handleEvent = () => {
      map.setView([targetLatLng.lat,targetLatLng.lng], zoomLevel);
    };
    handleEvent();
  }, [map, targetLatLng, zoomLevel]);

  return null; // This component doesn't render anything on its own
};

export default ZoomEventComponent;
