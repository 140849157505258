import {Box} from '@mui/material';
import FormBuilder from 'Components/Shared/FormBuilder';
import React from 'react';

const SectionB: React.FC = () => {
  const companyId = window?.bharatCarbon?.company?._id;
  const handleSubmit = (data: any) => {
    data.companyId = companyId;
    // eslint-disable-next-line no-console
    console.log(data);
  };

  return (
    <Box sx={{padding: '30px 24px 24px 24px'}}>
      <FormBuilder
        fields={[
          {
            type: 'table',
            label: 'Disclosure Questions',
            key: 'disclosureQuestions',
            static: [
              `Whether your Company’s policy/
            policies cover each principle and
            its core elements of the NGRBCs.`,
              `Has the policy been approved by
            the Board?`
            ],
            options: [
              {type: 'box', label: 'Policy and management processes', key: ''},
              {type: 'checkBox', label: 'P1', key: 'P1'},
              {type: 'checkBox', label: 'P2', key: 'P2'},
              {type: 'checkBox', label: 'P3', key: 'P3'},
              {type: 'checkBox', label: 'P4', key: 'P4'},
              {type: 'checkBox', label: 'P5', key: 'P5'},
              {type: 'checkBox', label: 'P6', key: 'P6'},
              {type: 'checkBox', label: 'P7', key: 'P7'},
              {type: 'checkBox', label: 'P8', key: 'P8'},
              {type: 'checkBox', label: 'P9', key: 'P9'}
            ]
          },
          {
            type: 'text',
            label: 'Web Link of the Policies, if available',
            key: 'policyLink'
          },
          {
            type: 'table',
            label: 'Other Disclosure Questions',
            key: 'disclosureQuestions',
            static: [
              `Whether the Company has translated
                  the policy into procedures.`,
              `Do the enlisted policies extend to
                  Company’s value chain partners?`
            ],
            options: [
              {type: 'box', label: 'Policy and management processes', key: ''},
              {type: 'checkBox', label: 'P1', key: 'P1'},
              {type: 'checkBox', label: 'P2', key: 'P2'},
              {type: 'checkBox', label: 'P3', key: 'P3'},
              {type: 'checkBox', label: 'P4', key: 'P4'},
              {type: 'checkBox', label: 'P5', key: 'P5'},
              {type: 'checkBox', label: 'P6', key: 'P6'},
              {type: 'checkBox', label: 'P7', key: 'P7'},
              {type: 'checkBox', label: 'P8', key: 'P8'},
              {type: 'checkBox', label: 'P9', key: 'P9'}
            ]
          },
          {
            type: 'textarea',
            label:
              'Name of the national and international codes/certifications/labels/ standards',
            key: 'nationalInternationStandards'
          },
          {
            type: 'textarea',
            label:
              'Specific commitments, goals and targets set by the Company with defined timelines, if any.',
            key: 'optionalCommitments'
          },
          {
            type: 'textarea',
            label: `Performance of the Company against the specific commitments, 
            goals and targets along-with reasons in case the same are not met.`,
            key: 'performanceAgainstOptCommitments'
          },
          {
            type: 'textarea',
            label: `Statement by director responsible for the business responsibility report, 
            highlighting ESG related challenges, targets and
            achievements (listed Company has flexibility regarding the placement of this disclosure).`,
            key: 'statementOfDirector'
          },
          {
            type: 'text',
            label: `Details of the highest authority
            responsible for implementation and
            oversight of the Business Responsibility
            policy (ies).`,
            key: 'highestAuthority'
          },
          {
            type: 'textarea',
            label: `Does the Company have a specified
            Committee of the Board/ Director
            responsible for decision making on
            sustainability related issues? (Yes / No).
            If yes, provide details.`,
            key: 'sustanibilityAuthorityDetails'
          },
          {
            type: 'div',
            label: 'Details of Review of NGRBCs by the Company:',
            key: ''
          },
          {
            type: 'textarea',
            label: `Performance against
            Above policies and follow up
            action`,
            key: 'NGRBCfollowUpPerformance'
          },
          {
            type: 'textarea',
            label: `Compliance with statutory
            requirements of relevance to the
            principles, and, rectification of
            any non-compliances`,
            key: 'NGRBCcompliance'
          },
          {
            type: 'text',
            label: `Has the Company carried out independent assessment/ evaluation of the working of its policies by
            an external agency? (Yes/No). If yes, provide name of the agency.`,
            key: 'independentAuditAgencyName'
          },
          {
            type: 'table',
            label: `If answer to question (1) above is “No” i.e. not all Principles 
            are covered by a policy, reasons to be stated:`,
            key: 'allPolicyPrinciples',
            static: [
              `The Company does not consider the Principles material
              to its business (Yes/No)`,
              `The Company is not at a stage where it is in a position
              to formulate and implement the policies on specified
              principles (Yes/No)`,
              `The Company does not have the financial or/human and
              technical resources available for the task (Yes/No)`,
              `It is planned to be done in the next financial year
              (Yes/No)`,
              'Any other reason (please specify)'
            ],
            options: [
              {type: 'box', label: 'Questions', key: ''},
              {type: 'checkBox', label: 'P1', key: 'P1'},
              {type: 'checkBox', label: 'P2', key: 'P2'},
              {type: 'checkBox', label: 'P3', key: 'P3'},
              {type: 'checkBox', label: 'P4', key: 'P4'},
              {type: 'checkBox', label: 'P5', key: 'P5'},
              {type: 'checkBox', label: 'P6', key: 'P6'},
              {type: 'checkBox', label: 'P7', key: 'P7'},
              {type: 'checkBox', label: 'P8', key: 'P8'},
              {type: 'checkBox', label: 'P9', key: 'P9'}
            ]
          }
        ]}
        onSubmit={handleSubmit}
        title='Section B'
      />
    </Box>
  );
};

export default SectionB;
