import Loader from 'Components/Shared/Loader';
import React, {useEffect, useState} from 'react';
import {
  BarChart as BarChartReChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend
} from 'recharts';

import {BarChartProps} from './Interfaces/BarChartProps';
import BarCustomizedTooltip from './Layouts/BarCustomizedTooltip';
import BarCustomLegend from './Layouts/BarCustomLegend';

const BarChart: React.FC<{data: BarChartProps[]}> = ({data}) => {
  /**  State for filtered data*/
  const [filteredData, setFilteredData] = useState<BarChartProps[]>(data);

  /** Convert data to the required format*/
  const convertData = () => {
    const convertedData: any = {year: data[0]?.year};

    filteredData.forEach((item) => {
      convertedData[item.dataId] = item.emissionIntensity.toString();
    });

    return convertedData;
  };

  useEffect(() => {
    setFilteredData(data);
  }, [data]);

  const convertedData = convertData();

  return data.length === 0 ? (
    <Loader />
  ) : (
    <ResponsiveContainer
      width='100%'
      aspect={5}
      height='50%'
      className='bar-chart-simple'
    >
      <BarChartReChart
        width={800}
        height={100}
        data={[convertedData]}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5
        }}
        barCategoryGap='0%'
        barGap={0}
      >
        <CartesianGrid strokeDasharray='3 3' />
        <XAxis dataKey='year' tickLine={false} axisLine={false} />
        <YAxis
          unit='TCo2'
          style={{fontSize: '10px'}}
          tickLine={false}
          axisLine={false}
        />
        <Tooltip
          position={{x: 900, y: 0}}
          cursor={false}
          content={(props) => {
            return (
              <BarCustomizedTooltip
                data={filteredData}
                active={!!props?.active ? props?.active : false}
              />
            );
          }}
        />
        <Legend
          content={
            <BarCustomLegend data={data} filterArray={setFilteredData} />
          }
          wrapperStyle={{
            position: 'absolute',
            width: '100%',
            height: 'auto',
            left: '220px',
            bottom: '5px'
          }}
        />
        {filteredData?.map((label, index) => {
          return (
            <Bar
              key={index}
              dataKey={label.dataId}
              fill={label.fill}
              barSize={105}
            />
          );
        })}
      </BarChartReChart>
    </ResponsiveContainer>
  );
};

export default BarChart;
