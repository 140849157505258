import CompanyState from 'State/Constants/CompanyState';
import UserState from 'State/Constants/UserState';

const ingestGlobalConfigInWindow = (): void => {
    window.bharatCarbon = {
        userInfo: UserState,
        auth: {token:''},
        company: CompanyState
    };
};

export default ingestGlobalConfigInWindow;
