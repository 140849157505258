import ComposedChartProps from 'Components/Widgets/Interfaces/ComposedChartProps';
import React from 'react';
import {
  ComposedChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ReferenceArea,
  ReferenceLine
} from 'recharts';

import CandyBarProps from './Interfaces/CandyBarProps';

const CandyBar: React.FC<CandyBarProps> = ({x, y, width, height, fill}) => {
  const barY = height < 0 ? y + height : y;
  const barHeight = Math.abs(height);

  return (
    <rect
      fill={fill}
      mask='url(#mask-stripe)'
      x={x}
      y={barY}
      width={width}
      height={barHeight}
    />
  );
};

const CustomLabel = (value: number): string => {
  return `${Math.round(value)}`;
};

const ComposedBarChart: React.FC<{data: ComposedChartProps}> = ({data}) => {
  const diff = data.value - data.benchmark;
  data.diff = diff;

  return data?.value === undefined ? null : (
    <ComposedChart
      className='composedChart'
      width={375}
      height={300}
      data={[data]}
      margin={{top: 20, right: 30, left: 20, bottom: 5}}
    >
      <pattern
        id='pattern-stripe'
        width='6'
        height='8'
        patternUnits='userSpaceOnUse'
        patternTransform='rotate(45)'
      >
        <rect width='2' height='8' transform='translate(0,0)' fill='white' />
      </pattern>
      <mask id='mask-stripe'>
        <rect
          x='0'
          y='0'
          width='100%'
          height='100%'
          fill='url(#pattern-stripe)'
          enableBackground='true'
        />
      </mask>
      <defs>
        <linearGradient id='colorUv' x1='0' y1='0' x2='0' y2='1'>
          <stop offset='20%' stopColor='#FF2366' stopOpacity={0} />
          <stop offset='95%' stopColor='#FF2366' stopOpacity={0.8} />
        </linearGradient>
      </defs>
      <CartesianGrid stroke='none' />
      <XAxis dataKey='year' />
      <YAxis
        domain={[0, data?.value + data?.value * 1.05]}
        tickFormatter={CustomLabel}
        tickCount={7}
        unit={` ${data?.unit}`}
      />
      <Tooltip
        position={{x: 150, y: 0}}
        content={() => {
          return (
            <>
              <div>{data.name}</div>
              <br />
              <div>
                Benchmark: {Number(data?.benchmark.toFixed(2))} {data?.unit}
              </div>
              <br />
              <div>
                Value: {Number(data?.value.toFixed(2))} {data?.unit}
              </div>
            </>
          );
        }}
      />
      <Legend
        content={() => {
          return <div style={{textAlign: 'center'}}>{data.name}</div>;
        }}
      />
      {data?.value < data?.benchmark ? (
        <>
          <Bar dataKey='value' stackId='a' fill={data.fill} barSize={115} />
          <Bar
            dataKey='benchmark'
            stackId='a'
            fill={data.fill}
            opacity='0'
            barSize={115}
          />
          <ReferenceLine
            y={Number(data?.benchmark.toFixed(2))}
            label={{
              position: 'bottom',
              value: `BENCHMARK ${Number(data?.benchmark.toFixed(2))} ${
                data?.unit
              }`,
              fill: '#B3B8BD',
              fontSize: '9px'
            }}
            strokeWidth='2'
            strokeDasharray='3 3'
            fill='#FC2366'
            stroke='#99B1C7'
          />
        </>
      ) : (
        <>
          <Bar dataKey='benchmark' stackId='a' fill={data.fill} barSize={115} />
          <Bar
            dataKey='diff'
            stackId='a'
            fill={data.fill}
            opacity='0.5'
            shape={(props) => {
              return <CandyBar {...props} />;
            }}
            barSize={115}
          />
          <ReferenceLine
            y={data?.benchmark}
            label={{
              position: 'bottom',
              value: `BENCHMARK ${Number(data?.benchmark.toFixed(2))}${
                data?.unit
              }`,
              fill: '#B3B8BD',
              fontSize: '9px'
            }}
            strokeWidth='2'
            fill='#FC2366'
            stroke='red'
          />
          <ReferenceArea
            y1={data?.benchmark}
            y2={
              data?.value === data?.benchmark
                ? data?.value
                : data?.value + data?.value * 1.05
            }
            fill='url(#colorUv)'
          />
        </>
      )}
    </ComposedChart>
  );
};

export default ComposedBarChart;
