import {useAuth0} from '@auth0/auth0-react';
import {Box, Button} from '@mui/material';
import GuestUpgradePremium from 'Components/Shared/Layouts/GuestUpgradePremium';
import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {NavLink} from 'react-router-dom';

import StoreInterface from '../../State/Interfaces/StoreInterface';
import RoutesURLs from '../Shared/Constants/Routes';
import Modal from '../Shared/Modal';

import Logo from './Layouts/Logo';

/**
 * SideNav component
 * Navbar on the left side of the screen which handles the routes of the whole application
 * @component
 */
const SideNav: React.FC<{
  setShowTour: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({setShowTour}) => {
  const [upgrade, setUpgrade] = useState(false);
  const {t} = useTranslation();
  const {logout} = useAuth0();
  const loggedUser = useSelector((state: StoreInterface) => state.user);
  const handleClick = (e: {preventDefault: () => void}) => {
    if (loggedUser.isGuest) {
      setUpgrade(true);
      e.preventDefault();
    }
  };
  const logoutHandle = () => {
    localStorage.removeItem('isLogged');
    localStorage.removeItem('token');
    logout({logoutParams: {returnTo: window.location.origin}});
  };

  return (
    <>
      <Box className='side-nav'>
        <Modal
          open={upgrade}
          setOpen={setUpgrade}
          children={<GuestUpgradePremium setOpen={setUpgrade} />}
        />
        <Logo />
        <h4>{t('MAIN_MENU')}</h4>
        <ul>
          <li>
            <NavLink to={RoutesURLs.DASHBOARD}>
              <span className='icon-dashboard'></span>
              <label>{t('YOUR_DASHBOARD')}</label>
            </NavLink>
          </li>
          <li>
            <NavLink
              className={loggedUser.isGuest ? 'deactive' : ''}
              onClick={handleClick}
              to={RoutesURLs.FOOTPRINT}
            >
              <span className='icon-co2'></span>
              <label>{t('YOUR_FOORPRINT')}</label>
            </NavLink>
          </li>
          <li>
            <NavLink
              className={loggedUser.isGuest ? 'deactive' : ''}
              onClick={handleClick}
              to={RoutesURLs.YOUR_PROGRESS}
            >
              <span className='icon-analytics'></span>
              <label>{t('YOUR_PROGRESS')}</label>
            </NavLink>
          </li>
          <li>
            <NavLink
              className={loggedUser.isGuest ? 'deactive' : ''}
              onClick={handleClick}
              to={RoutesURLs.ACTION}
            >
              <span className='icon-flag'></span>
              <label>{t('TAKE_ACTION')}</label>
            </NavLink>
          </li>
          <li>
            <NavLink
              className={loggedUser.isGuest ? 'deactive' : ''}
              onClick={handleClick}
              to={RoutesURLs.SUBSCRIPTION_INFO}
            >
              <span className='icon-cart'></span>
              <label>{t('PAYMENT_INFO')}</label>
            </NavLink>
          </li>
        </ul>
        {!loggedUser.isGuest && (
          <>
            <br />
            <h4>{t('ESG')}</h4>
            <ul>
              <li>
                <NavLink to={RoutesURLs.ESG_REPORTING}>
                  <span className='icon-cart'></span>
                  <label>{t('ESG_REPORTING')}</label>
                </NavLink>
              </li>
            </ul>
          </>
        )}

        {/* <Box
          display={loggedUser.isGuest ? '' : 'none'}
          className='text-center updatebtn'
        >
          <NavLink to={RoutesURLs.SCHEDULE_CALL}>
            <Button className='btn ml-auto'>{t('UPGRADE_TO_PRE')}</Button>
          </NavLink>
        </Box> */}
        <Box className='bottom-link'>
          <h4>{t('GENERAL')}</h4>
          <ul>
            <li>
              <Button
                onClick={() => {
                  setShowTour(true);
                }}
              >
                <span className='icon-support'></span>
                <label>{t('HELP')}</label>
              </Button>
            </li>
            {/* <li>
              <NavLink to={RoutesURLs.SETTINGS}>
                <span className='icon-settings'></span>
                <label>{t('SETTINGS')}</label>
              </NavLink>
            </li> */}
            <li>
              <div onClick={logoutHandle}>
                <span className='icon-power'></span>
                <label>{t('SIGN_OUT')}</label>
              </div>
            </li>
          </ul>
        </Box>
      </Box>
    </>
  );
};

export default SideNav;
