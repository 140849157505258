import {Box, Grid} from '@mui/material';
import Loader from 'Components/Shared/Loader';
import PageId from 'Components/Widgets/Constants/PageId';
import Widget from 'Components/Widgets/Interfaces/Widget';
import Widgets from 'Components/Widgets/Widgets';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {connectFootPrintDataSubscription} from 'State/connectSubscriptions';
import StoreInterface from 'State/Interfaces/StoreInterface';

/**
 * Footprint component represents the footprint page.
 * It displays a grid of widgets.
 * @component
 */
const Footprint: React.FC = () => {
  const [widgets, setWidgets] = useState<Widget[]>([]);
  const widgetsStoreInfo = useSelector(
    (state: StoreInterface) => state.widgets
  );
  const footprintData = useSelector(
    (state: StoreInterface) => state.footprintData
  );
  const dispatch = useDispatch();

  /**
   * Update the widgets when the store information changes.
   */
  useEffect(() => {
    setWidgets(widgetsStoreInfo);
  }, [widgetsStoreInfo]);

  /**
   * Connect the footprint data subscription if companyId is empty.
   */
  useEffect(() => {
    if (footprintData?.companyId === '') {
      connectFootPrintDataSubscription(dispatch);
    }
  }, []);

  return !footprintData ? (
    <Loader />
  ) : (
    <Box className='foot-container'>
      <Grid container spacing={{xs: 1, md: 1}}>
        {widgets
          ?.filter((item) => item.pageId === PageId.FOOTPRINT)
          .map((item, index) => {
            return item?.visible ? (
              <Grid
                key={index}
                item
                sm={item.displayWidthWeb}
                xs={item.displayWidthMobile}
              >
                <Widgets {...item} />
              </Grid>
            ) : (
              <></>
            );
          })}
      </Grid>
    </Box>
  );
};

export default Footprint;
