import {Box} from '@mui/material';
import React from 'react';

import MarkerProps from '../Interface/MarkersProps';

const MapLabel: React.FC<{labelContinent: string; markers: MarkerProps[]}> = ({
  labelContinent,
  markers
}) => {
  return (
    <Box
      sx={{
        position: 'absolute',
        display: 'flex',
        flexDirection: 'column',
        zIndex: 25,
        color: '#99B1C7',
        width: '100px'
      }}
    >
      <Box
        sx={{
          textTransform: 'uppercase',
          font: 'normal normal 600 12px/15px Quicksand'
        }}
      >
        {'total number of projects'} {labelContinent && `in ${labelContinent}`}{' '}
      </Box>
      <Box sx={{font: 'normal normal normal 52px/65px Quicksand'}}>
        {markers.length}
      </Box>
      <Box sx={{font: 'normal normal 600 12px/15px Quicksand'}}>
        Source : UNFCCC
      </Box>
    </Box>
  );
};

export default MapLabel;
