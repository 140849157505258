import PieChartProps from '../../Charts/Interfaces/PieChartProps';
import getChartDataProps from '../Interfaces/getChartDataProps';

import getDataBydataId from './getDataByDataId';

/**
 * Retrieves data based on the provided item, chartConfig and widget data.
 * The data retrieved is provided to PieChart
 * 
 * @param item - widget item provided.
 * @param chartConfig - Configuration of the chart.
 * @param widgetData - The widget data.
 * @returns The retrieved data.
 */
const getPieChartData = ({item, chartConfig, widgetData}: getChartDataProps): PieChartProps[] => {
    const pieChartData: PieChartProps[] = [];
    const arrayData: any[] = getDataBydataId(item?.dataId, item?.dataType, item?.chartType, widgetData);

    chartConfig?.forEach(e => {
        const value = arrayData.find((o: {dataId: string}) => o.dataId === e.id).emissionIntensity;
        const unit = arrayData.find((o: {dataId: string}) => o.dataId === e.id).unit;
        pieChartData.push({
            name: e?.name,
            fill: e?.color,
            value: value,
            unit: unit
        });
    });

    return pieChartData;
};

export default getPieChartData;
