import React from 'react';

import RadialChartProps from '../../Charts/Interfaces/RadialChartProps';

const RadialCustomizedLegend: React.FC<{data: RadialChartProps[]}> = ({
  data
}) => {
  return (
    <>
      <ul
        className='recharts-default-legend'
        style={{padding: '0px', margin: '0px', textAlign: 'left'}}
      >
        {data?.map((e, index: number) => {
          return e.name ? (
            <li
              style={{
                display: 'flex',
                marginRight: '10px',
                alignItems: 'center'
              }}
              key={index}
            >
              <span
                style={{
                  width: '7px',
                  height: '7px',
                  backgroundColor: `${e.fill}`,
                  borderRadius: '50%',
                  marginRight: '5px'
                }}
              />
              <span
                className='recharts-legend-item-text'
                style={{color: 'rgb(200, 162, 252)'}}
              >
                {e.name} ({e.emissionIntensity}
                {e.unit})
              </span>
            </li>
          ) : (
            <div key={index}></div>
          );
        })}
      </ul>
    </>
  );
};

export default RadialCustomizedLegend;
