/* eslint-disable no-console */
import RadialChartProps from '../../Charts/Interfaces/RadialChartProps';
import getChartDataProps from '../Interfaces/getChartDataProps';

import getDataBydataId from './getDataByDataId';

/**
 * Retrieves data based on the provided item, chartConfig and widget data.
 * The data retrieved is provided to the RadialChart
 *
 * @param item - widget item provided.
 * @param chartConfig - Configuration of the chart.
 * @param widgetData - The widget data.
 * @returns The retrieved data.
 */
const getRadialChartData = ({
  item,
  chartConfig,
  widgetData
}: getChartDataProps): any => {
  const radialChartData: RadialChartProps[] = [];

  const arrayData: any[] = getDataBydataId(
    item?.dataId,
    item?.dataType,
    item?.chartType,
    widgetData
  );

  const totalPercentValue = arrayData?.reduce(function (
    acc: number,
    item: any
  ) {
    return acc + item?.emissionIntensity;
  },
  0);

  radialChartData.push({
    name: '',
    fill: '#fff',
    value: 100,
    emissionIntensity: 0,
    unit: ''
  });

  chartConfig?.forEach((e) => {
    const value = arrayData?.find(
      (o: {dataId: string}) => o.dataId === e.id
    )?.emissionIntensity;
    const unit = arrayData?.find(
      (o: {dataId: string}) => o.dataId === e.id
    )?.unit;
    const percentageValue = Number(
      Math.round((value * 100) / totalPercentValue)
    );

    radialChartData.push({
      name: e?.name,
      fill: e?.color,
      value: isNaN(percentageValue) ? 0 : percentageValue,
      emissionIntensity: value,
      unit: unit
    });
  });
  radialChartData.reverse();

  return radialChartData;
};

export default getRadialChartData;
