/* eslint-disable no-console */
import {IdToken} from '@auth0/auth0-react';
import getActionData from 'Components/Action/Utils/getActionData';
import getAllContinents from 'Components/Action/Utils/getAllContinents';
import getAllCountries from 'Components/Action/Utils/getAllCountries';
import getAllProjects from 'Components/Action/Utils/getAllProjects';
import getIndustryTypes from 'Components/Action/Utils/getIndustryTypes';
import getCommunityData from 'Components/Dashboard/Utils/getCommunityData';
import getFootPrintData from 'Components/Footprint/Utils/getFootPrintData';
import AuthUserInterface from 'Components/Shared/Interfaces/AuthUserInterface';
import MyDispatch from 'Components/Shared/Interfaces/Dispatch';
import {storeUserToken} from 'Components/Shared/Utils/storeUserToken';
import getWidgets from 'Components/Widgets/Utils/getWidget';
import getWidgetConfig from 'Components/Widgets/Utils/getWidgetConfig';
import getWidgetData from 'Components/Widgets/Utils/getWidgetData';

import createActionStore from './Stores/Action/createActionStore';
import createContinentStore from './Stores/Action/createContinentStore';
import createCountryStore from './Stores/Action/createCountryStore';
import createIndustryTypeStore from './Stores/Action/createIndustryTypeStore';
import createProjectStore from './Stores/Action/createProjectStore';
import createFootprintStore from './Stores/Footprint/createFootprintStore';
import createLocationStore from './Stores/Footprint/createLocationStore';
import createCompanyStore from './Stores/User/createCompanyStore';
import createUserStore from './Stores/User/createUserStore';
import createCommunityStore from './Stores/Widget/createCommunityStore';
import createWidgetConfigurationStore from './Stores/Widget/createWidgetConfigurationStore';
import createWidgetDataStore from './Stores/Widget/createWidgetDataStore';
import createWidgetStore from './Stores/Widget/createWidgetStore';
import createYearStore from './Stores/Widget/createYearStore';
import getUserInfo from './Utils/getUserInfo';
import ingestGlobalConfigInWindow from './Utils/ingestGlobalConfigInWindow';

const connectSubscriptions: (
  dispatch: MyDispatch,
  user: AuthUserInterface,
  getIdTokenClaims: () => Promise<IdToken | undefined>
) => Promise<void> = async (dispatch, user, getIdTokenClaims) => {
  await ingestGlobalConfigInWindow();
  await storeUserToken(getIdTokenClaims);
  await connectStoreSubscriptions(dispatch, user);
};
const connectStoreSubscriptions: (
  dispatch: MyDispatch,
  user: AuthUserInterface
) => Promise<void> = async (dispatch, user) => {
  await connectUserSubscriptions(dispatch, user);

  //await connectFootPrintDataSubscription(dispatch);
};

export const connectWidgetSub: (dispatch: MyDispatch) => Promise<void> = async (
  dispatch
) => {
  await connectWidgetSubscription(dispatch);
  await connectWidgetConfigSubscription(dispatch);
  await connectWidgetDataSubscription(dispatch);
};

export const connectAction: (dispatch: MyDispatch) => Promise<void> = async (
  dispatch
) => {
  await connectActionDataSubscription(dispatch);
};
const connectUserSubscriptions: (
  dispatch: MyDispatch,
  user: AuthUserInterface
) => Promise<void> = async (dispatch, user) => {
  await getUserInfo().then((res) => {
    if (res && user) {
      const copy = {...res, profilePhoto: user?.picture as string};
      dispatch(createUserStore.actions.setUserInfo(copy));
      dispatch(createUserStore.actions.ingestGlobalConfigInWindow(copy));
      dispatch(createCompanyStore.actions.setCompanyInfo(copy?.company));
      dispatch(
        createCompanyStore.actions.ingestGlobalConfigInWindow(copy?.company)
      );
    }
  });
};
const connectWidgetSubscription: (
  dispatch: MyDispatch
) => Promise<void> = async (dispatch) => {
  await getWidgets().then((res) => {
    if (res) {
      res.forEach((item) => {
        dispatch(createWidgetStore.actions.setWidgets(item));
      });
    }
  });
};
const connectWidgetConfigSubscription: (
  dispatch: MyDispatch
) => Promise<void> = async (dispatch) => {
  await getWidgetConfig().then((res) => {
    if (res) {
      dispatch(createWidgetConfigurationStore.actions.setWidgetConfig(res));
    }
  });
};
const connectWidgetDataSubscription: (
  dispatch: MyDispatch
) => Promise<void> = async (dispatch) => {
  await getCommunityData().then((res) => {
    if (res) {
      dispatch(createCommunityStore.actions.community(res));
    }
  });
  await getWidgetData().then((res) => {
    if (res) {
      dispatch(createWidgetDataStore.actions.setWidgetData(res));
      dispatch(createYearStore.actions.setYears(res));
    }
  });
};

const connectActionDataSubscription: (
  dispatch: MyDispatch
) => Promise<void> = async (dispatch) => {
  await getActionData().then((res) => {
    if (res) {
      dispatch(createActionStore.actions.setActionData(res));
    }
  });
  await getAllContinents().then((res) => {
    if (res) {
      dispatch(createContinentStore.actions.setContinents(res));
    }
  });

  await getAllCountries().then((res) => {
    if (res) {
      dispatch(createCountryStore.actions.setCountries(res));
    }
  });

  await getIndustryTypes().then((res) => {
    if (res) {
      dispatch(createIndustryTypeStore.actions.setIndustryTypes(res));
    }
  });

  await getAllProjects().then((res) => {
    if (res) {
      dispatch(createProjectStore.actions.setProjects(res));
    }
  });
};
export const connectFootPrintDataSubscription: (
  dispatch: MyDispatch
) => Promise<void> = async (dispatch) => {
  await getFootPrintData().then((res) => {
    if (res) {
      dispatch(createFootprintStore.actions.setFootPrintData(res));
      dispatch(createLocationStore.actions.setLocations(res));
    }
  });
};

export default connectSubscriptions;
