import {
  Box,
  FormControl,
  Select,
  MenuItem,
  TextField,
  InputAdornment,
  Tooltip as MuiTooltip
} from '@mui/material';
import Crown from 'Assets/crown.svg';
import {t} from 'i18next';

import Emmision from '../Constants/Emmisions';
import CalculateFormProps from '../Interfaces/CalculateFormProps';

/**
 * CalculateForm renders the form for the values to be taken from the user
 * It takes the values for calculation and result is shown on FootPrintCalculator
 * @component
 */
const CaluclateForm: React.FC<CalculateFormProps> = ({
  emmitor,
  handleChange,
  value,
  setValue
}) => {
  return (
    <Box className='centre marginY tile-form'>
      <FormControl>
        <Select
          defaultValue={String(emmitor)}
          onChange={handleChange}
          displayEmpty
          inputProps={{'aria-label': 'Without label'}}
          sx={{
            width: '140px',
            fieldset: {borderColor: 'white'}
          }}
        >
          <MuiTooltip title='Premium Only'>
            <span>
              <MenuItem
                disabled={true}
                sx={{opacity: '1 !important'}}
                value={Emmision.FUEL}
              >
                <Box
                  display='flex'
                  justifyContent='space-between'
                  gap={1}
                  alignItems='center'
                >
                  <img alt='' src={Crown}></img> {t('FUEL')}
                </Box>
              </MenuItem>
            </span>
          </MuiTooltip>
          <MenuItem value={Emmision.ELECTRICITY}>
            <Box
              display='flex'
              justifyContent='space-between'
              gap={1}
              alignItems='center'
            >
              {'     '}
              {t('ELECTRICITY')}
            </Box>
          </MenuItem>
          <MuiTooltip title='Premium Only'>
            <span>
              <MenuItem
                disabled={true}
                sx={{opacity: '1 !important'}}
                value={Emmision.WATER}
              >
                <Box
                  display='flex'
                  justifyContent='space-between'
                  gap={1}
                  alignItems='center'
                >
                  <img alt='' src={Crown}></img> {t('WATER')}
                </Box>
              </MenuItem>
            </span>
          </MuiTooltip>
          <MuiTooltip title='Premium Only'>
            <span>
              <MenuItem
                disabled={true}
                sx={{opacity: '1 !important'}}
                value={Emmision.TRANSPORTATION}
              >
                <Box
                  display='flex'
                  justifyContent='space-between'
                  gap={1}
                  alignItems='center'
                >
                  <img alt='' src={Crown}></img> {t('TRANSPORTATION')}
                </Box>
              </MenuItem>
            </span>
          </MuiTooltip>
        </Select>
      </FormControl>
      <TextField
        className='marginX'
        placeholder='Value in kWh'
        type='number'
        value={value}
        onChange={(e) => {
          setValue(e.target.value as unknown as number);
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment sx={{color: '#fff !important'}} position='end'>
              kWh
            </InputAdornment>
          )
        }}
        onKeyDown={(event) => {
          if (event.code === 'ArrowDown' || event.code === 'ArrowUp') {
            event.preventDefault();
          }
        }}
        onFocus={(e) => {
          e.target.addEventListener(
            'wheel',
            function (e) {
              e.preventDefault();
            },
            {passive: false}
          );
        }}
        sx={{width: '190px', fieldset: {borderColor: 'white !important'}}}
      />
    </Box>
  );
};

export default CaluclateForm;
