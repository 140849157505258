import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import UserState from 'State/Constants/UserState';
import UserInfo from 'State/Interfaces/UserInfo';

/**
 * Redux slice for the user store.
 */
const createUserStore = createSlice({
  name: 'user',
  initialState: UserState,
  reducers: {
    /**
     * Sets the user information in the state.
     * @param {UserState} state - The current state.
     * @param {PayloadAction<UserInfo>} action - The action containing the user information payload.
     */
    setUserInfo: (state, action: PayloadAction<UserInfo>) => {
      state.email = action.payload.email;
      state.firstName = action.payload.firstName;
      state.lastName = action.payload.lastName;
      state.phone = action.payload.phone;
      state.address = action.payload.address;
      state.isVerified = action.payload.isVerified;
      state.isGuest = action.payload.isGuest;
      state.profilePhoto = action.payload.profilePhoto;
      state.userType = action.payload.userType;
      state._id = action.payload._id;
    },
    /**
     * Ingests the global configuration in the window object.
     * @param {UserState} state - The current state.
     * @param {PayloadAction<UserInfo>} action - The action containing the user information payload.
     */
    ingestGlobalConfigInWindow: (state, action: PayloadAction<UserInfo>) => {
      window.bharatCarbon.userInfo = {
        email: action.payload.email,
        firstName: action.payload.firstName,
        lastName: action.payload.lastName,
        phone: action.payload.phone,
        address: action.payload.address,
        isVerified: action.payload.isVerified,
        isGuest: action.payload.isGuest,
        profilePhoto: action.payload.profilePhoto,
        userType: action.payload.userType,
        _id: action.payload._id
      };
    }
  }
});

export default createUserStore;
