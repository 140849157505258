import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import ProgressState from 'Components/Shared/Constants/ProgressState';
import {Kpis} from 'Components/Shared/Interfaces/Kpis';

/**
 * Redux slice for the progress page store.
 */
const createProgressStore = createSlice({
  name: 'kpiValues',
  initialState: ProgressState,
  reducers: {
    /**
     * Updates the values in the state for a specific KPI.
     * @param {ProgressState} state - The current state.
     * @param {PayloadAction<Kpis>} action - The action containing the KPI values payload.
     */
    updateValues: (state, action: PayloadAction<Kpis>) => {
      const index = state.findIndex((item) => item?._id === action?.payload._id);
      if (index !== -1) {
        state[index].initiativeValues = action?.payload?.initiativeValues;
        state[index].priority = action?.payload.priority;
      } else {
        state.push(action?.payload);
      }
    }
  }
});

// Export the store
export default createProgressStore;
