import CountryService from 'Services/Countries';

const getAllCountries = async (): Promise<any> => {
  try {
    const res = await CountryService();
    if (res) {
      
      return res;
    }
  } catch (error) {
    console.error('Error fetching continents:', error);
  }
};

export default getAllCountries;
