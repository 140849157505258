import {SpeedometerConstants} from '../Interfaces/Speedometer';

export const SPEEDOMETER_CONSTANTS: SpeedometerConstants = {
  data: [
    {label: '0', name: '', value: 0, color: ''},
    {label: '', name: 'Fair', value: 40, color: '#f27260'},
    {label: '40', name: '', value: 0, color: ''},
    {label: '', name: 'Good', value: 20, color: '#8BCBBF'},
    {label: '60', name: '', value: 0, color: ''},
    {label: '', name: 'Very Good', value: 20, color: '#58B0A0'},
    {label: '80', name: '', value: 0, color: ''},
    {label: '', name: 'Excellent', value: 20, color: '#1f8d79'},
    {label: '100', name: '', value: 0, color: ''}
  ],
  cx: 200,
  cy: 200,
  iR: 100,
  oR: 150,
  color: '#024064'
};
