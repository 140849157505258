// Import AxiosResponse to add type to response data
import axios, {AxiosResponse} from 'axios';
import UserInfo from 'State/Interfaces/UserInfo';

interface ResponseType {
  data: UserInfo;
}

/**
 * Retrieves UserInfo .
 * @returns {Promise<InitiativeScore[]>} A promise that resolves to an UserInfo.
 */
// Add Promise type and define return type as BenchMark[]
const getUserData = async (): Promise<UserInfo> => {
  const API_BASE_PATH = process.env.REACT_APP_API_PATH;
  const API_GET_USER_DATA = `${API_BASE_PATH}${process.env.REACT_APP_API_GET_USER_DATA}`;
  try {
    /** Use AxiosResponse to add type to response data */
    const response: AxiosResponse<ResponseType> = await axios.get(
      API_GET_USER_DATA,
      {
        headers: {
          authorization: `Bearer ${window?.bharatCarbon?.auth?.token}`
        }
      }
    );
    // eslint-disable-next-line no-console

    return response?.data?.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    /** Throw the error to be caught by the calling function */
    throw error;
  }
};

export default getUserData;
