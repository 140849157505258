import ContinentService from 'Services/Continent';

const getAllContinents = async (): Promise<any> => {
  try {
    const res = await ContinentService();
    if (res) {
      
      return res;
    }
  } catch (error) {
    console.error('Error fetching:', error);
  }
};

export default getAllContinents;
