/* eslint-disable no-console */
import RenderListProps from '@charts/Interfaces/RenderListProps';
import {Locations} from 'Components/Footprint/Interfaces/FootPrintData';

import ListWidgetData from '../Interfaces/ListWidgetData';

/**
 * Filters the provided data based on the scope value and selected locations.
 * Calculates the sum of values for each location and returns an array of `RenderListProps`.
 *
 * @param data - The list widget data to filter.
 * @param scopeValue - The scope value to filter the data by.
 * @param selectedLocation - The selected locations to include in the result.
 * @returns An array of `RenderListProps` containing the filtered and calculated data.
 */
const getFilterData = (
  data: ListWidgetData[],
  scopeValue: string,
  selectedLocation: Locations[]
): RenderListProps[] => {
  const scopeFilter = data.filter((item: ListWidgetData) => {
    return item.dataId === scopeValue;
  });

  const resultArray: RenderListProps[] = selectedLocation.map(
    (i: Locations) => {
      let value: number = 0;
      const locationFilterData: ListWidgetData[] = scopeFilter.filter(
        (e) => i._id === e.locationId
      );
      locationFilterData.forEach((l) => {
        value += l.value;
      });
      const unit = scopeFilter[0]?.unit;
      const benchmark = scopeFilter[0]?.benchmark;

      return {
        ...i,
        value: Number(value.toFixed(2)),
        unit: unit,
        benchmark: benchmark as number
      };
    }
  );

  return resultArray;
};

export default getFilterData;
