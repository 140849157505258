import React from 'react';
import {useSelector} from 'react-redux';
import {ScatterChart, Scatter, XAxis, YAxis, CartesianGrid, ResponsiveContainer, ReferenceLine, Legend} from 'recharts';

import StoreInterface from '../../State/Interfaces/StoreInterface';

import ScatterIcon from './Layouts/ScatterIcon';
import ScatterQuadrantLegend from './Layouts/ScatterQuadrantLegend';

const ScatterQuadrant: React.FC = () => {
    const selectedKpis = useSelector((state: StoreInterface) => state.kpiValues);

    return (
        <ResponsiveContainer width="100%" height={400}>
            <ScatterChart margin={{top: 20, right: 20, bottom: 20, left: -20}} className="scatter-opacity-grid">
                <CartesianGrid
                    verticalFill={[
                        '#F27260',
                        '#F27260',
                        '#F27260',
                        '#F27260',
                        '#8BCBBF',
                        '#8BCBBF',
                        '#58B0A0',
                        '#58B0A0',
                        '#1F8D79',
                        '#1F8D79'
                    ]}
                    fillOpacity={0.1}
                    strokeDasharray="5 5"
                />
                <XAxis domain={[0, 100]} tickCount={11} type="number" dataKey="value" />
                <Legend wrapperStyle={{top: 0, left: 25}} content={<ScatterQuadrantLegend />} />
                <YAxis
                    domain={[0, 120]}
                    tickCount={7}
                    type="number"
                    dataKey="y"
                    tickLine={false}
                    stroke="none"
                />
                <ReferenceLine x="40" stroke="#F27260" />
                <ReferenceLine x="60" stroke="#58B0A0" />
                <ReferenceLine x="80" stroke="#1F8D79" />
                {selectedKpis.map((item, index) => {
                    return (
                        <Scatter
                            key={index}
                            shape={props => <ScatterIcon {...props} kpiName={item.kpiName} icon={item.icon} />}
                            name={item.kpiName}
                            data={item.initiativeValues.filter(item => item.checked !== false && item.value !== 0)}
                            fill="#8884d8"
                        />
                    );
                })}
            </ScatterChart>
        </ResponsiveContainer>
    );
};

export default ScatterQuadrant;
