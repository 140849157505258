import {Box, Button} from '@mui/material';
import React from 'react';
import {Link} from 'react-router-dom';

import Exclamation from '../../../Assets/Exclamation.svg';
import RoutesURLs from '../Constants/Routes';

const GuestUpgradePremium: React.FC<{setOpen: React.Dispatch<React.SetStateAction<boolean>>}> = ({setOpen}) => {
    return (
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
            <img src={Exclamation} alt="not premium" style={{width: '16px', height: '98px'}} />
            <Box sx={{mt: '16px'}}>Sorry</Box>
            <Box sx={{mt: '16px'}}>This feature is not available on this user type</Box>
            <Box sx={{mt: '10px'}}>Please subscribe to avail all features</Box>
            <Button
                component={Link}
                sx={{
                    width: '151px',
                    background: '#9FD983 0% 0% no-repeat padding-box',
                    borderRadius: '4px',
                    opacity: 1,
                    textDecoration: 'none',
                    padding: '8px 34px',
                    color: '#024064',
                    textAlign: 'center',
                    mt: '70px',
                    '&:hover': {
                        background: '#9FD983 0% 0% no-repeat padding-box'
                    }
                }}
                onClick={() => {
                    setOpen(false);
                }}
                to={RoutesURLs.SCHEDULE_CALL}
            >
                Subcribe
            </Button>
        </Box>
    );
};

export default GuestUpgradePremium;
