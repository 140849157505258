// Import AxiosResponse to add type to response data
import axios, {AxiosResponse} from 'axios';
import BenchMark from 'Components/Guest/Interfaces/BenchMark';

interface ResponseType {
  data: BenchMark[];
}

/**
 * Retrieves benchmark data.
 * @returns {Promise<BenchMark[]>} A promise that resolves to an array of benchmark data.
 */
const getBenchMark = async (): Promise<BenchMark[]> => {
  const INDUSTRY = window?.bharatCarbon?.company?.category;
  const API_BASE_PATH = process.env.REACT_APP_API_PATH;
  const API_GET_BENCHMARK = `${API_BASE_PATH}${process.env.REACT_APP_API_GET_INDUSTRYBENCHMARK}?industry=${INDUSTRY}`;

  try {
    /** Use AxiosResponse to add type to response data */
    const response: AxiosResponse<ResponseType> = await axios.get(
      API_GET_BENCHMARK,
      {
        headers: {
          authorization: `Bearer ${window?.bharatCarbon?.auth?.token}`
        }
      }
    );

    return response.data.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    /** Throw the error to be caught by the calling function */
    throw error;
  }
};

export default getBenchMark;
