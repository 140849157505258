/* eslint-disable no-console */
import {
  Box,
  Checkbox,
  FormControl,
  ListSubheader,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography
} from '@mui/material';
import {Locations} from 'Components/Footprint/Interfaces/FootPrintData';

import MultiSelectProps from './Interfaces/MultiSelectProps';
import Tooltip from './Tooltip';

/**
 * Customized MultiSelect Component where we can select multiple options
 * @component
 */

const MultiSelect: React.FC<MultiSelectProps> = ({
  selectedValues,
  setValues,
  options
}) => {
  const handleChange = (event: SelectChangeEvent<any>) => {
    const {
      target: {value}
    } = event;

    let duplicateRemoved: Locations[] = [];

    value.forEach((item: Locations) => {
      if (duplicateRemoved.findIndex((o) => o._id === item._id) >= 0) {
        duplicateRemoved = duplicateRemoved.filter((x) => x._id !== item._id);
      } else {
        duplicateRemoved.push(item);
      }
    });

    setValues(duplicateRemoved);
  };

  const renderSelectGroup = (product: [string, Locations[]]) => {
    const items = product[1].map((p) => {
      return (
        <MenuItem className='multiselect' key={p._id} value={p as any}>
          <Box
            display='flex'
            alignItems='center'
            justifyContent='space-between'
          >
            <Checkbox
              checked={
                selectedValues.findIndex(
                  (item: Locations) => item._id === p._id
                ) >= 0
              }
            />
            {p.title}
            <Tooltip
              message={
                <Typography style={{whiteSpace: 'pre-line'}}>
                  {p.title +
                    '\n' +
                    (p.addressLine1 ? p.addressLine1 : p.addressLine2) +
                    '\n' +
                    p.city +
                    ' ' +
                    p.state +
                    ' ' +
                    p.zipCode +
                    ' ' +
                    p.country}
                </Typography>
              }
            />
          </Box>
        </MenuItem>
      );
    });

    return [<ListSubheader>{product[0]}</ListSubheader>, items];
  };

  return (
    <>
      <FormControl sx={{m: 1, minWidth: 120}}>
        <Select
          multiple
          value={selectedValues}
          onChange={handleChange}
          displayEmpty={true}
          renderValue={() => {
            return <>{`${selectedValues?.length} Locations selected`}</>;
          }}
        >
          {Object.entries(options)?.map((p) => renderSelectGroup(p))}
        </Select>
      </FormControl>
    </>
  );
};

export default MultiSelect;
