import {Box} from '@mui/material';
import TooltipMessage from 'Components/Shared/Constants/TooltipMessages';
import React, {useEffect} from 'react';
import {useSelector} from 'react-redux';
import StoreInterface from 'State/Interfaces/StoreInterface';

import Widget from '../Interfaces/Widget';
import getLocationsGroup from '../Utils/getLocationsGroup';

import ReturnChartComponent from './ReturnChartComponent';
import WidgetTitle from './WidgetTitle';

/**
 * Cards Widget Component
 * @component
 */
const CardsWidget: React.FC<Widget> = (widget) => {
  const years = useSelector((state: StoreInterface) => state.years);
  const [yearValue, setYearValue] = React.useState<string>(
    years?.[years.length - 1]
  );
  const locations = useSelector((state: StoreInterface) => state.locations);
  const [locationId, setLocationId] = React.useState<string>(locations[0]?._id);
  const locationGroups = getLocationsGroup(locations);

  useEffect(() => {
    setLocationId(locations[0]?._id);
  }, [locations]);

  return (
    <Box className='dash-cards card-widget title-bg-only' id='fp-third'>
      <WidgetTitle
        name={widget?.name}
        yearValue={yearValue}
        setYearValue={setYearValue}
        locationEnabled={true}
        locationData={locationGroups}
        locationId={locationId}
        setLocationId={setLocationId}
        TooltipMessage={TooltipMessage.FOOTPRINT_LIST_OF_ACTIVITIES}
      />
      <Box className='card-data simple-cards'>
        {widget?.tabs?.length ? (
          <Box>
            <ReturnChartComponent
              year={yearValue}
              item={widget.tabs}
              widget={widget}
              locationId={locationId}
            />
          </Box>
        ) : (
          <></>
        )}
      </Box>
    </Box>
  );
};

export default CardsWidget;
