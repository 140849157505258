import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import {AppBar, Box, Typography} from '@mui/material';
import React from 'react';
import {useSelector} from 'react-redux';
import {useLocation} from 'react-router-dom';

import userImg from '../../Assets/userimg.png';
import StoreInterface from '../../State/Interfaces/StoreInterface';

import RoutesURLs from './Constants/Routes';

/**
 * Topbar Pure Component to show welcome message, user name and other details
 * @component
 */
const Topbar: React.FC = () => {
  const loggedUser = useSelector((state: StoreInterface) => state.user);
  const companyInfo = useSelector((state: StoreInterface) => state.company);
  const location = useLocation();
  const headColour = location.pathname === RoutesURLs.SCHEDULE_CALL;

  return (
    <>
      <AppBar
        position='sticky'
        className={loggedUser.isGuest ? 'header transparent' : 'header'}
      >
        <Box className='userInfo'>
          <img
            alt=''
            src={loggedUser?.profilePhoto ? loggedUser?.profilePhoto : userImg}
          ></img>
          <Box className='ui-data'>
            <Box className={`user-name ${headColour ? 'schedule' : ''}`}>
              Welcome{' '}
              {loggedUser?.firstName
                ? `${loggedUser?.firstName} ! | ${
                    companyInfo?.businessName ? companyInfo?.businessName : ''
                  }`
                : 'Guest'}
            </Box>
            <Box className={`user-email ${headColour ? 'schedule' : ''}`}>
              {loggedUser?.email ? loggedUser?.email : ''}
            </Box>
          </Box>
        </Box>
        <Box className='topicon-sets'>
          {!loggedUser.isGuest && (
            <>
              <Box>
                <Typography>Call us - 999-999-9999</Typography>
              </Box>
              <span>&nbsp; or</span>
            </>
          )}
          <Box>
            <a
              href='https://web.whatsapp.com/'
              target='_blank'
              rel='noreferrer'
            >
              <WhatsAppIcon />
            </a>
          </Box>
        </Box>
      </AppBar>
    </>
  );
};

export default Topbar;
