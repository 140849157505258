/* eslint-disable no-console */
import {Box, Button} from '@mui/material';
import axios from 'axios';
import Loader from 'Components/Shared/Loader';
import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';

import SuccessIcon from '../../../Assets/success-icon.svg';

const PaymentSuccessful: () => JSX.Element = () => {
  const [data, setData] = useState<any>('');
  const [timeLeft, setTimeLeft] = useState<number | null>(null);
  const urlParams = new URLSearchParams(window.location.search);
  const sessionId = urlParams.get('session_id');
  const companyId = urlParams.get('companyId');
  const invoiceId = urlParams.get('invoiceId');
  const API_BASE_PATH = process.env.REACT_APP_API_PATH;
  const navigate = useNavigate();

  useEffect(() => {
    axios
      .get(
        `${API_BASE_PATH}/payment/status?session_id=${sessionId}&companyId=${companyId}&invoiceId=${invoiceId}`,
        {
          headers: {
            authorization: `Bearer ${window?.bharatCarbon?.auth?.token}`
          }
        }
      )
      .then((res) => {
        setData(res.status);
        setTimeLeft(5);
      })
      .catch((err) => {
        return err;
      });
  }, [sessionId]);

  useEffect(() => {
    if (timeLeft !== null) {
      if (timeLeft === 0) {
        // navigate('/dashboard', {state: {refresh: true}});
      }
      if (timeLeft > 0) {
        const timer = setTimeout(() => {
          setTimeLeft(timeLeft - 1);
        }, 1000);

        return () => {
          clearTimeout(timer);
        };
      }
    }
  }, [timeLeft]);

  const login = () => {
    navigate('/dashboard', {state: {refresh: true}});
  };

  return !data ? (
    <Loader />
  ) : (
    <Box className='pay-page flex-center'>
      <Box className='payment-status'>
        <img src={SuccessIcon} alt='Short Logo'></img>
        <Box className='ps-status-msg success'>Payment Successful !</Box>
        <Box className='ps-status-desc'>
          Your payment is successful. We have sent the invoice to your
          registered email id.
          <Box className='highlight'>Your climate journey starts here!</Box>
        </Box>
        <Button className='btn btn-secondry' onClick={login}>
          Login Now
        </Button>
        {/* <img
        src={require('../../../Assets/congrats.gif')}
        alt='congrats'
        style={{width: '100%', height: '90vh'}}
      /> */}
        {/* <Box>{timeLeft}</Box> */}
      </Box>
    </Box>
  );
};

export default PaymentSuccessful;
