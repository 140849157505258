/* eslint-disable no-console */
import {Box, Grid} from '@mui/material';
import TooltipMessage from 'Components/Shared/Constants/TooltipMessages';
import LinearLoader from 'Components/Shared/LinearLoader';
import Tooltip from 'Components/Shared/Tooltip';
import {useEffect, useState} from 'react';

import RenderListProps from '../Interfaces/RenderListProps';

const RenderList: React.FC<{
  data: RenderListProps[];
  benchmark: string | undefined;
}> = ({data, benchmark}) => {
  const [list, setList] = useState<RenderListProps[]>([]);

  useEffect(() => {
    if (benchmark === '-1') {
      setList(data?.filter((i) => i.value <= i.benchmark));
    } else if (benchmark === '1') {
      setList(data?.filter((i) => i.value > i.benchmark));
    } else {
      setList(data);
    }
  }, [data, benchmark]);

  return list.length === 0 ? (
    <LinearLoader />
  ) : (
    <Box width='100%'>
      {list?.length === 0 ? (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%'
          }}
        >
          No Locations found for selected filters
        </Box>
      ) : (
        list
          .sort((a: {value: number}, b: {value: number}) =>
            a.value < b.value ? 1 : -1
          )
          .map((location, index: number) => {
            const isAbove = location?.value > location?.benchmark;

            return (
              <Grid
                className={`locationList ${isAbove ? 'above' : 'below'}`}
                container
                key={index}
              >
                <Grid item xs={1}>
                  {index + 1}
                </Grid>
                <Grid item xs={3}>
                  {location?.title}
                </Grid>
                <Grid item xs={3}>
                  {isAbove ? 'Above Benchmark' : 'Below Benchmark'}
                </Grid>
                <Grid item xs={3}>
                  {isAbove ? (
                    <Box className='flex-acenter flag flagAbove'>
                      <Tooltip message={TooltipMessage.CARBON_NEGATIVE} />
                      <Box className='negative'> Carbon Negative</Box>
                    </Box>
                  ) : (
                    <Box className='flex-acenter flag flagBelow'>
                      <Tooltip message={TooltipMessage.CARBON_POSITIVE} />{' '}
                      <Box className='positive'>Carbon Positive</Box>
                    </Box>
                  )}
                </Grid>
                <Grid textAlign='right' item xs={2}>
                  {location?.value} {location?.unit}
                </Grid>
              </Grid>
            );
          })
      )}
    </Box>
  );
};

export default RenderList;
