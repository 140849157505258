import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import Continent from 'Components/Action/Interface/Continent';


/**
 * Redux slice for the progress page store.
 */
const createContinentStore = createSlice({
  name: 'continent',
  initialState: [] as Continent[],
  reducers: {
    /**
     * Updates the values in the state for a continent.
     * @param {ProgressState} state - The current state.
     * @param {PayloadAction<Kpis>} action - The action containing the continent payload.
     */
    setContinents: (state, action: PayloadAction<Continent[]>) => {
        action?.payload?.forEach(i => {
            state.push(i);
        });
    }
  }
});

// Export the store
export default createContinentStore;
