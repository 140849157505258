import {Box} from '@mui/material';
import FormBuilder from 'Components/Shared/FormBuilder';
import React from 'react';

const Principle2: React.FC = () => {
  const companyId = window?.bharatCarbon?.company?._id;
  const handleSubmit = (data: any) => {
    data.companyId = companyId;
    // eslint-disable-next-line no-console
    console.log(data);
  };

  return (
    <Box sx={{padding: '30px 24px 24px 24px'}}>
      <FormBuilder
        title='Principle 2 : Businesses Should Provide Goods And Services In A Manner
        That Is Sustainable And Safe'
        fields={[
          {type: 'div', label: 'Details', key: ''},
          {
            type: 'textarea',
            label: `Percentage of R&D and capital expenditure (capex) investments in specific technologies to improve
        the environmental and social impacts of product and processes to total R&D and capex investments
        made by the Company, respectively.`,
            key: 'percentR&D'
          },
          {
            type: 'textarea',
            label: `Does the Company have procedures in place for sustainable sourcing?
            If yes, what percentage of inputs were sourced sustainably?`,
            key: 'sustainableSourcing'
          },
          {
            type: 'textarea',
            label: `Describe the processes in place to safely reclaim
             your products for reusing, recycling, and disposing
            at the end of life.`,
            key: 'sustainableSourcing'
          },
          {
            type: 'textarea',
            label: `Whether Extended Producer Responsibility (EPR) is applicable to the Company’s activities (Yes / No).
            If yes, whether the waste collection plan is in line with the Extended Producer Responsibility (EPR)
            plan submitted to Pollution Control Boards? If not, provide steps taken to address the same.`,
            key: 'EPR'
          },
          {type: 'div', label: 'Leadership Indicator', key: ''},
          {
            type: 'textarea',
            label: `Has the Company conducted Life Cycle Perspective / Assessments (LCA) for any of its products (for
                manufacturing industry) or for its services (for service industry)?`,
            key: 'LCA'
          },
          {
            type: 'textarea',
            label: `If there are any significant social or environmental 
            concerns and/or risks arising from production or
            disposal of your products / services, as identified in the Life Cycle Perspective / Assessments (LCA)
            or through any other means, briefly describe the same along-with action taken to mitigate the same.`,
            key: 'LCADetails'
          },
          {
            type: 'text',
            label: `Percentage of recycled or reused input material to total material (by value) used in production (for
            manufacturing industry) or providing services (for service industry).`,
            key: 'percentRecycled'
          },
          {
            type: 'table',
            label: `Of the products and packaging reclaimed at end of life of products, amount (in metric tonnes)
            reused, recycled, and safely disposed, as per the following format`,
            key: 'lifeOfSun',
            static: [
              `Plastics
            (including packaging)`,
              'E-waste',
              'Hazardous waste',
              'Other waste'
            ],
            options: [
              {type: 'box', label: 'Particulars', key: 'particulars'},
              {
                type: 'textarea',
                label: 'Re-Used in Current FY',
                key: 'reUsedCurrFY'
              },
              {
                type: 'textarea',
                label: 'Recycled in Current FY',
                key: 'recycledCurrFY'
              },
              {
                type: 'textarea',
                label: `Safely
                Disposed in Current FY`,
                key: 'disposedCurrFY'
              },
              {
                type: 'textarea',
                label: 'Re-Used in last FY',
                key: 'reUsedLastFY'
              },
              {
                type: 'textarea',
                label: 'Recycled in last FY',
                key: 'recycledLastFY'
              },
              {
                type: 'textarea',
                label: `Safely
                Disposed in last FY`,
                key: 'disposedLastFY'
              }
            ]
          },
          {
            type: 'textarea',
            label: `Reclaimed products and their packaging materials (as percentage of products sold) for each product
            category.`,
            key: 'reclaimedProducts'
          }
        ]}
        onSubmit={handleSubmit}
      />
    </Box>
  );
};

export default Principle2;
