import {Box} from '@mui/material';
import React from 'react';

import TabPanelProps from './Interfaces/TabPanelProps';

/**
 * TabPanel Component which shows the section for the selected tab 
 * @component
 */
const TabPanel: React.FC<TabPanelProps> = ({children, value, index}) => {
	
	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
		>
			{value === index && (
				<Box sx={{p: 3}}>
					<div>{children}</div>
				</Box>
			)}
		</div>
	);
};

export default TabPanel;
