import EditIcon from '@mui/icons-material/Edit';
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Paper
} from '@mui/material';
import RoutesURLs from 'Components/Shared/Constants/Routes';
import LinearProgressWithLabel from 'Components/Shared/Layouts/LinearProgressWithLabel';
import React from 'react';
import {Link} from 'react-router-dom';

const BRSR: React.FC = () => {
  return (
    <Box className='foot-container'>
      <Box
        className='dash-cards'
        sx={{
          font: 'normal normal medium 18px/17px Montserrat',
          background: '#FFFFFF 0% 0% no-repeat padding-box',
          boxShadow: '0px 3px 6px #E0EFFD',
          color: '#677489',
          margin: '8px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
          padding: '18px',
          height: '71px !important'
        }}
      >
        Business Responsibility and Sustainability Report
      </Box>
      <Grid container spacing={2} sx={{padding: '10px'}}>
        <Grid item xs={6} md={6} sm={6}>
          <Paper
            sx={{
              display: 'flex',

              boxShadow: '0px 3px 6px #E0EFFD',
              flexDirection: 'column'
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                flex: 1,
                color: '#677489',
                margin: '20px 20px 10px 20px'
              }}
            >
              <Box
                sx={{
                  font: 'normal normal medium 18px/17px Montserrat',
                  letterSpacing: '0.56px'
                }}
              >
                Section A
              </Box>
              <Link
                to={`${RoutesURLs.ESG_REPORTING}/${RoutesURLs.BRSR}/${RoutesURLs.SECTIONA}`}
                style={{
                  border: '1px solid #AFAFAF',
                  borderRadius: '4px',
                  display: 'flex',
                  alignItems: 'center',
                  padding: '5px',
                  gap: 2,
                  color: '#677489',
                  font: 'normal normal 600 14px/16px Quicksand',
                  width: '80px',
                  justifyContent: 'center'
                }}
              >
                <EditIcon fontSize='small' sx={{aspectRatio: '3'}} />
                EDIT
              </Link>
            </Box>
            <Box sx={{padding: '0px 20px 0px 20px'}}>
              <LinearProgressWithLabel progress={20} />
            </Box>
            <Box>
              <FormControl
                sx={{
                  padding: '20px',
                  font: 'normal normal medium 14px/22px Quicksand'
                }}
                component='fieldset'
                variant='standard'
              >
                <FormGroup>
                  <FormControlLabel
                    sx={{
                      font: 'normal normal medium 14px/22px Quicksand',
                      color: '#121212',
                      lineHeight: '1'
                    }}
                    control={
                      <Checkbox
                        checked={true}
                        readOnly
                        disableTouchRipple
                        disableFocusRipple
                        disableRipple
                        color='success'
                      />
                    }
                    label='Whether the entity has translated the policy into procedures.'
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={false}
                        readOnly
                        disableTouchRipple
                        disableFocusRipple
                        disableRipple
                        color='success'
                      />
                    }
                    label='Do the enlisted policies extend to your value chain partners?'
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={false}
                        readOnly
                        disableTouchRipple
                        disableFocusRipple
                        disableRipple
                        color='success'
                      />
                    }
                    label='Specific commitments, goals and targets set by the entity with defined timelines, if any.'
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={false}
                        readOnly
                        disableTouchRipple
                        disableFocusRipple
                        disableRipple
                        color='success'
                      />
                    }
                    label='Details of Review of NGRBCs by the Company'
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={false}
                        readOnly
                        disableTouchRipple
                        disableFocusRipple
                        disableRipple
                        color='success'
                      />
                    }
                    label='If answer to question (1) above is “No” i.e. not all Principles are covered by 
                    a policy, reasons to be stated'
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={false}
                        readOnly
                        disableTouchRipple
                        disableFocusRipple
                        disableRipple
                        color='success'
                      />
                    }
                    label='Details as at the end of Financial Year'
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={false}
                        readOnly
                        disableTouchRipple
                        disableFocusRipple
                        disableRipple
                        color='success'
                      />
                    }
                    label='Participation/Inclusion/Representation of women'
                  />
                </FormGroup>
              </FormControl>
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={6} md={6} sm={6}>
          <Paper
            sx={{
              display: 'flex',
              boxShadow: '0px 3px 6px #E0EFFD',
              flexDirection: 'column'
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                flex: 1,
                color: '#677489',
                margin: '20px 20px 10px 20px'
              }}
            >
              <Box
                sx={{
                  font: 'normal normal medium 18px/17px Montserrat',
                  letterSpacing: '0.56px'
                }}
              >
                Section B
              </Box>
              <Link
                to={`${RoutesURLs.ESG_REPORTING}/${RoutesURLs.BRSR}/${RoutesURLs.SECTIONB}`}
                style={{
                  border: '1px solid #AFAFAF',
                  borderRadius: '4px',
                  display: 'flex',
                  alignItems: 'center',
                  padding: '5px',
                  gap: 2,
                  color: '#677489',
                  font: 'normal normal 600 14px/16px Quicksand',
                  width: '80px',
                  justifyContent: 'center'
                }}
              >
                <EditIcon fontSize='small' sx={{aspectRatio: '3'}} />
                EDIT
              </Link>
            </Box>
            <Box sx={{padding: '0px 20px 0px 20px'}}>
              <LinearProgressWithLabel progress={40} />
            </Box>
            <Box>
              <FormControl
                sx={{padding: '20px'}}
                component='fieldset'
                variant='standard'
              >
                <FormGroup>
                  <FormControlLabel
                    sx={{
                      font: 'normal normal medium 14px/22px Quicksand',
                      color: '#121212',
                      lineHeight: '1'
                    }}
                    control={
                      <Checkbox
                        checked={true}
                        readOnly
                        disableTouchRipple
                        disableFocusRipple
                        disableRipple
                        color='success'
                      />
                    }
                    label='Corporate Identity Number (CIN) of the Listed Entity'
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={false}
                        readOnly
                        disableTouchRipple
                        disableFocusRipple
                        disableRipple
                        color='success'
                      />
                    }
                    label='Financial year for which reporting is being done'
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={false}
                        readOnly
                        disableTouchRipple
                        disableFocusRipple
                        disableRipple
                        color='success'
                      />
                    }
                    label='Details of business activities (accounting for 90% of the turnover).'
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={false}
                        readOnly
                        disableTouchRipple
                        disableFocusRipple
                        disableRipple
                        color='success'
                      />
                    }
                    label='Products/Services sold by the entity (accounting for 90% of the entity’s Turnover):'
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={false}
                        readOnly
                        disableTouchRipple
                        disableFocusRipple
                        disableRipple
                        color='success'
                      />
                    }
                    label='Number of locations where plants and/or operations/offices of the entity are situated:'
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={false}
                        readOnly
                        disableTouchRipple
                        disableFocusRipple
                        disableRipple
                        color='success'
                      />
                    }
                    label='Details as at the end of Financial Year'
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={false}
                        readOnly
                        disableTouchRipple
                        disableFocusRipple
                        disableRipple
                        color='success'
                      />
                    }
                    label='Participation/Inclusion/Representation of women'
                  />
                </FormGroup>
              </FormControl>
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12} md={12} sm={12}>
          <Paper
            sx={{
              display: 'flex',
              boxShadow: '0px 3px 6px #E0EFFD',
              flexDirection: 'column'
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                flex: 1,
                color: '#677489',
                margin: '20px 20px 10px 20px'
              }}
            >
              <Box
                sx={{
                  font: 'normal normal medium 18px/17px Montserrat',
                  letterSpacing: '0.56px'
                }}
              >
                Section C
              </Box>
            </Box>
            <Box sx={{padding: '0px 20px 0px 20px'}}>
              <LinearProgressWithLabel progress={40} />
            </Box>
            <Box>
              <FormControl
                sx={{padding: '20px'}}
                component='fieldset'
                variant='standard'
              >
                <FormGroup>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between'
                    }}
                  >
                    <FormControlLabel
                      sx={{
                        font: 'normal normal medium 14px/22px Quicksand',
                        color: '#121212',
                        lineHeight: '1',
                        width: '1000px'
                      }}
                      control={
                        <Checkbox
                          checked={true}
                          readOnly
                          disableTouchRipple
                          disableFocusRipple
                          disableRipple
                          color='success'
                        />
                      }
                      label='Principle 1 : Businesses Should Conduct And Govern Themselves With
                      Integrity, And In A Manner That Is Ethical, Transparent And Accountable.'
                    />
                    <Link
                      to={`${RoutesURLs.ESG_REPORTING}/${RoutesURLs.BRSR}/${RoutesURLs.SECTIONC}/${RoutesURLs.P1}`}
                      style={{
                        border: '1px solid #AFAFAF',
                        borderRadius: '4px',
                        display: 'flex',
                        alignItems: 'center',
                        padding: '5px',
                        gap: 2,
                        color: '#677489',
                        font: 'normal normal 600 14px/16px Quicksand',
                        width: '80px',
                        justifyContent: 'center'
                      }}
                    >
                      <EditIcon
                        fontSize='small'
                        sx={{aspectRatio: '3', mr: '2px'}}
                      />
                      EDIT
                    </Link>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between'
                    }}
                  >
                    <FormControlLabel
                      sx={{
                        font: 'normal normal medium 14px/22px Quicksand',
                        color: '#121212',
                        lineHeight: '1',
                        width: '1000px'
                      }}
                      control={
                        <Checkbox
                          checked={false}
                          readOnly
                          disableTouchRipple
                          disableFocusRipple
                          disableRipple
                          color='success'
                        />
                      }
                      label='Principle 2 : Businesses Should Provide Goods And Services In A Manner
                      That Is Sustainable And Safe'
                    />
                    <Link
                      to={`${RoutesURLs.ESG_REPORTING}/${RoutesURLs.BRSR}/${RoutesURLs.SECTIONC}/${RoutesURLs.P2}`}
                      style={{
                        border: '1px solid #AFAFAF',
                        borderRadius: '4px',
                        display: 'flex',
                        alignItems: 'center',
                        padding: '5px',
                        gap: 2,
                        color: '#677489',
                        font: 'normal normal 600 14px/16px Quicksand',
                        width: '80px',
                        justifyContent: 'center'
                      }}
                    >
                      <EditIcon
                        fontSize='small'
                        sx={{aspectRatio: '3', mr: '2px'}}
                      />
                      EDIT
                    </Link>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between'
                    }}
                  >
                    <FormControlLabel
                      sx={{
                        font: 'normal normal medium 14px/22px Quicksand',
                        color: '#121212',
                        lineHeight: '1',
                        width: '1000px'
                      }}
                      control={
                        <Checkbox
                          checked={false}
                          readOnly
                          disableTouchRipple
                          disableFocusRipple
                          disableRipple
                          color='success'
                        />
                      }
                      label='Principle 3 : Businesses Should Respect And Promote The Well-being Of All
                      Employees, Including Those In Their Value Chains'
                    />
                    <Link
                      to={`${RoutesURLs.ESG_REPORTING}/${RoutesURLs.BRSR}/${RoutesURLs.SECTIONC}/${RoutesURLs.P3}`}
                      style={{
                        border: '1px solid #AFAFAF',
                        borderRadius: '4px',
                        display: 'flex',
                        alignItems: 'center',
                        padding: '5px',
                        gap: 2,
                        color: '#677489',
                        font: 'normal normal 600 14px/16px Quicksand',
                        width: '80px',
                        justifyContent: 'center'
                      }}
                    >
                      <EditIcon
                        fontSize='small'
                        sx={{aspectRatio: '3', mr: '2px'}}
                      />
                      EDIT
                    </Link>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between'
                    }}
                  >
                    <FormControlLabel
                      sx={{
                        font: 'normal normal medium 14px/22px Quicksand',
                        color: '#121212',
                        lineHeight: '1',
                        width: '1000px'
                      }}
                      control={
                        <Checkbox
                          checked={false}
                          readOnly
                          disableTouchRipple
                          disableFocusRipple
                          disableRipple
                          color='success'
                        />
                      }
                      label='Principle 4 : Businesses Should Respect The Interests Of And Be Responsive
                      To All Its Stakeholders'
                    />
                    <Link
                      to={`${RoutesURLs.ESG_REPORTING}/${RoutesURLs.BRSR}/${RoutesURLs.SECTIONC}/${RoutesURLs.P4}`}
                      style={{
                        border: '1px solid #AFAFAF',
                        borderRadius: '4px',
                        display: 'flex',
                        alignItems: 'center',
                        padding: '5px',
                        gap: 2,
                        color: '#677489',
                        font: 'normal normal 600 14px/16px Quicksand',
                        width: '80px',
                        justifyContent: 'center'
                      }}
                    >
                      <EditIcon
                        fontSize='small'
                        sx={{aspectRatio: '3', mr: '2px'}}
                      />
                      EDIT
                    </Link>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between'
                    }}
                  >
                    <FormControlLabel
                      sx={{
                        font: 'normal normal medium 14px/22px Quicksand',
                        color: '#121212',
                        lineHeight: '1',
                        width: '1000px'
                      }}
                      control={
                        <Checkbox
                          checked={false}
                          readOnly
                          disableTouchRipple
                          disableFocusRipple
                          disableRipple
                          color='success'
                        />
                      }
                      label='Principle 5 : Businesses Should Respect And Promote Human Rights'
                    />
                    <Link
                      to={`${RoutesURLs.ESG_REPORTING}/${RoutesURLs.BRSR}/${RoutesURLs.SECTIONC}/${RoutesURLs.P5}`}
                      style={{
                        border: '1px solid #AFAFAF',
                        borderRadius: '4px',
                        display: 'flex',
                        alignItems: 'center',
                        padding: '5px',
                        gap: 2,
                        color: '#677489',
                        font: 'normal normal 600 14px/16px Quicksand',
                        width: '80px',
                        justifyContent: 'center'
                      }}
                    >
                      <EditIcon
                        fontSize='small'
                        sx={{aspectRatio: '3', mr: '2px'}}
                      />
                      EDIT
                    </Link>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between'
                    }}
                  >
                    <FormControlLabel
                      sx={{
                        font: 'normal normal medium 14px/22px Quicksand',
                        color: '#121212',
                        lineHeight: '1',
                        width: '1000px'
                      }}
                      control={
                        <Checkbox
                          checked={false}
                          readOnly
                          disableTouchRipple
                          disableFocusRipple
                          disableRipple
                          color='success'
                        />
                      }
                      label='Principle 6 : Businesses Should Respect And Make Efforts To Protect And
                      Restore The Environment'
                    />
                    <Link
                      to={`${RoutesURLs.ESG_REPORTING}/${RoutesURLs.BRSR}/${RoutesURLs.SECTIONC}/${RoutesURLs.P6}`}
                      style={{
                        border: '1px solid #AFAFAF',
                        borderRadius: '4px',
                        display: 'flex',
                        alignItems: 'center',
                        padding: '5px',
                        gap: 2,
                        color: '#677489',
                        font: 'normal normal 600 14px/16px Quicksand',
                        width: '80px',
                        justifyContent: 'center'
                      }}
                    >
                      <EditIcon
                        fontSize='small'
                        sx={{aspectRatio: '3', mr: '2px'}}
                      />
                      EDIT
                    </Link>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between'
                    }}
                  >
                    <FormControlLabel
                      sx={{
                        font: 'normal normal medium 14px/22px Quicksand',
                        color: '#121212',
                        lineHeight: '1',
                        width: '1000px'
                      }}
                      control={
                        <Checkbox
                          checked={false}
                          readOnly
                          disableTouchRipple
                          disableFocusRipple
                          disableRipple
                          color='success'
                        />
                      }
                      label='Principle 7 : Businesses, When Engaging In Influencing Public And Regulatory
                      Policy, Should Do So In A Manner That Is Responsible And Transparent'
                    />
                    <Link
                      to={`${RoutesURLs.ESG_REPORTING}/${RoutesURLs.BRSR}/${RoutesURLs.SECTIONC}/${RoutesURLs.P7}`}
                      style={{
                        border: '1px solid #AFAFAF',
                        borderRadius: '4px',
                        display: 'flex',
                        alignItems: 'center',
                        padding: '5px',
                        gap: 2,
                        color: '#677489',
                        font: 'normal normal 600 14px/16px Quicksand',
                        width: '80px',
                        justifyContent: 'center'
                      }}
                    >
                      <EditIcon
                        fontSize='small'
                        sx={{aspectRatio: '3', mr: '2px'}}
                      />
                      EDIT
                    </Link>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between'
                    }}
                  >
                    <FormControlLabel
                      sx={{
                        font: 'normal normal medium 14px/22px Quicksand',
                        color: '#121212',
                        lineHeight: '1',
                        width: '1000px'
                      }}
                      control={
                        <Checkbox
                          checked={false}
                          readOnly
                          disableTouchRipple
                          disableFocusRipple
                          disableRipple
                          color='success'
                        />
                      }
                      label='Principle 8 : Businesses Should Promote Inclusive Growth And Equitable
                      Development'
                    />
                    <Link
                      to={`${RoutesURLs.ESG_REPORTING}/${RoutesURLs.BRSR}/${RoutesURLs.SECTIONC}/${RoutesURLs.P8}`}
                      style={{
                        border: '1px solid #AFAFAF',
                        borderRadius: '4px',
                        display: 'flex',
                        alignItems: 'center',
                        padding: '5px',
                        gap: 2,
                        color: '#677489',
                        font: 'normal normal 600 14px/16px Quicksand',
                        width: '80px',
                        justifyContent: 'center'
                      }}
                    >
                      <EditIcon
                        fontSize='small'
                        sx={{aspectRatio: '3', mr: '2px'}}
                      />
                      EDIT
                    </Link>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between'
                    }}
                  >
                    <FormControlLabel
                      sx={{
                        font: 'normal normal medium 14px/22px Quicksand',
                        color: '#121212',
                        lineHeight: '1',
                        width: '1000px'
                      }}
                      control={
                        <Checkbox
                          checked={false}
                          readOnly
                          disableTouchRipple
                          disableFocusRipple
                          disableRipple
                          color='success'
                        />
                      }
                      label='Principle 9 : Businesses Should Engage With And Provide Value To Their
                      Consumers In A Responsible Manner'
                    />
                    <Link
                      to={`${RoutesURLs.ESG_REPORTING}/${RoutesURLs.BRSR}/${RoutesURLs.SECTIONC}/${RoutesURLs.P9}`}
                      style={{
                        border: '1px solid #AFAFAF',
                        borderRadius: '4px',
                        display: 'flex',
                        alignItems: 'center',
                        padding: '5px',
                        gap: 2,
                        color: '#677489',
                        font: 'normal normal 600 14px/16px Quicksand',
                        width: '80px',
                        justifyContent: 'center'
                      }}
                    >
                      <EditIcon
                        fontSize='small'
                        sx={{aspectRatio: '3', mr: '2px'}}
                      />
                      EDIT
                    </Link>
                  </Box>
                </FormGroup>
              </FormControl>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default BRSR;
