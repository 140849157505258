import {Box, Typography} from '@mui/material';
import {calculatedKpiScore} from 'Components/Shared/Interfaces/Kpis';
import React from 'react';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import StoreInterface from 'State/Interfaces/StoreInterface';

import Speedometer from '../../Charts/Speedometer';
import Standard from '../Constants/Standard';
import calculateKPIScore, {getTotalAverage} from '../Utils/calculateKPIScore';

import SingleSustainability from './SingleSustainibility';

/**
 * Sustainability Component shows the speedometer component and
 * score for each kpis
 * @component
 */
const Sustainability: React.FC = () => {
  const {t} = useTranslation();
  const selectedKpis = useSelector((state: StoreInterface) => state.kpiValues);
  const [individualKPIscore, setIndividualKPIscore] = useState<
    calculatedKpiScore[]
  >([]);

  useEffect(() => {
    setIndividualKPIscore(calculateKPIScore(selectedKpis));
  }, [selectedKpis]);

  return (
    <>
      <Box className='mc-score'>
        <span>
          {t('TOTAL')} {t('SUSTAINIBILITY_SCORE')}
        </span>
        <Typography variant='h4'>{`${getTotalAverage(
          individualKPIscore
        )}`}</Typography>
      </Box>

      <Speedometer value={getTotalAverage(individualKPIscore)} />

      <Box className='meter-cards'>
        {individualKPIscore.map((item, index) => {
          return (
            <SingleSustainability
              key={index}
              icon={item.icon}
              score={item.score}
              kpiName={item.kpiName}
              _id={item._id}
              priority={item.priority ? item.priority : Standard.HIGH}
            />
          );
        })}
      </Box>
    </>
  );
};

export default Sustainability;
