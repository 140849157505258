/**
 * Import AxiosResponse to add type to response data
 */
import axios, {AxiosResponse} from 'axios';
import FootPrintData from 'Components/Footprint/Interfaces/FootPrintData';

interface ResponseType {
  data: FootPrintData;
}

/**
 * Retrieves footprint data.
 * @returns {Promise<FootPrintData>} A promise that resolves to the footprint data.
 */
const FootPrintService = async (): Promise<FootPrintData> => {
  const companyId = window?.bharatCarbon?.company?._id;
  const industry = window?.bharatCarbon?.company?.category;
  const API_BASE_PATH = process.env.REACT_APP_API_PATH;
  const API_FOOT_PRINT_DATA = 
  `${API_BASE_PATH}${process.env.REACT_APP_API_FOOT_PRINT_DATA}?companyId=${companyId}&industry=${industry}`;

  try {
    /**
     * Use AxiosResponse to add type to response data
     */
    const response: AxiosResponse<ResponseType> = await axios.get(API_FOOT_PRINT_DATA, {
      headers: {
        authorization: `Bearer ${window?.bharatCarbon?.auth?.token}`
      }
    });

    return response?.data?.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    /**
     * Throw the error to be caught by the calling function
     */
    throw error;
  }
};

export default FootPrintService;
