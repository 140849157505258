/* eslint-disable no-console */

import {Box, Paper, Typography} from '@mui/material';
import TotalAndUnitTypes from 'Components/Shared/Interfaces/TotalAndUnitTypes';
import React from 'react';

import tree from '../../../Assets/Mask Group 10.png';
import ActionDataInterface from '../Interface/ActionData';

import Initiators from './Initiators';
import SelectInitiator from './SelectInitiator';

const Credits: React.FC<{
  totalAndUnit: TotalAndUnitTypes;
  actionData: ActionDataInterface;
}> = ({totalAndUnit, actionData}) => {
  return (
    <Paper
      sx={{
        height: '374px',
        margin: '15px 10px 5px 20px',
        borderRadius: '8px',
        padding: '24px',
        boxShadow: '0px 3px 6px #e0effd'
      }}
    >
      <Box sx={{display: 'flex', flex: '1', justifyContent: 'center'}}>
        <Box>
          <Box
            sx={{
              color: '#677489',
              width: '115px',
              textAlign: 'left',
              font: 'normal normal 600 12px/15px Quicksand',
              textTransform: 'uppercase',
              letterSpacing: '0px'
            }}
          >
            <Box>Total </Box>
            <Box>Carbon Emissions</Box>
          </Box>
          <Box
            sx={{
              font: 'normal normal normal 59px/74px Quicksand',
              color: '#99B1C7',
              letterSpacing: '0px'
            }}
          >
            {totalAndUnit.total || 0}
          </Box>
          <Box
            sx={{
              font: 'normal normal 600 20px/25px Quicksand',
              color: '#99B1C7',
              letterSpacing: '0px',
              margin: '0px 0px 25px 0px'
            }}
          >
            {totalAndUnit.unit.split(' ')[1] || ''}
          </Box>
          <Box sx={{display: 'flex'}}>
            <Box>
              <img src={tree} alt='tree' style={{width: '150px'}}></img>
            </Box>
            <Box
              sx={{
                color: '#F27260',
                margin: '40px 20px 20px 10px',
                width: '210px'
              }}
            >
              <Typography
                sx={{
                  font: 'normal normal 600 16px/20px Montserrat',
                  textAlign: 'left'
                }}
              >
                Your company emits the amount of carbon that
              </Typography>
              <Typography
                sx={{
                  font: 'normal normal 600 24px/20px Montserrat',
                  fontSize: '24px'
                }}
              >
                {actionData?.numberOfTrees ||
                  Math.round(totalAndUnit.total / 0.022)}{' '}
              </Typography>
              <Typography
                sx={{
                  font: 'normal normal 600 16px/20px Montserrat',
                  textAlign: 'left'
                }}
              >
                trees absorb in a year.
              </Typography>
            </Box>
          </Box>
        </Box>
        {actionData?.initiators?.length === 0 ? (
          <SelectInitiator />
        ) : (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              flex: '1',
              alignItems: 'center',
              justifyContent: 'flex-start'
            }}
          >
            {actionData?.initiators.map((initiator, index) => {
              return <Initiators key={index} initiator={initiator} />;
            })}
          </Box>
        )}
      </Box>
    </Paper>
  );
};

export default Credits;
