import {Box} from '@mui/material';
import React from 'react';
import {TooltipProps} from 'recharts';
import {NameType, ValueType} from 'recharts/types/component/DefaultTooltipContent';

const PiechartTooltip: React.FC<TooltipProps<ValueType, NameType>> = ({active, payload}) => { 
	if (active && payload && payload.length) {
		return (
			<Box className="custom-tooltip">
				<p className="label">{`${payload[0]?.payload?.payload?.name} : ${payload[0]?.value}%`}</p>
			</Box>
		);
	}

	return null;
};

export default PiechartTooltip;
