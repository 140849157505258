/* eslint-disable no-console */
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Typography
} from '@mui/material';
import {loadStripe} from '@stripe/stripe-js';
import moment from 'moment';
import React, {useEffect, useState} from 'react';
import {useSearchParams} from 'react-router-dom';
import Payment from 'Services/Payment';

import PaymentMethod from '../../Assets/pay-circles.jpg';
import PaymentLogo from '../../Assets/payment-logo.svg';
import ShortLogo from '../../Assets/short-logo.svg';

import LocationPayment from './Layouts/LocationPayment';
import {generatePaymentLink} from './Utils/generatePaymentLink';

const PayNow = (): JSX.Element => {
  const StripeKey: string = process.env.REACT_APP_STRIPE_PK_KEY_TEST || '';

  loadStripe(StripeKey);

  const [search] = useSearchParams();
  const companyId: string = search.get('companyId') || '';
  const id: string = search.get('id') || '';
  const [tncAccepted, setTncAccepted] = useState(false);

  const invoiceId: string = search.get('invoiceId') || '';
  const [data, setData] = useState<any>();

  const checkTnCAccepted = (event: any) => {
    setTncAccepted(event.target.checked);
  };

  useEffect(() => {
    const fetch = async () => {
      setData(await Payment(companyId, invoiceId));
    };
    fetch();
  }, [companyId]);

  const period =
    moment(data?.newInvoice?.company?.footprintingPeriod?.[1]).year() -
    moment(data?.newInvoice?.company?.footprintingPeriod?.[0]).year();

  const onSubmit = async (): Promise<any> => {
    const paymentData = {
      priceDetails: {
        price_data: {
          currency: 'inr',
          product_data: {
            name: `Footprinting of ${data.newInvoice.company.businessName}`
          },
          unit_amount: Math.round(data.newInvoice.totalPrice) * 100
        },
        quantity: 1
      },
      discount: {
        coupon: id
      },
      companyId,
      invoiceId
    };
    try {
      await generatePaymentLink(paymentData).then((url) => {
        window.location.href = url;
      });
    } catch (error: any) {
      return error;
    }
  };

  return data?.status !== 'success' ? (
    <Box
      display='flex'
      alignItems='center'
      justifyContent='center'
      width='100vw'
      height='100vh'
    >
      <CircularProgress disableShrink color='success' />
    </Box>
  ) : (
    <Box className='innerPage pay-page'>
      <Box className='billing-page'>
        {/* <Box className='circles circles-left'>

        </Box> */}
        {/* left section */}
        <Box className='billing-content'>
          <Box className='bc-header'>
            <img src={PaymentLogo} alt='Logo'></img>
            <Box>
              <Typography>Bharat Carbon</Typography>
              Sai Hira , Mundhwa,
              <br /> Pune, Maharashtra 411036
            </Box>
            <Box className='est-txt'>Estimate</Box>
          </Box>
          <Box className='formData-view d-flex'>
            <Box className='fd-col'>
              <Box className='form-data'>
                <Typography className='label-value'>#</Typography>
                <Typography className='label-title'>{`: ${data.newInvoice.invoiceNumber}`}</Typography>
              </Box>
              <Box className='form-data'>
                <Typography className='label-value'>Estimate Date</Typography>
                <Typography className='label-title'>{`: ${moment().format(
                  'Do MMM YYYY'
                )}`}</Typography>
              </Box>
              <Box className='form-data'>
                <Typography className='label-value'>Expiry Date</Typography>
                <Typography className='label-title'>{`: ${moment()
                  .add(15, 'days')
                  .format('Do MMM YYYY')}`}</Typography>
              </Box>
            </Box>
            <Box className='fd-col'>
              <Box className='form-data'>
                <Typography className='label-value'>Place of Supply</Typography>
                <Typography className='label-title'>: Maharashtra</Typography>
              </Box>
            </Box>
          </Box>
          <Box className='pay-subtitle'>
            <h4>Bill To</h4>
          </Box>
          <Box className='formData-view bill-info'>
            <Box className='form-data'>
              <Typography className='label-title'>
                {data.newInvoice.company.businessName}
              </Typography>
              <Typography className='label-value'>
                {`${data?.newInvoice?.locations[0]?.locationId.addressLine1},
                ${data?.newInvoice?.locations[0]?.locationId.addressLine2},
                 ${data?.newInvoice?.locations[0]?.locationId.city}, 
                 ${data?.newInvoice?.locations[0]?.locationId.state},
                  ${data?.newInvoice?.locations[0]?.locationId.zipCode}`}
              </Typography>
            </Box>
            <Box className='form-data'>
              <Typography className='label-title'>{`${data?.newInvoice?.locations[0]?.locationId.city}`}</Typography>
              <Typography className='label-value'>
                {` ${data?.newInvoice?.locations[0]?.locationId.zipCode}
                ${data?.newInvoice?.locations[0]?.locationId.state}`}
              </Typography>
            </Box>
            <Box className='form-data'>
              <Typography className='label-title'>{`${data?.newInvoice?.locations[0]?.locationId.country}`}</Typography>
              <Typography className='label-value'>
                {`GSTIN ${data?.newInvoice?.company.taxIdentityNumber}`}
              </Typography>
            </Box>
          </Box>
          <Box className='formData-view' sx={{display: 'none !important'}}>
            <Box className='form-view'>
              <Box className='form-data'>
                <Typography className='label-title'>
                  Business name:{' '}
                  <span>{`${data?.newInvoice.company.businessName?.replace(
                    /\b\w/g,
                    (match: string) => match.toUpperCase()
                  )}`}</span>
                </Typography>
              </Box>
              <Box className='form-data'>
                <Typography className='label-title'>
                  Category:
                  <span>{`${data?.newInvoice.company.category?.replace(
                    /\b\w/g,
                    (match: string) => match.toUpperCase()
                  )}`}</span>
                </Typography>
              </Box>
              <Box className='form-data'>
                <Typography className='label-title'>
                  Nature of business:
                  <span>{`${data?.newInvoice.company.natureOfBusiness?.replace(
                    /\b\w/g,
                    (match: string) => match.toUpperCase()
                  )}`}</span>
                </Typography>
              </Box>
              <Box className='form-data'>
                <Typography className='label-title'>
                  Type of business:
                  <span>{`${data?.newInvoice.company.typeOfBusiness?.replace(
                    /\b\w/g,
                    (match: string) => match.toUpperCase()
                  )}`}</span>
                </Typography>
              </Box>
              <Box className='form-data'>
                <Typography className='label-title'>
                  Footprinting period:
                  <span>{`Footprinting Period: ${period} ${
                    period === 1 ? 'Yr' : 'Yrs'
                  }`}</span>
                </Typography>
              </Box>
              <Box className='form-data'>
                <Typography className='label-title'>
                  Total area of all outlets:
                  <span>{`${Math.round(
                    data?.newInvoice.buildUpInFt
                  )} Sq ft`}</span>
                </Typography>
              </Box>
              <Box className='form-data'>
                <Typography className='label-title'>
                  Outlet Count:
                  <span>{`${data?.newInvoice.locations.length}`}</span>
                </Typography>
              </Box>
            </Box>
          </Box>
          <LocationPayment data={data?.newInvoice.locations} />
          <Box className='pay-calculation'></Box>
          <Box className='payGroup'></Box>
          <Box className='pay-bottom'>
            <Box className='note-data'>
              <Box className='address'>
                <Box className='ad-title'>Bharat Carbon</Box>
                <Box className='ad-desc'>
                  Sai Hira , Mundhwa,
                  <br />
                  Pune, Maharashtra 411036
                  <br />
                  GST No. 27AALCB3156G1ZN
                </Box>
              </Box>
              <Box className='address'>
                <Box className='ad-title'>Bank Account</Box>
                <Box className='ad-desc'>
                  Account No. 1646847141, Kotak Bank, Ramwadi ,Pune
                  <br />
                  IFSC : KKBK0000730
                </Box>
              </Box>
              <img src={PaymentMethod} alt='Payment Method'></img>
              <Box className='hightlight-sec'>
                <img src={ShortLogo} alt='Short Logo'></img>
                <p>
                  Bharat Carbon will contribute 1% of your purchase to remove
                  CO₂ from the atmosphere.
                </p>
              </Box>
              <Box className='address'>
                <Box className='ad-title'>Note:</Box>
                <Box className='ad-desc'>
                  The base price covers upto 1000 sq ft per location.
                  <br /> Each additional sq ft will be charged at INR 5.
                </Box>
              </Box>
            </Box>
            <Box className='right-section'>
              <Box className='payTotal'>
                <span>Grand Total : </span>
                {`₹ ${(data?.newInvoice.price / period).toLocaleString(
                  'en-IN'
                )}`}
              </Box>
              <Box className='payment-section'>
                <Box className='amount-calc'>
                  <Box>
                    {'Period:'}
                    <span>
                      {`${period} ${
                        period === 1 ? 'Year' : 'Years'
                      } | ₹ ${Math.round(data?.newInvoice.price).toLocaleString(
                        'en-IN'
                      )}`}{' '}
                    </span>
                  </Box>
                  <Box className='discount'>
                    {'Discount:'}
                    <span>
                      {`${
                        data.newInvoice.discountPercentage || 0
                      } % | ₹ ${Math.round(
                        data?.newInvoice.discountedAmount
                      ).toLocaleString('en-IN')}`}{' '}
                    </span>
                  </Box>
                  <Box>
                    {'CGST:'}
                    <span>
                      {`9 % | ₹ ${(
                        Math.round(data?.newInvoice.gstAmount) / 2
                      ).toLocaleString('en-IN')}`}{' '}
                    </span>
                  </Box>
                  <Box>
                    {'SGST:'}
                    <span>
                      {`9 % | ₹ ${(
                        Math.round(data?.newInvoice.gstAmount) / 2
                      ).toLocaleString('en-IN')}`}{' '}
                    </span>
                  </Box>

                  <Box className='total-amt'>
                    {'Total Price:'}
                    <span>{`₹ ${Math.round(
                      data?.newInvoice.totalPrice
                    ).toLocaleString('en-IN')} /-`}</span>
                  </Box>
                </Box>
                <Box>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={tncAccepted}
                        onChange={checkTnCAccepted}
                      />
                    }
                    label={
                      <Typography>
                        I agree to{' '}
                        <a
                          href='https://google.com'
                          target='_blank'
                          rel='noreferrer'
                        >
                          terms and conditions
                        </a>
                      </Typography>
                    }
                  />
                </Box>
                <Box>
                  <Button
                    className='btn-primary'
                    sx={{
                      '&.Mui-disabled': {
                        opacity: 0.2
                      }
                    }}
                    onClick={() => {
                      onSubmit();
                    }}
                    disabled={!tncAccepted}
                  >
                    Pay now
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default PayNow;
