import {Box} from '@mui/material';
import {options} from 'Components/Shared/Constants/FormBuilder';
import FormBuilder from 'Components/Shared/FormBuilder';
import React from 'react';

const Principle8: React.FC = () => {
  const companyId = window?.bharatCarbon?.company?._id;
  const handleSubmit = (data: any) => {
    data.companyId = companyId;
    // eslint-disable-next-line no-console
    console.log(data);
  };

  const {Sr_no} = options;

  return (
    <Box sx={{padding: '30px 24px 24px 24px'}}>
      <FormBuilder
        fields={[
          {
            type: 'div',
            label: 'ESSENTIAL INDICATORS',
            key: ''
          },
          {
            type: 'textarea',
            label: `Details of Social Impact Assessments (SIA) of projects undertaken by the Company based on
            applicable laws, in the current financial year.`,
            key: 'SIA'
          },
          {
            type: 'textarea',
            label: `Information on project(s) for which ongoing Rehabilitation and Resettlement (R&R) is being
            undertaken by your Company.`,
            key: 'informationOfProjects'
          },
          {
            type: 'textarea',
            label: `Describe the mechanisms to receive and 
            redress grievances of the community.`,
            key: 'grievances'
          },
          {
            type: 'textarea',
            label: `Percentage of input material (inputs to total 
                inputs by value) sourced from suppliers.`,
            key: 'materialSourced'
          },
          {
            type: 'div',
            label: 'LEADERSHIP INDICATORS',
            key: ''
          },
          {
            type: 'textarea',
            label: `Provide details of actions taken to mitigate any negative social impacts identified in the Social
              Impact Assessments (Reference: Question 1 of Essential Indicators above).`,
            key: 'essentialIndicators'
          },
          {
            type: 'table',
            label: `CSR projects undertaken by your Company in designated aspirational districts as identified by
              government:`,
            key: 'CSRProjects',
            options: [
              {
                type: 'text',
                label: Sr_no
              },
              {
                type: 'text',
                label: 'State',
                key: 'state'
              },
              {
                type: 'text',
                label: 'Aspirational District',
                key: 'aspirationalDistrict'
              },
              {
                type: 'input',
                label: 'Amount Spent (In \u20B9)',
                key: 'aspirationalDistrict',
                inputType: 'number'
              }
            ]
          },
          {
            type: 'textarea',
            label: `Do you have a preferential procurement policy where you give preference to purchase from
            suppliers comprising marginalised /vulnerable groups?`,
            key: 'procurementPolicy'
          },
          {
            type: 'textarea',
            label: `From which marginalised /vulnerable groups
             do you procure?`,
            key: 'marginalisedGroups'
          },
          {
            type: 'input',
            label: `What percentage of total procurement
             (by value) does it constitute?`,
            key: 'percentageProcurement',
            inputType: 'number'
          },
          {
            type: 'textarea',
            label: `Details of the benefits derived and shared from 
            the intellectual properties owned or acquired by your
            Company (in the current financial year), based on traditional knowledge.`,
            key: 'intellectualProperties'
          },
          {
            type: 'textarea',
            label: `Details of corrective actions taken or underway, based on any adverse order in intellectual property
            related disputes wherein usage of traditional knowledge is involved.`,
            key: 'correctiveActions'
          },
          {
            type: 'table',
            label: 'Details of beneficiaries of CSR Projects:',
            key: 'CSRbeneficiaries',
            options: [
              {
                type: 'text',
                label: Sr_no
              },
              {
                type: 'text',
                label: 'CSR Project',
                key: 'CSRProject'
              },
              {
                type: 'input',
                label: `No of Persons
                benefitted from
                CSR Project
                (approximate)`,
                key: 'personsBenefitted',
                inputType: 'number'
              },
              {
                type: 'input',
                label: `% of beneficiaries
                from vulnerable and
                marginalised groups`,
                key: 'percentBenefitted',
                inputType: 'number'
              }
            ]
          }
        ]}
        title='Principle 8 : Businesses Should Promote Inclusive Growth And Equitable
Development'
        onSubmit={handleSubmit}
      />
    </Box>
  );
};

export default Principle8;
