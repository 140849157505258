import FootPrintService from 'Services/FootPrintData';

import FootPrintData from '../Interfaces/FootPrintData';

/**
 * Retrieves the footprint data asynchronously.
 *
 * @returns A Promise that resolves to the footprint data or undefined if an error occurs.
 */
const getFootPrintData = async (): Promise<FootPrintData | undefined> => {
  try {
    const res = await FootPrintService();
    
return res;
  } catch (error) {
    console.error('Error fetching benchmark data:', error);
    
return undefined;
  }
};

export default getFootPrintData;
