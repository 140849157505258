import {Tooltip} from '@mui/material';
import React from 'react';

const ScatterIcon: React.FC = (props: any) => {
    return (
        <Tooltip title={props.payload.initiativeName}>
            <svg x={props.cx - 10} y={props.cy - 10} width="100" height="100">
                <image href={props.icon} />
            </svg>
        </Tooltip>
    );
};

export default ScatterIcon;
