import {Box, Grid, Typography} from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
// import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import axios from 'axios';
import LinearLoader from 'Components/Shared/LinearLoader';
import * as React from 'react';

import payimg1 from '../../Assets/payimg-1.png';
import payimg2 from '../../Assets/payimg-2.png';

import PaymentLayout from './Layouts/PaymentLayout';

/**
 * To display the subscription info of user.
 * @component
 */
const SubscriptionInfo: React.FC = () => {
  const [paymentData, setPaymentData] = React.useState<any>('');
  const API_BASE_PATH = process.env.REACT_APP_API_PATH;
  const companyId = window?.bharatCarbon?.company._id;
  React.useEffect(() => {
    const fetchApi = async () => {
      await axios
        .get(`${API_BASE_PATH}/payment/info?companyId=${companyId}`, {
          headers: {
            authorization: `Bearer ${window?.bharatCarbon?.auth?.token}`
          }
        })
        .then((res) => {
          setPaymentData(res.data.data);
        });
    };
    fetchApi().catch((err) => {
      return err;
    });
  }, []);

  return (
    <Box className='dash-container'>
      <Grid className='payImg' container spacing={{xs: 2, md: 2}}>
        <Grid item xs={6} sm={6}>
          <img alt='' src={payimg2} className='full-width'></img>
        </Grid>
        <Grid item xs={6} sm={6}>
          <img alt='' src={payimg1} className='full-width'></img>
        </Grid>
      </Grid>
      <Box className='dash-cards only-heading'>
        <Box className='card-title'>
          <Typography>Previous Packages</Typography>
          {/* <Tooltip message='Tooltip' /> */}
        </Box>
      </Box>
      <Box className='payTable paymentTbl'>
        <TableContainer sx={{maxHeight: 150}}>
          <Table stickyHeader aria-label='sticky table'>
            <TableHead>
              <TableRow>
                <TableCell>#</TableCell>
                <TableCell>Package Id</TableCell>
                <TableCell>Period</TableCell>
                <TableCell>Locations</TableCell>
                <TableCell>Payment status</TableCell>
                <TableCell>Amount Paid</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            {!paymentData ? (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={7}>
                    <LinearLoader />
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <TableBody>
                {paymentData.length > 0 ? (
                  paymentData?.map((data: any, index: number) => {
                    return (
                      <PaymentLayout key={index} index={index} data={data} />
                    );
                  })
                ) : (
                  <TableRow hover>
                    <TableCell colSpan={7}>No Records Found</TableCell>
                  </TableRow>
                )}
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};

export default SubscriptionInfo;
