import React from 'react';

import PieChartProps from '../../Charts/Interfaces/PieChartProps';

const PieDiagramCustomizedLegend: React.FC<{ data: PieChartProps[] }> = ({data}) => {
    return (
        <>
            <ul className="recharts-default-legend" style={{padding: '0px', margin: '0px', textAlign: 'left'}}>
                {data?.map((e, index: number) => {
                    return e.name ? (
                        <li style={{display: 'flex', marginRight: '10px', alignItems: 'center'}} key={index}>
                            <span
                                style={{
                                    width: '7px',
                                    height: '7px',
                                    backgroundColor: `${e.fill}`,
                                    borderRadius: '50%',
                                    marginRight: '5px'
                                }}
                            ></span>
                            <span className="recharts-legend-item-text" style={{color: 'rgb(200, 162, 252)'}}>
                                {e.name} ({e.value}
                                {e.unit})
                            </span>
                        </li>
                    ) : (
                        <div key={index} ></div>
                    );
                })}
            </ul>
        </>
    );
};

export default PieDiagramCustomizedLegend;
