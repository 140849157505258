import {Box} from '@mui/material';
import FormBuilder from 'Components/Shared/FormBuilder';
import React from 'react';

const Principle5: React.FC = () => {
  const companyId = window?.bharatCarbon?.company?._id;
  const handleSubmit = (data: any) => {
    data.companyId = companyId;
    // eslint-disable-next-line no-console
    console.log(data);
  };

  return (
    <Box sx={{padding: '30px 24px 24px 24px'}}>
      <FormBuilder
        fields={[
          {type: 'div', label: 'ESSENTIAL INDICATORS', key: ''},
          {
            type: 'table',
            label: `Employees and workers who have been provided training on human rights issues and policy(ies) of
            the Company, in the following format`,
            key: 'trainingEmployees',
            static: [
              'Permanent',
              `Other than
            permanent`,
              'Total Employees'
            ],
            options: [
              {
                type: 'box',
                label: 'Category',
                key: ''
              },
              {
                type: 'input',
                label: 'Total (A) for current FY',
                key: 'totalCurrent',
                inputType: 'number'
              },
              {
                type: 'input',
                label: `No.
                of employees
                /
                workers
                covered (B) for current FY`,
                key: 'NoOfWorkerCurrent'
              },
              {
                type: 'input',
                label: '% (B/ A) for current FY',
                key: 'percentCurrent'
              },
              {
                type: 'input',
                label: 'Total (C) for last FY',
                key: 'totalLast',
                inputType: 'number'
              },
              {
                type: 'input',
                label: `No.
                of employees
                /
                workers
                covered (D) for Last FY`,
                key: 'NoOfWorkerLast'
              },
              {
                type: 'input',
                label: '% (D/ A) for Last FY',
                key: 'percentLast'
              }
            ]
          },
          {
            type: 'table',
            label: 'Details of minimum wages paid to employees:',
            key: 'minimumWages',
            static: [
              'Permanent Male',
              'Permanent Female',
              'Contract Male',
              'Contract Female'
            ],
            options: [
              {
                type: 'box',
                label: 'Category',
                key: ''
              },
              {
                type: 'input',
                label: 'Total (A) for current FY',
                key: 'totalCurrent',
                inputType: 'number'
              },
              {
                type: 'input',
                label: `Equal to
                Minimum Wage (B) for current FY`,
                key: 'minWagesEqualCurrent'
              },
              {
                type: 'input',
                label: '% (B/ A) for current FY',
                key: 'minWagesEqualPercentCurrent'
              },
              {
                type: 'input',
                label: `More to
                Minimum Wage (C) for current FY`,
                key: 'minWagesMoreCurrent'
              },
              {
                type: 'input',
                label: '% (C/ A) for current FY',
                key: 'minWagesMorePercentCurrent'
              },
              {
                type: 'input',
                label: 'Total (A) for last FY',
                key: 'totalLast',
                inputType: 'number'
              },
              {
                type: 'input',
                label: `Equal to
                Minimum Wage (B) for last FY`,
                key: 'minWagesEqualLast'
              },
              {
                type: 'input',
                label: '% (B/ A) for last FY',
                key: 'minWagesEqualPercentLast'
              },
              {
                type: 'input',
                label: `More to
                Minimum Wage (C) for last FY`,
                key: 'minWagesMoreLast'
              },
              {
                type: 'input',
                label: '% (C/ A) for last FY',
                key: 'minWagesMorePercentLast'
              }
            ]
          },
          {
            type: 'table',
            label: 'Details of remuneration/salary:',
            key: 'remunerationDetails',
            static: [
              'Board of Directors (BoD)',
              `Key Managerial Personnel other than
            Whole Time Directors`,
              `Employees other than BoD and KMP
            - Mid to senior management`,
              `Employees other than BoD and KMP
            – Other employees`,
              'Workers'
            ],
            options: [
              {
                type: 'Box',
                label: 'Category',
                key: ''
              },
              {
                type: 'input',
                label: 'Number of male',
                key: 'noOfMale'
              },
              {
                type: 'input',
                label: `Male Median remuneration/
                salary/wages of
                respective category
                ( \u20B9 million)`,
                key: 'salaryOfMale'
              },
              {
                type: 'input',
                label: 'Number of Female',
                key: 'noOfFemale'
              },
              {
                type: 'input',
                label: `Female Median remuneration/
                salary/wages of
                respective category
                ( \u20B9 million)`,
                key: 'salaryOfFemale'
              }
            ]
          },
          {
            type: 'textarea',
            label: `Do you have a focal point (Individual/ Committee) responsible for addressing human rights impacts
            or issues caused or contributed to by the business? (Yes/No).`,
            key: 'focalPoint'
          },
          {
            type: 'textarea',
            label: `Internal mechanisms in place to
             redress grievances related to human rights issues.`,
            key: 'internalMechanism'
          },
          {
            type: 'table',
            label: `Number of Complaints on the following
             made by employees and workers:`,
            key: 'noOfCompliants',
            static: [
              'Sexual Harassment',
              'Discrimination at workplace',
              'Child Labor',
              `Forced Labor/Involuntary
              Labor`,
              'Wages',
              `Other human rights related
              issues`
            ],
            options: [
              {
                type: 'box',
                label: 'Category',
                key: ''
              },
              {
                type: 'input',
                label: `Filed during
                the Current FY`,
                key: 'filedCurrent',
                inputType: 'number'
              },
              {
                type: 'input',
                label: `Pending
                resolution
                at the end
                of Current FY`,
                key: 'pendingCurrent',
                inputType: 'number'
              },
              {
                type: 'textarea',
                label: 'Remarks',
                key: 'remarks'
              },
              {
                type: 'input',
                label: `Filed during
                the Last FY`,
                key: 'filedLast',
                inputType: 'number'
              },
              {
                type: 'input',
                label: `Pending
                resolution
                at the end
                of Last FY`,
                key: 'pendingLast',
                inputType: 'number'
              },
              {
                type: 'textarea',
                label: 'Remarks',
                key: 'remarks'
              }
            ]
          },
          {
            type: 'textarea',
            label: `Mechanisms to prevent adverse consequences to the complainant in discrimination and harassment
            cases.`,
            key: 'adverseConsequences'
          },
          {
            type: 'textarea',
            label: `Do human rights requirements form part of 
            your business agreements and contracts? (Yes/No).`,
            key: 'agreementsAndContracts'
          },
          {
            type: 'table',
            label: 'Assessments for the year:',
            key: 'assessmentsForTheYear:',
            static: [
              'Child labor',
              'Forced/involuntary labor',
              'Sexual harassment',
              'Discrimination at workplace',
              'Wages'
            ],
            options: [
              {
                type: 'box',
                label: 'Category',
                key: ''
              },
              {
                type: 'input',
                label: `% of your plants and offices that were assessed (by Company or
                    statutory authorities or third parties)`,
                key: 'assessedPercent'
              }
            ]
          },
          {
            type: 'textarea',
            label: `Provide details of any corrective actions taken or underway to address significant risks / concerns
            arising from the assessments above.`,
            key: 'detailsOfAssessments'
          },
          {
            type: 'div',
            label: 'LEADERSHIP INDICATORS',
            key: ''
          },
          {
            type: 'textarea',
            label: `Details of a business process being modified / introduced as a result of addressing human rights
            grievances/complaints.`,
            key: 'detailsBusiness'
          },
          {
            type: 'textarea',
            label: `Details of the scope and coverage 
            of any Human rights due diligence conducted.`,
            key: 'diligenceConducted'
          },
          {
            type: 'textarea',
            label: `Is the premise/office of the Company accessible 
            to differently abled visitors, as per the requirements
            of the Rights of Persons with Disabilities Act, 2016?`,
            key: 'accessibleDisabilities'
          },
          {
            type: 'table',
            label: 'Details on assessment of value chain partners',
            key: 'detailsValueChain',
            static: [
              'Sexual Harassment',
              'Discrimination at workplace',
              'Child Labor',
              `Forced Labor/Involuntary
                Labor`,
              'Wages',
              `Other human rights related
              issues`
            ],
            options: [
              {type: 'box', label: 'Category', key: ''},
              {
                type: 'textarea',
                label: `% of value chain partners (by value of business done with such partners) that
                    were assessed`,
                key: 'percent'
              }
            ]
          },
          {
            type: 'textarea',
            label: `Provide details of any corrective actions taken or underway to address significant risks / concerns
          arising from the assessments at Question above.`,
            key: 'detailsCorrectiveActions'
          }
        ]}
        title='Principle 5 : Businesses Should Respect And Promote Human Rights'
        onSubmit={handleSubmit}
      />
    </Box>
  );
};

export default Principle5;
