import getWidgetsData from 'Services/WidgetData';

import WidgetData from '../Interfaces/WidgetData';

/**
 * Retrieves the Widget data by calling WidgetData service asynchronously.
 *
 * @returns A Promise that resolves to the widget data or undefined if an error occurs.
 */
const getWidgetData = async (): Promise<WidgetData[]> => {
    try {
        const res = await getWidgetsData();
        if (res) {
            return res;
        }
    } catch (error) {
        console.error('Error fetching benchmark data:', error);
    }

    return [];
};

export default getWidgetData;
