import FlagIcon from '@mui/icons-material/Flag';
import {Box} from '@mui/material';
import {calculatedKpiScore} from 'Components/Shared/Interfaces/Kpis';
import React from 'react';


import returnFlagColour from '../Utils/returnFlagColour';

/**
 * SingleSustainibility Component is the box component to show the score for single kpi  
 * @component
 */
const SingleSustainability: React.FC<calculatedKpiScore> = ({icon, score, kpiName, priority}) => {

	return (
		<>
			<Box className="mc-list">
				<Box className="data-n-image">
					<Box>
						<img src={icon} alt="" />
					</Box>
					<Box>{score}</Box>
				</Box>
				<Box className="mc-data">{kpiName}</Box>
				<Box className="flag" 
				sx={{background: returnFlagColour(priority).background, color: returnFlagColour(priority).color}} >
					<FlagIcon fontSize="inherit" /> {priority}</Box>
			</Box>
		</>
	);
};

export default SingleSustainability;
