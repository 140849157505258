import getWidgetConfigService from 'Services/WidgetConfiguration';

import WidgetConfig from '../Interfaces/WidgetConfig';

/**
 * Retrieves the Widget Configuration data by calling getWidgetConfigService asynchronously.
 *
 * @returns A Promise that resolves to the Widget Configuration data or undefined if an error occurs.
 */
const getWidgetConfig = async (): Promise<WidgetConfig | undefined> => {
	try {
		const res = await getWidgetConfigService();
		if (res) {
			return res;
		}
		
	} catch (error) {
		console.error('Error fetching benchmark data:', error);
	}
};

export default getWidgetConfig;