import {
  Box,
  Button,
  Checkbox,
  Grid,
  Slider,
  TextField,
  Typography
} from '@mui/material';
import React from 'react';

const SelectInitiator: React.FC = () => {
  const sampleInitiator = [
    'Plastic Recycling',
    'Waste water treatment',
    'EVs',
    'Glass Recycling',
    'Water from air',
    'Natural product alternatives',
    'Renewable Energy',
    'Waste heat recovery'
  ];

  const valuetext = (value: number) => {
    return `${value.toLocaleString('en-IN')} \u20B9`;
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flex: '1',
        backgroundColor: '#F8FAFC !important',
        width: '100%',
        height: '100%',
        padding: '8px 8px 8px 8px'
      }}
    >
      <Box sx={{display: 'flex', flexDirection: 'column'}}>
        <Typography
          sx={{
            font: 'normal normal medium 16px/17px Quicksand',
            color: '#99B1C7',
            letterSpacing: '0.5px'
          }}
        >
          Initiators
        </Typography>
        <Typography
          sx={{
            font: 'normal normal 600 14px/22px Quicksand',
            letterSpacing: '0px',
            color: '#677489'
          }}
        >
          Choose your targeted initiator
        </Typography>
      </Box>
      <Box>
        <Grid container sx={{justifyContent: 'center', alignItems: 'center'}}>
          {sampleInitiator.map((initiator) => {
            return (
              <Grid item xs={6} zeroMinWidth>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                >
                  <Checkbox
                    sx={{
                      margin: '10px',
                      color: '#99B1C7'
                    }}
                  />
                  <Typography
                    sx={{
                      font: 'normal normal medium 14px/22px Quicksand',
                      color: '#677489',
                      letterSpacing: '0px'
                    }}
                  >
                    {initiator}
                  </Typography>
                </Box>
              </Grid>
            );
          })}
        </Grid>
      </Box>
      <Box>
        <Typography>Set your budget to net zero journey</Typography>
        <Box sx={{display: 'flex'}}>
          <Slider
            // size='small'
            min={0}
            max={10000000}
            marks={[
              {value: 0, label: '0'},
              {value: 10000000, label: '1 Crore'}
            ]}
            step={1000}
            valueLabelFormat={valuetext}
            valueLabelDisplay='auto'
            sx={{color: '#20E2BD'}}
          />
          <TextField
            variant='outlined'
            sx={{
              color: '#E4E4E4'
            }}
          />
          <Button
            sx={{
              backgroundColor: '#20E2BD',
              color: '#024064',
              '&:hover': {backgroundColor: '#3acfb2', color: 'white'}
            }}
          >
            Submit
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default SelectInitiator;
