enum RoutesURLs {
  DASHBOARD = '/dashboard',
  SCHEDULE_CALL = '/schedule-call',
  HOME = '*',
  FOOTPRINT = '/footprint',
  YOUR_PROGRESS = '/your-progress',
  ACTION = '/action',
  SUBSCRIPTION_INFO = '/payInfo',
  HELP = '/help',
  SETTINGS = '/settings',
  PAYMENT = '/payment',
  PAYMENT_SUCCESSFULL = '/paymentSuccessful',
  PAYMENT_FAILED = '/paymentUnSuccessful',
  NONE = '#',
  ESG_REPORTING = '/reporting',
  BRSR = 'brsr',
  SECTIONA = 'sectionA',
  SECTIONB = 'sectionB',
  SECTIONC = 'sectionC',
  P1 = 'p1',
  P2 = 'p2',
  P3 = 'p3',
  P4 = 'p4',
  P5 = 'p5',
  P6 = 'p6',
  P7 = 'p7',
  P8 = 'p8',
  P9 = 'p9'
}

export default RoutesURLs;
