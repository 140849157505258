import React from 'react';
import {
  PieChart,
  Pie,
  Cell,
  Legend,
  Tooltip,
  ResponsiveContainer
} from 'recharts';

//import {PieChartStyle} from './Constants/Piechart';
import PieChartProps from './Interfaces/PieChartProps';
import PieDiagramCustomizedLegend from './Layouts/PieDiagramCustomizedLegend';
import RenderCustomizedLabel from './Layouts/RenderCustomizedLabel';

const PieDiagram: React.FC<{data: PieChartProps[]}> = ({data}) => {
  return (
    <ResponsiveContainer width='100%' aspect={1} className='pieChart'>
      <PieChart width={500} height={500}>
        <Pie
          data={data}
          cx='125'
          cy='125'
          labelLine={false}
          label={RenderCustomizedLabel}
          outerRadius={95}
          fill='#833563'
          dataKey='value'
          innerRadius={0}
          paddingAngle={1}
        >
          {data?.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={entry?.fill} />
          ))}
        </Pie>
        <Legend
          iconSize={7}
          layout='vertical'
          verticalAlign='middle'
          iconType='circle'
          content={<PieDiagramCustomizedLegend data={data} />}
        />
        <Legend iconSize={10} layout='vertical' verticalAlign='middle' />
        <Tooltip separator=':' cursor={false} />
      </PieChart>
    </ResponsiveContainer>
  );
};

export default PieDiagram;
