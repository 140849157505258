/* eslint-disable no-console */
import {Box, Button} from '@mui/material';
import axios from 'axios';
import Loader from 'Components/Shared/Loader';
import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';

import FailIcon from '../../../Assets/fail-icon.svg';

const PaymentSuccessful: () => JSX.Element = () => {
  const [data, setData] = useState<any>();
  const [timeLeft, setTimeLeft] = useState<number | null>(null);
  const urlParams = new URLSearchParams(window.location.search);
  const sessionId = urlParams.get('session_id');
  const companyId = urlParams.get('companyId');
  const invoiceId = urlParams.get('invoiceId');
  const API_BASE_PATH = process.env.REACT_APP_API_PATH;

  const navigate = useNavigate();

  useEffect(() => {
    axios
      .get(
        `${API_BASE_PATH}/payment/status?session_id=${sessionId}&companyId=${companyId}&invoiceId=${invoiceId}`,
        {
          headers: {
            authorization: `Bearer ${window?.bharatCarbon?.auth?.token}`
          }
        }
      )
      .then((res) => {
        setData(res.data);
        setTimeLeft(5);
      })
      .catch((err) => {
        return err;
      });
  }, [sessionId]);

  useEffect(() => {
    if (!timeLeft) return;

    if (timeLeft === 0) {
      // navigate('/');
    }

    const reDirect = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 6000);

    return () => clearInterval(reDirect);
  }, []);

  const login = () => {
    navigate('/dashboard', {state: {refresh: true}});
  };

  return !data?.status ? (
    <Loader />
  ) : (
    // <img
    //   src={require('../../../Assets/congrats.gif')}
    //   alt='congrats'
    //   style={{width: '100vw', height: '90vh'}}
    // />
    <Box className='pay-page flex-center'>
      <Box className='payment-status'>
        <img src={FailIcon} alt='Short Logo'></img>
        <Box className='ps-status-msg'>Payment Failed</Box>
        <Box className='ps-status-desc'>
          The payment was unsuccessful. Please try again later or use another
          payment method.
        </Box>

        <Button className='btn btn-secondry' onClick={login}>
          Try Again
        </Button>
      </Box>
      {/* <Box>{timeLeft}</Box> */}
    </Box>
  );
};

export default PaymentSuccessful;
