import {Box, Tab, Tabs} from '@mui/material';
import TooltipMessage from 'Components/Shared/Constants/TooltipMessages';
import TabPanel from 'Components/Shared/TabPanel';
import Tooltip from 'Components/Shared/Tooltip';
import React from 'react';

import ProgressData from '../Interfaces/ProgressData';

import TabSection from './TabSection';

/**
 * ProgressTabs Component shows the all the kpis and initiatives for selected kpi
 * Initiatives for selected kpi is displayed through TabSection component
 * @component
 */
const ProgressTabs: React.FC<ProgressData> = ({kpis}) => {
	const [value, setValue] = React.useState(0);
	const handleChange = (event: React.SyntheticEvent, newValue: number) => setValue(newValue);

	return (
		<>
			<Box className="dash-cards">
				<Box className="card-title" sx={{borderBottom: 1, borderColor: 'divider'}}>
					<Tabs value={value} onChange={handleChange} aria-label="basic tabs example" centered>
						{kpis.map((item, index) => {
							return (
								<Tab className="pt-tabs"
									key={index}
									icon={<img src={item.icon} alt="" />}
									iconPosition="start"
									label={item.kpiName}
									value={index}
								/>
							);
						})}
					</Tabs>
					<Tooltip message={TooltipMessage?.PROGRESS_KPI} />
				</Box>
				{kpis.map((item, index) => {
					return (
						<TabPanel key={index} value={value} index={index}>
							<TabSection
								dataId={item?.dataId}
								initiatives={item?.initiatives}
								kpiName={item.kpiName}
								displayIndex={item.displayIndex}
								icon={item.icon}
								type={item.type}
								minRange={item.minRange}
								maxRange={item.maxRange}
								_id={item._id}
							/>
						</TabPanel>
					);
				})}
			</Box>
		</>
	);
};

export default ProgressTabs;
