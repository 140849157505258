import {Grid, Paper} from '@mui/material';
import React, {useState} from 'react';
import {MapContainer, TileLayer} from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import {useSelector} from 'react-redux';
import StoreInterface from 'State/Interfaces/StoreInterface';

import MarkerProps from '../Interface/MarkersProps';
import UpdatePosition from '../Interface/UpdatePosition';

import ContinentSelector from './ContinentSelector';
import MapLabel from './MapLabel';
import Markers from './Markers';
import ZoomEventComponent from './ZoomEventComponent';

const UNFProjects: React.FC = () => {
  const storeProject = useSelector((state: StoreInterface) => state.projects);

  const [position, setPosition] = React.useState({lat: 42, lng: 0});
  const [zoomLevel, setZoomLevel] = React.useState(1.52);
  const [markers, setMarkers] = React.useState<MarkerProps[]>([]);
  const [labelContinent, setLabelContinent] = useState('');

  const markerRefs: any[] = [];

  const updatePosition: UpdatePosition = ({lat, lng}, zoom) => {
    setPosition({lat: lat, lng: lng});
    setZoomLevel(zoom);
  };

  React.useEffect(() => {
    setMarkers(storeProject);
  }, [storeProject]);

  return (
    <Paper
      sx={{
        height: '100%',
        borderRadius: '8px',
        boxShadow: '0px 3px 6px #e0effd'
      }}
    >
      <MapLabel labelContinent={labelContinent} markers={markers} />
      <Grid container>
        <Grid item xs={12} sm={8} padding={4}>
          <MapContainer
            style={{
              height: '500px',
              width: '100%',
              background: '#abd3df',
              zIndex: 20
            }}
            zoomSnap={0.01}
            scrollWheelZoom={false}
            attributionControl={false}
            zoomControl={false}
          >
            <TileLayer
              attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
              noWrap={true}
            />
            <Markers data={markers} markerRefs={markerRefs} />
            <ZoomEventComponent targetLatLng={position} zoomLevel={zoomLevel} />
          </MapContainer>
        </Grid>
        <Grid item xs={12} sm={4} padding={3}>
          <ContinentSelector
            markerRefs={markerRefs}
            setLabelContinent={setLabelContinent}
            updatePosition={updatePosition}
            markers={markers}
            setMarkers={setMarkers}
          />
        </Grid>
      </Grid>
    </Paper>
  );
};

export default UNFProjects;
