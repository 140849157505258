import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import CompanyState from 'State/Constants/CompanyState';
import CompanyInfo from 'State/Interfaces/CompanyInfo';

/**
 * Redux slice for the company store.
 */
const createCompanyStore = createSlice({
  name: 'company',
  initialState: CompanyState,
  reducers: {
    /**
     * Sets the company information in the state.
     * @param {CompanyState} state - The current state.
     * @param {PayloadAction<CompanyInfo>} action - The action containing the company information payload.
     */
    setCompanyInfo: (state, action: PayloadAction<CompanyInfo>) => {
      state._id = action.payload?._id;
      state.businessName = action.payload?.businessName;
      state.category = action.payload?.category;
    },
    /**
     * Ingests the global configuration in the window object.
     * @param {CompanyState} state - The current state.
     * @param {PayloadAction<CompanyInfo>} action - The action containing the company information payload.
     */
    ingestGlobalConfigInWindow: (state, action: PayloadAction<CompanyInfo>) => {
      window.bharatCarbon.company = {
        _id: action.payload?._id,
        businessName: action.payload?.businessName,
        category: action.payload?.category
      };
    }
  }
});

export default createCompanyStore;
