import axios, {AxiosResponse} from 'axios';
import {PostInitiativeScore} from 'Components/Shared/Interfaces/Initiatives';

/**
 * Posts an initiative score.
 * @param {PostInitiativeScore} PostData - The data of the initiative score to be posted.
 * @returns {Promise<PostInitiativeScore[]>} A promise that resolves to an array of posted initiative scores.
 */
const postInitiativeScore = async (PostData: PostInitiativeScore): Promise<PostInitiativeScore[]> => {
  const API_BASE_PATH = process.env.REACT_APP_API_PATH;
  const API_SAVE_INITIATIVE_SCORE = `${API_BASE_PATH}${process.env.REACT_APP_API_SAVE_INITIATIVE_SCORE}`;

  try {
    /** Use AxiosResponse to add type to response data */
    const response: AxiosResponse<PostInitiativeScore[]> = await axios.post(API_SAVE_INITIATIVE_SCORE, PostData, {
      headers: {
        authorization: `Bearer ${window?.bharatCarbon?.auth?.token}`
      }
    });

    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    /** Throw the error to be caught by the calling function */
    throw error;
  }
};

export default postInitiativeScore;
