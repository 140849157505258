import {Button, TableCell, TableRow} from '@mui/material';
import moment from 'moment';
import React from 'react';

const PaymentLayout: React.FC<{data: any; index: number}> = ({data, index}) => {
  return (
    <TableRow hover>
      <TableCell>{index + 1}</TableCell>
      <TableCell>{data.invoiceId.invoiceNumber}</TableCell>
      <TableCell>{`${moment(data.invoiceId.footprintingPeriod[0]).format(
        'YYYY'
      )} - ${moment(data.invoiceId.footprintingPeriod[1]).format(
        'YYYY'
      )}`}</TableCell>
      <TableCell>{data.invoiceId.locations.length}</TableCell>
      <TableCell>{data.paymentStatus === 'paid' ? 'PAID' : 'UNPAID'}</TableCell>
      <TableCell>
        {`₹ ${data.invoiceId.totalPrice.toFixed(1).toLocaleString('en-IN')} /-`}
      </TableCell>
      <TableCell>
        <Button className='btn btn-primary'>download Report</Button>
        <Button className='btn btn-primary'>download certificate</Button>
        <Button className='btn btn-primary'>View invoice</Button>
      </TableCell>
    </TableRow>
  );
};

export default PaymentLayout;
