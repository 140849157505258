import UserInfo from '../Interfaces/UserInfo';

const UserState: UserInfo = {
	email: '',
	firstName: '',
	lastName: '',
	phone: '',
	address: '',
	isVerified: false,
	isGuest: true,
	profilePhoto: '',
	company: {
		_id: '',
		businessName: '',
		category: '',
		footprintingPeriod: [],
		status: ''
	},
	userType: '',
	_id: ''
};

export default UserState;