import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import MarkerProps from 'Components/Action/Interface/MarkersProps';
import ProjectState from 'State/Constants/ProjectState';

/**
 * Redux slice for the progress page store.
 */
const createProjectStore = createSlice({
  name: 'projects',
  initialState: ProjectState,
  reducers: {
    /**
     * Updates the values in the state for a projects.
     * @param {ProgressState} state - The current state.
     * @param {PayloadAction<MarkerProps>} action - The action containing the projects payload.
     */
    setProjects: (state, action: PayloadAction<MarkerProps[]>) => {
        action?.payload?.forEach(i => {
            state.push(i);
        });
    }
  }
});

// Export the store
export default createProjectStore;
