import GetAppIcon from '@mui/icons-material/GetApp';
import {Box, Button, Grid, Paper, Typography} from '@mui/material';
import RoutesURLs from 'Components/Shared/Constants/Routes';
import React from 'react';
import {Link} from 'react-router-dom';

import PaymentLogo from '../../../Assets/payment-logo.svg';

const Reporting: React.FC = () => {
  return (
    <>
      <Box className='foot-container'>
        <Box
          className='dash-cards'
          sx={{
            font: 'normal normal medium 18px/17px Montserrat',
            background: '#FFFFFF 0% 0% no-repeat padding-box',
            boxShadow: '0px 3px 6px #E0EFFD',
            color: '#677489',
            margin: '8px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            padding: '18px',
            height: '71px'
          }}
        >
          Reporting Frameworks
        </Box>
      </Box>
      <Paper
        sx={{
          borderRadius: '8px',
          margin: '0px 0px 0px 32px',
          width: '763px',
          padding: '20px 24px',
          boxShadow: '0px 3px 6px #E0EFFD'
        }}
      >
        <Grid container>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12} sm={12}>
              <Box className='dash-cards'>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    mb: '20px'
                    // padding: '12px 20px'
                  }}
                >
                  <img
                    src={PaymentLogo}
                    alt=''
                    style={{width: '180px', height: '50px'}}
                  />
                  <Typography
                    sx={{
                      font: 'normal normal medium 18px/22px Montserrat',
                      color: '#677489',
                      mr: '20px'
                    }}
                  >
                    Business Responsibility and Sustainability Report
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    sx={{
                      font: 'normal normal normal 14px/20px Montserrat',
                      letterSpacing: 0,
                      color: '#848484',
                      mr: '32px',
                      ml: '24px'
                    }}
                  >
                    India's Business Responsibility and Sustainability Report
                    (BRSR)—a framework for environmental, social and governance
                    (ESG) reporting—comes into effect in 2023. These new
                    reporting standards represent an evolution from the
                    voluntary guidelines first issued in 2009 by India's
                    Ministry of Corporate Affairs, which were further refined in
                    the Business Responsibility Report (BRR) of 2012.
                  </Typography>
                </Box>
                <Box sx={{mt: '20px'}}>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between'
                    }}
                  >
                    <Link
                      style={{
                        width: '160px',
                        background: '#20E2BD 0% 0% no-repeat padding-box',
                        borderRadius: '4px',
                        margin: '10px 24px 10px 24px',
                        color: 'white',
                        display: 'grid',
                        placeItems: 'center',
                        height: '56px',
                        font: 'normal normal medium 14px/16px Quicksand',
                        letterSpacing: 1.25
                      }}
                      to={`${RoutesURLs.ESG_REPORTING}/${RoutesURLs.BRSR}`}
                    >
                      VIEW
                    </Link>
                    <Button
                      sx={{
                        border: '1px solid #AFAFAF',
                        borderRadius: '4px',
                        color: '#AFAFAF',
                        m: '10px 24px 10px 121px',
                        height: '56px'
                      }}
                    >
                      <GetAppIcon />
                      Download Report
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

export default Reporting;
