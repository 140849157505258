import {Box} from '@mui/material';
import TooltipMessage from 'Components/Shared/Constants/TooltipMessages';
import React from 'react';
import {useSelector} from 'react-redux';
import StoreInterface from 'State/Interfaces/StoreInterface';

import Widget from '../Interfaces/Widget';

import ReturnChartComponent from './ReturnChartComponent';
import WidgetTitle from './WidgetTitle';

/**
 * List Widget Component
 * @component
 */
const ListsWidget: React.FC<Widget> = (widget) => {
  const years = useSelector((state: StoreInterface) => state.years);
  const [yearValue, setYearValue] = React.useState<string>(
    years?.[years.length - 1]
  );

  return (
    <Box className='dash-cards list-widget' id='fp-second'>
      <WidgetTitle
        name={widget?.name}
        yearValue={yearValue}
        setYearValue={setYearValue}
        TooltipMessage={TooltipMessage.FOOTPRINT_LOCATION_PERFORMANCE}
      />
      <Box className='card-data simple-cards'>
        {widget?.tabs?.length ? (
          <Box>
            <ReturnChartComponent
              year={yearValue}
              item={widget.tabs}
              widget={widget}
            />
          </Box>
        ) : (
          <></>
        )}
      </Box>
    </Box>
  );
};

export default ListsWidget;
