/* eslint-disable no-console */
import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import ActionDataInterface from 'Components/Action/Interface/ActionData';
import ActionData from 'State/Constants/ActionState';

/**
 * Redux slice for the progress page store.
 */
const createActionStore = createSlice({
  name: 'actionData',
  initialState: ActionData,
  reducers: {
    /**
     * Updates the values in the state for a continent.
     * @param {ProgressState} state - The current state.
     * @param {PayloadAction<Kpis>} action - The action containing the continent payload.
     */
    setActionData: (state, action: PayloadAction<ActionDataInterface[]>) => {
      action?.payload?.forEach((i) => {
        state.push(i);
      });
    }
  }
});

// Export the store
export default createActionStore;
