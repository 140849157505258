/* eslint-disable no-console */
import {Box, Tab, Tabs} from '@mui/material';
import TooltipMessage from 'Components/Shared/Constants/TooltipMessages';
import TabPanel from 'Components/Shared/TabPanel';
import React from 'react';
import {useSelector} from 'react-redux';
import StoreInterface from 'State/Interfaces/StoreInterface';

import Widget from '../Interfaces/Widget';

import ReturnChartComponent from './ReturnChartComponent';
import WidgetTitle from './WidgetTitle';

/**
 * Tabs Widget Component
 * @component
 */
const TabsWidget: React.FC<Widget> = (widget) => {
  const years = useSelector((state: StoreInterface) => state.years);
  const [yearValue, setYearValue] = React.useState<string>(
    years?.[years.length - 1]
  );
  const [value, setValue] = React.useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box className='dash-cards' id={widget.name.replace(/\s/g, '')}>
      <WidgetTitle
        name={widget?.name}
        yearValue={yearValue}
        setYearValue={setYearValue}
        TooltipMessage={
          widget?.name === 'Total GHG Emissions'
            ? TooltipMessage.DASHBOARD_GHG_EMISSIONS
            : TooltipMessage.DASHBOARD_GHG_BY_SCOPE
        }
      />
      {(widget?.tabs?.length as number) > 1 ? (
        <Box className='card-data'>
          <Box display='' sx={{borderBottom: 1, borderColor: 'divider'}}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label='basic tabs example'
              variant='scrollable'
              scrollButtons='auto'
            >
              {widget?.tabs?.map((item, index) => {
                return <Tab key={index} label={item?.label} value={index} />;
              })}
            </Tabs>
          </Box>

          {widget?.tabs?.map((item, index) => {
            return (
              <TabPanel key={index} value={value} index={index}>
                <ReturnChartComponent
                  year={yearValue}
                  item={item}
                  widget={widget}
                />
              </TabPanel>
            );
          })}
        </Box>
      ) : (
        widget?.tabs?.map((item, index) => {
          return (
            <TabPanel key={index} value={value} index={index}>
              <ReturnChartComponent
                year={yearValue}
                item={item}
                widget={widget}
              />
            </TabPanel>
          );
        })
      )}
    </Box>
  );
};

export default TabsWidget;
