import {Box} from '@mui/material';
import React from 'react';

const FootprintInProgress: React.FC = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        height: '250px',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      Please Be patient , your data collection is on
    </Box>
  );
};

export default FootprintInProgress;
