/* eslint-disable @typescript-eslint/no-explicit-any */
import {FormControl, MenuItem, Select as SelectComponent, SelectChangeEvent} from '@mui/material';
import React from 'react';

import SelectProps from './Interfaces/SelectProp';

/**
 * Custom Select Component
 * @component
 */
const Select: React.FC<SelectProps> = ({selectedValue, setSelectedValue, options, disabled}) => {
	const handleSelectChange = (event: SelectChangeEvent) => {
		setSelectedValue(event?.target?.value);
	};
	
	return (
		<FormControl sx={{m: 1, minWidth: 120}}>
			<SelectComponent
				defaultValue={options?.[0]}
				value={selectedValue}
				onChange={handleSelectChange}
				disabled={disabled}
				displayEmpty
				inputProps={{'aria-label': 'Without label'}}
			>
				{options?.map((optionValue: any, index: number) => {
					return (
						<MenuItem key={index} value={optionValue?.value ? optionValue?.value : optionValue}>
							{optionValue?.title ? optionValue.title : optionValue}	
						</MenuItem>
					);
				})}
			</SelectComponent>
		</FormControl>
	);
};

export default Select;
