/* eslint-disable no-console */
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField
} from '@mui/material';
import {DesktopDatePicker, LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import TableArray from 'Components/ESG/Framework/Layouts/TableArray';
import moment from 'moment';
import React, {useEffect} from 'react';
import {Controller, useForm} from 'react-hook-form';

import {Field} from './Interfaces/FormBuilder';

interface Props {
  fields: Field[];
  onSubmit: (data: any) => void;
  title: string;
  setValues?: {key: string; func: React.Dispatch<React.SetStateAction<any>>};
}

const FormBuilder: React.FC<Props> = ({fields, onSubmit, setValues, title}) => {
  const {register, control, handleSubmit, watch} = useForm();
  useEffect(() => {
    setValues?.func(
      watch(`${setValues?.key}`)
        ? moment(watch(`${setValues?.key}`)).get('year')
        : moment().get('year')
    );
  }, [watch(`${setValues?.key}`)]);

  console.log('watch', watch());

  const renderField = (field: Field, index: number) => {
    switch (field.type) {
      case 'text':
        return (
          <Grid item xs={12} key={index}>
            <Controller
              name={field.key as string}
              control={control}
              defaultValue=''
              render={({field: {onChange, value}}) => (
                <TextField
                  fullWidth
                  label={field?.label}
                  variant='outlined'
                  value={value}
                  {...register(`${field.key}`)}
                  onChange={onChange}
                />
              )}
            />
          </Grid>
        );
      case 'textarea':
        return (
          <Grid item xs={12} key={index}>
            <Controller
              name={field.key as string}
              control={control}
              defaultValue=''
              render={({field: {onChange, value}}) => (
                <TextField
                  fullWidth
                  multiline
                  label={field?.label}
                  variant='outlined'
                  value={value}
                  {...register(`${field.key}`)}
                  onChange={onChange}
                />
              )}
            />
          </Grid>
        );
      case 'input':
        return (
          <Grid item xs={12} key={index}>
            <Controller
              name={field.key as string}
              control={control}
              defaultValue=''
              render={({field: {onChange, value}}) => (
                <TextField
                  fullWidth
                  type={field.inputType}
                  label={field?.label}
                  variant='outlined'
                  value={value}
                  {...register(`${field.key}`)}
                  onChange={onChange}
                />
              )}
            />
          </Grid>
        );
      case 'select':
        return (
          <Grid item xs={12} key={index}>
            <InputLabel id='label'>{field.label}</InputLabel>
            <Controller
              name={field.key as string}
              control={control}
              defaultValue={''}
              render={({field: {onChange, value}}) => (
                <Select
                  labelId='label'
                  label={field.label}
                  {...register(`${field.key}`)}
                  value={value || ''}
                  onChange={onChange}
                  variant='outlined'
                >
                  {field.options.map((el: string, i: number) => {
                    return (
                      <MenuItem key={i} value={el}>
                        {el}
                      </MenuItem>
                    );
                  })}
                </Select>
              )}
            />
          </Grid>
        );
      case 'multiSelect':
        return (
          <Grid item xs={12} key={index}>
            <InputLabel id='label'>{field.label}</InputLabel>
            <Controller
              name={field.key as string}
              control={control}
              defaultValue={''}
              render={({field: {onChange, value}}) => (
                <Select
                  labelId='label'
                  multiple
                  label={field.label}
                  {...register(`${field.key}`)}
                  value={value || ''}
                  onChange={onChange}
                  variant='outlined'
                >
                  {field.options.map((el: string, i: number) => {
                    return (
                      <MenuItem key={i} value={el}>
                        {el}
                      </MenuItem>
                    );
                  })}
                </Select>
              )}
            />
          </Grid>
        );
      case 'date':
        return (
          <Grid item xs={12} key={index}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Controller
                name={field.key as string}
                control={control}
                render={({field: {onChange, value}}) => (
                  <DesktopDatePicker
                    label={field.label}
                    {...field.options}
                    sx={{width: '100%'}}
                    value={value || null}
                    {...register(`${field.key}`)}
                    onChange={onChange}
                    renderInput={(params: any) => (
                      <TextField
                        {...params}
                        InputLabelProps={{
                          shrink: value ? true : false
                        }}
                        inputProps={{
                          ...params.inputProps,
                          readOnly: true
                        }}
                      />
                    )}
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>
        );
      case 'div':
        return (
          <Box key={index} sx={{ml: '20px', mt: '20px'}}>
            {`${field?.label}`}
          </Box>
        );
      case 'checkBox':
        const options = field;

        return (
          <Grid item xs={12} key={index}>
            <Controller
              name={field.key as string}
              control={control}
              render={({field}) => (
                <FormControlLabel
                  {...field}
                  control={<Checkbox />}
                  label={options.label}
                  labelPlacement='start'
                />
              )}
            />
          </Grid>
        );
      default:
        return <></>;
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Paper sx={{padding: '20px'}}>
        <Box sx={{mb: '20px'}}>{title}</Box>
        <Grid container spacing={3}>
          {fields.map((field, index) => {
            switch (field.type) {
              case 'text':
              case 'textarea':
              case 'input':
              case 'select':
              case 'multiSelect':
              case 'date':
              case 'div':
                return renderField(field, index);
              case 'table':
                return (
                  <Grid item xs={12} key={index} sx={{mb: '10px'}}>
                    <TableArray
                      config={field}
                      control={control}
                      name={field.key}
                    />
                  </Grid>
                );

              default:
                return null;
            }
          })}
          <Grid item xs={12}>
            <Button
              variant='contained'
              color='primary'
              type='submit'
              size='large'
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </form>
  );
};

export default FormBuilder;
