import {Box} from '@mui/material';
import React from 'react';

import {SPEEDOMETER_CONSTANTS} from '../Constants/Speedometer';

const SpeedometerLegend: React.FC = () => {
	return (
		<Box display="flex">
			{SPEEDOMETER_CONSTANTS.data.map((entry, index) => (
				<Box key={index} className="legendBox">
					<span style={{background: entry.color}} className="legendCircle"></span>
					<span>{entry.name}</span>
				</Box>
			))}
		</Box>
	);
};

export default SpeedometerLegend;
