import axios, {AxiosResponse} from 'axios';
import WidgetData from 'Components/Widgets/Interfaces/WidgetData';

interface ResponseType {
  data: WidgetData[];
}

/**
 * Retrieves Widget data.
 * @returns {Promise<WidgetData[]>} A promise that resolves to an array of Widget data.
 */
const getWidgetsData = async (): Promise<WidgetData[]> => {
  const companyId = window?.bharatCarbon?.company?._id;
  const API_BASE_PATH = process.env.REACT_APP_API_PATH;
  const API_GET_WIDGET_DATA = `${API_BASE_PATH}${process.env.REACT_APP_API_GET_WIDGET_DATA}?companyId=${companyId}`;
  try {
    const response: AxiosResponse<ResponseType> = await axios.get(API_GET_WIDGET_DATA, {
      headers: {
        authorization: `Bearer ${window?.bharatCarbon?.auth?.token}`
      }
    });

    return response?.data?.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export default getWidgetsData;
