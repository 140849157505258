/* eslint-disable no-console */
import {Box, Paper, Typography} from '@mui/material';
import React from 'react';

import ActionChartProps from '../Interface/ActionChartProps';

const TinyChartTooltip: React.FC<{payload: any}> = ({payload}) => {
  const mainPayload: ActionChartProps = payload?.[0]?.payload;

  return (
    <Paper sx={{display: 'flex', flexDirection: 'column', padding: '20px'}}>
      {mainPayload?.percentReduced ? (
        <>
          <Box
            sx={{
              color: '#26AF5F',
              textAlign: 'center',
              display: 'flex'
            }}
          >
            <Typography sx={{font: 'normal normal 600 12px/15px Quicksand'}}>
              {'Reduced '}
              <span style={{font: 'normal normal bold 14px/15px Quicksand'}}>
                {`${mainPayload?.emissionReduced || 0} `}
              </span>
              {`${mainPayload?.unit || 'kg'}`}
            </Typography>
          </Box>
          <Box sx={{display: 'flex', color: '#F27260', textAlign: 'center'}}>
            <Typography sx={{font: 'normal normal 600 12px/15px Quicksand'}}>
              {'You have '}{' '}
              <span style={{font: 'normal normal bold 14px/15px Quicksand'}}>
                {`${mainPayload?.percentReduced || 0} `}
              </span>
              <span>{'% '}</span>
              {'to Reduce '}
            </Typography>
          </Box>
        </>
      ) : mainPayload?.emissionState !== 0 ? (
        <Box
          sx={{
            color: '#26AF5F',
            textAlign: 'center',
            display: 'flex'
          }}
        >
          <Typography sx={{font: 'normal normal 600 12px/15px Quicksand'}}>
            {'Your Total Carbon emission is '}
            <span style={{font: 'normal normal bold 14px/15px Quicksand'}}>
              {`${mainPayload?.emissionState || 0} `}
            </span>
            {`${mainPayload?.unit || 'kg'}`}
          </Typography>
        </Box>
      ) : (
        <Box
          sx={{
            color: '#26AF5F',
            textAlign: 'center',
            display: 'flex'
          }}
        >
          <Typography sx={{font: 'normal normal 600 12px/15px Quicksand'}}>
            {'Our Target '}
            <span style={{font: 'normal normal bold 14px/15px Quicksand'}}>
              {`${mainPayload?.emissionState || 0} `}
            </span>
            {`${mainPayload?.unit || 'kg'}`}
          </Typography>
        </Box>
      )}
    </Paper>
  );
};

export default TinyChartTooltip;
