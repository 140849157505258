import ChartType from '../Constants/ChartType';
import WidgetConfig, {WidgetConfigItem} from '../Interfaces/WidgetConfig';

/**
 * Retrieves the chart configuration based on the provided item and widget configuration.
 *
 * @param item - The item configuration.
 * @param widgetConfig - The widget configuration.
 * @returns The chart configuration.
 */
const getChartConfiguration: (item: any, widgetConfig: any) => WidgetConfigItem[] = (item, widgetConfig) => {
  let config: WidgetConfigItem[] = [];

  if (!Array.isArray(item)) {
    if (item.dataId === 'default') {
      widgetConfig[`${item.dataType}`]?.forEach((node: any) => {
        config.push(getSingleNode(node, item, widgetConfig));
      });
    } else {
      const node = widgetConfig[`${item.dataType}`]?.find((o: { id: string }) => o.id === item.dataId);

      switch (item?.chartType) {
        case ChartType.COMPOSED_BAR_CHART:
          config.push(getSingleNode(node, item, widgetConfig));
          break;
        default:
          config = getCategoryNodes(node, item, widgetConfig);
          break;
      }
    }
  } else {
    item?.forEach((l: any) => {
      if (l.dataId === 'default') {
        widgetConfig[`${l.dataType}`]?.forEach((node: any) => {
          config.push(getSingleNode(node, l, widgetConfig));
        });
      } else {
        const node = widgetConfig[`${l.dataType}`]?.find((o: { id: string }) => o.id === l.dataId);
        config.push(node);
      }
    });
  }

  return config;
};

/**
 * Retrieves a single node from the widget configuration.
 *
 * @param node - The node from the widget configuration.
 * @param item - The item configuration.
 * @param widgetConfig - The widget configuration.
 * @returns The single node with category data.
 */
const getSingleNode: (node: any, item: any, widgetConfig: WidgetConfig) 
  => WidgetConfigItem = (node, item, widgetConfig) => {
  const categories: any[] = [];

  if (node?.category?.length > 0) {
    node?.category.forEach((e: string) => {
      if (item.dataType === 'kpiList') {
        categories.push(widgetConfig.categories.find((o: { id: string }) => o.id === e));
      } else if (item.dataType === 'scopeList') {
        categories.push(widgetConfig.consumptionList.find((o: { id: string }) => o.id === e));
      }
    });
  }

  const dataNode = Object.assign({categoryData: categories}, node);

  return dataNode;
};

/**
 * Retrieves the category nodes from the widget configuration.
 *
 * @param node - The node from the widget configuration.
 * @param item - The item configuration.
 * @param widgetConfig - The widget configuration.
 * @returns The category nodes.
 */
const getCategoryNodes: 
    (node: any, item: any, widgetConfig: WidgetConfig) => WidgetConfigItem[] = (node, item, widgetConfig) => {
  const categories: any[] = [];

  if (node?.category?.length > 0) {
    node?.category.forEach((e: string) => {
      if (item.dataType === 'kpiList') {
        categories.push(widgetConfig.categories.find((o: { id: string }) => o.id === e));
      } else if (item.dataType === 'scopeList') {
        categories.push(widgetConfig.consumptionList.find((o: { id: string }) => o.id === e));
      }
    });
  }

  return categories;
};

export default getChartConfiguration;
