import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import Country from 'Components/Action/Interface/Country';


/**
 * Redux slice for the progress page store.
 */
const createCountryStore = createSlice({
  name: 'country',
  initialState: [] as Country[],
  reducers: {
    /**
     * Updates the values in the state for a country.
     * @param {ProgressState} state - The current state.
     * @param {PayloadAction<Kpis>} action - The action containing the countries payload.
     */
    setCountries: (state, action: PayloadAction<Country[]>) => {
        action?.payload?.forEach(i => {
            state.push(i);
        });
    }
  }
});

// Export the store
export default createCountryStore;
