// Import AxiosResponse to add type to response data
import axios, {AxiosResponse} from 'axios';
import {Kpi} from 'Components/Shared/Interfaces/Kpis';

interface ResponseType {
	data: Kpi[];
}

/**
 * Retrieves Kpi data.
 * @returns {Promise<Kpi[]>} A promise that resolves to an array of Kpi data.
 */

/** Add Promise type and define return type as BenchMark[] */ 
const getKPIs = async (): Promise<Kpi[]> => {
	const INDUSTRY = window?.bharatCarbon?.company?.category;
	const API_BASE_PATH = process.env.REACT_APP_API_PATH;
	const API_GET_KPIS = `${API_BASE_PATH}${process.env.REACT_APP_API_GET_KPI_DATA}/${INDUSTRY}`;

	try {
		/** Use AxiosResponse to add type to response data */ 
		const response: AxiosResponse<ResponseType> = await axios.get(API_GET_KPIS, {
			headers: {
				authorization: `Bearer ${window?.bharatCarbon?.auth?.token}`
			}
		});

		return response?.data?.data;
	} catch (error) {
		console.error('Error fetching data:', error);
		/** Throw the error to be caught by the calling function */ 
		throw error;
	}
};

export default getKPIs;
