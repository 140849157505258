import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import FootPrintData from 'Components/Footprint/Interfaces/FootPrintData';
import FootPrintState from 'State/Constants/FootPrintState';

/**
 * Redux slice for footprint data.
 */
const createFootprintStore = createSlice({
  name: 'footprintData',
  initialState: FootPrintState,
  reducers: {
    /**
     * Sets the footprint data in the state.
     * @param {FootPrintState} state - The current state.
     * @param {PayloadAction<FootPrintData>} action - The action containing the footprint data payload.
     */
    setFootPrintData: (state, action: PayloadAction<FootPrintData>) => {
      state.companyId = action.payload.companyId;
      state.emissionsData = action.payload.emissionsData;
      state.locations = action.payload.locations;
    }
  }
});

export default createFootprintStore;
