import axios from 'axios';
import APP_STATUSES from 'Components/Shared/Constants/AppStatus';

/**
 * Schedules a customer call.
 * @returns {Promise<number>} A promise that resolves to the HTTP status code of the response.
 */
const scheduleCall = async (): Promise<any> => {
  const companyId = window?.bharatCarbon?.company?._id;
  const API_BASE_PATH = process.env.REACT_APP_API_PATH;
  const API_SCHEDULE_CUSTOMER_CALL = `${API_BASE_PATH}${process.env.REACT_APP_API_SCHEDULE_CUSTOMER_CALL}`;

  const result = await axios
    .put(
      API_SCHEDULE_CUSTOMER_CALL,
      {
        appStatus: APP_STATUSES.CALL_SCHEDULED,
        companyId: companyId
      },
      {
        headers: {
          authorization: `Bearer ${window?.bharatCarbon?.auth?.token}`
        }
      }
    )
    .then((response: any) => {
      return response.data.code;
    })
    .catch((err) => {
      return err;
    });

  return result;
};

export default scheduleCall;
