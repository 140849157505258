import axios, {AxiosResponse} from 'axios';
import Widget from 'Components/Widgets/Interfaces/Widget';
interface ResponseType {
	data: Widget[];
}

/**
 * Retrieves Widget information.
 * @returns {Promise<Widget[]>} A promise that resolves to an array of Widget information.
 */

/** Add Promise type and define return type as BenchMark[] */ 
const getWidgetsService = async (): Promise<Widget[]> => {
	const API_BASE_PATH = process.env.REACT_APP_API_PATH;
	const API_GET_WIDGET = `${API_BASE_PATH}${process.env.REACT_APP_API_GET_WIDGET}`;
	try {
		// Use AxiosResponse to add type to response data
		const response: AxiosResponse<ResponseType> = await axios.get(API_GET_WIDGET, {
			headers: {
				authorization: `Bearer ${window?.bharatCarbon?.auth?.token}`
			}
		});

		return response?.data?.data;
	} catch (error) {
		console.error('Error fetching data:', error);
		/** Throw the error to be caught by the calling function */ 
		throw error;
	}
};

export default getWidgetsService;
