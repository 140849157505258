import {IdToken} from '@auth0/auth0-react';

/**
 * Stores the user token in the `window.bharatCarbon.auth` object.
 *
 * @param getIdToken - A function that returns a Promise resolving to the IdToken or undefined.
 */
export const storeUserToken: (getIdToken: () => Promise<IdToken | undefined>) => void = async (getIdToken) => {
  try {
    const accessToken = await getIdToken();
    const token = accessToken?.__raw;

    if (token) {
      window.bharatCarbon.auth.token = token;
    }
  } catch (error: unknown) {
    // Empty catch block, ignoring the error
  }
};
