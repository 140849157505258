import ElectricBoltIcon from '@mui/icons-material/ElectricBolt';
import {
    ToggleButton,
    ToggleButtonGroup,
    Box,
    SelectChangeEvent,
    Button,
    Typography
} from '@mui/material';
import TooltipMessage from 'Components/Shared/Constants/TooltipMessages';
import Tooltip from 'Components/Shared/Tooltip';
import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';

import Emmision from '../Constants/Emmisions';
import Periods from '../Constants/Period';
import calculateValue from '../Utils/calculateValue';

import CalculateForm from './CalculateForm';

/**
 * Footprint Calculator Component handles and displays all the calculations for guest users.
 * @component
 */
const FootPrintCalculator: React.FC = () => {
    const {t} = useTranslation();
    const [period, setPeriod] = useState<string | null>('monthly');
    const [value, setValue] = useState<number>(0);
    const [calculatedValue, setCalculatedValue] = useState<number>(0);
    const [emmitor, setEmmitor] = useState<number>(Emmision.ELECTRICITY);

    /**
     * Handles the change of period through user - Monthly / Yearly
     */
    const handlePeriod = (event: React.MouseEvent<HTMLElement>, newperiod: string | null) => {
        if (newperiod !== null) {
            setCalculatedValue(0);
            setValue(0);
            setPeriod(newperiod);
        }
    };

    /**
     * Handles the change of emmitor for user
     */
    const handleChange = (event: SelectChangeEvent) => {
        setEmmitor(event.target.value as unknown as number);
    };

    /**
     * Get result from calculateValue function and sets the result 
     * to calculateValue state when user clicks on calculate button
     */
    const getResult = () => {
        setCalculatedValue(calculateValue({value, period, emmitor}));
    };

    return (
        <>
            <Box className="tile">
                <Box display="flex" justifyContent="space-between">
                    <Typography variant="h2">
                        {t('CALCULATE')} {t('YOUR_FOORPRINT')}{' '}
                    </Typography>
                    <Tooltip message={TooltipMessage.CALCULATE_FOOTPRINT} />
                </Box>
                <Box className="toggle-year">
                    <ToggleButtonGroup value={period} exclusive onChange={handlePeriod}>
                        <ToggleButton value={Periods.MONTHLY} aria-label="centered">
                            {t('MONTHLY')}
                        </ToggleButton>
                        <ToggleButton value={Periods.YEARLY} aria-label="centered">
                            {t('YEARLY')}
                        </ToggleButton>
                    </ToggleButtonGroup>
                </Box>

                <CalculateForm emmitor={emmitor} handleChange={handleChange} value={value} setValue={setValue} />
                <Box className="flexEnd">
                    <p className="smallFont">{t('BENCHMARK')}</p>
                </Box>
                <Box className="centre tile-btnSection">
                    <Box className='tf-colum-1'>
                    <Button  className='green-btn full-width'
                        onClick={getResult}
                    >
                        {t('CALCULATE')}
                    </Button>
                    </Box>
                    <Box className='tf-colum-2'>
                        <Box className='btn-kg full-width'>
                            <Box>{calculatedValue > 1000 ? 'Tco2' : 'KgCo2'}</Box>
                            <Box sx={{display: 'flex', justifyContent: 'space-between', alignContent: 'center'}}>
                                <Box sx={{marginLeft: '-30px', marginRight: '10px'}}>
                                    <ElectricBoltIcon sx={{color: '#fff'}} />
                                </Box>
                                <Box>
                                    {calculatedValue > 1000 ? (calculatedValue / 1000).toFixed(2)
                                        : calculatedValue.toFixed(2)}
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    
                </Box>
            </Box>
        </>
    );
};

export default FootPrintCalculator;
