enum ChartType {
    RADIAL_BAR_CHART = 'RadialBarChart',
    PIE_CHART_WITH_CUSTOM_LABEL = 'PieChartWithCustomLabel',
    PIE_CHART_WITH_PADDING_ANGLE = 'PieChartWithPaddingAngle',
    BAR_CHART = 'SimpleBarChart',
    SCATTER_QUADRANT_CHART = 'ScatterQuadrantChart',
    COMPOSED_BAR_CHART = 'ComposedBarChart',
    SIMPLE_CARD= 'SimpleCard',
}

export default ChartType;
