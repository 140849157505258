const UpgradeToPremium = {
  title: 'What is included?',
  subTitle: `Unlock the full potential of sustainable transformation with our comprehensive carbon management service.
   From precise carbon footprint measurements to insightful analytics and strategic action plans,
   we provide the tools and expertise to drive positive change. Join us in the journey to a greener,
    more responsible future, and make a meaningful impact on the environment. 
  Together, we can create a sustainable world for generations to come.`,
  scheduleButtonName: 'Schedule a Call',
  step1: 'Measure',
  step1Desc: `- Accurately measure your carbon footprint using 
  advanced methodologies and reliable data sources.`,
  step1SubDesc: `- Get valuable insights into your emissions profile,
   helping you understand your environmental impact better.`,
  step2: 'Analyze',
  step2Desc: `- Our advanced analytics engine crunches the numbers
   to provide you with clear and actionable insights.`,
  step2SubDesc: `- Visualize your emissions data through interactive 
  graphs and charts for easy understanding.`,
  step3: 'Strategize',
  step3Desc: `- Collaborate with our expert team to develop a strategic 
  plan for reducing your carbon footprint.`,
  step3SubDesc: `- Set ambitious sustainability goals and track your progress in 
  real-time on our user-friendly dashboard.`,
  step4: 'Reduce',
  step4Desc: `- Implement effective sustainability initiatives
   tailored to your business, industry, and values.`,
  step4SubDesc: `- Take decisive action to lower your emissions,
   minimize your carbon impact, and drive positive change.`,
  step5: 'Offset',
  step5Desc: `- For unavoidable emissions, explore options to 
  offset your carbon footprint through verified and impactful carbon credit projects.`,
  step5SubDesc: `- Make a meaningful contribution to global sustainability efforts 
  and support projects aligned with your company's values.`
};

export default UpgradeToPremium;
