import {Box} from '@mui/material';
import FormBuilder from 'Components/Shared/FormBuilder';
import React from 'react';

const Principle3: React.FC = () => {
  const companyId = window?.bharatCarbon?.company?._id;
  const handleSubmit = (data: any) => {
    data.companyId = companyId;
    // eslint-disable-next-line no-console
    console.log(data);
  };

  return (
    <Box sx={{padding: '30px 24px 24px 24px'}}>
      <FormBuilder
        fields={[
          {type: 'div', label: 'ESSENTIAL INDICATORS', key: ''},
          {
            type: 'table',
            label:
              'Details of measures for the well-being of permanent employees:',
            key: 'empWellBeing',
            static: ['Male', 'Female', 'Total'],
            options: [
              {type: 'box', label: 'Category', key: ''},
              {type: 'input', label: 'Total Employees A', key: 'totalEmp'},
              {
                type: 'input',
                label: 'Health insurance in number B',
                key: 'healthInsurance'
              },
              {
                type: 'input',
                label: 'Health insurance in % (B/A)',
                key: 'healthInsurancePer'
              },
              {
                type: 'input',
                label: 'Accident insurance in number C',
                key: 'accidentInsurance'
              },
              {
                type: 'input',
                label: 'Accident insurance in % C/A',
                key: 'accidentInsurancePer'
              },
              {
                type: 'input',
                label: 'Maternity benefits in number D',
                key: 'maternityBenefitsNumber',
                inputType: 'number'
              },
              {
                type: 'input',
                label: 'Maternity benefits in % D/A',
                key: 'maternityBenefitsPercent',
                inputType: 'number'
              },
              {
                type: 'input',
                label: 'Paternity Benefits in number E',
                key: 'paternityBenefitsNumber',
                inputType: 'number'
              },
              {
                type: 'input',
                label: 'Paternity Benefits in % E/A',
                key: 'paternityBenefitsPercent',
                inputType: 'number'
              },
              {
                type: 'input',
                label: 'Day Care facilities in number F',
                key: 'dayCareNumber',
                inputType: 'number'
              },
              {
                type: 'input',
                label: 'Day Care facilities in % F/A',
                key: 'dayCarePercent',
                inputType: 'number'
              }
            ]
          },
          {
            type: 'table',
            label:
              'Details of measures for the well-being of Other than Permanent employees (on fixed term contract):',
            key: 'otherEmpWellBeing',
            static: ['Male', 'Female', 'Total'],
            options: [
              {type: 'box', label: 'Category', key: ''},
              {type: 'input', label: 'Total Employees A', key: 'totalEmp'},
              {
                type: 'input',
                label: 'Health insurance in number B',
                key: 'healthInsurance'
              },
              {
                type: 'input',
                label: 'Health insurance in % (B/A)',
                key: 'healthInsurancePer'
              },
              {
                type: 'input',
                label: 'Accident insurance in number C',
                key: 'accidentInsurance'
              },
              {
                type: 'input',
                label: 'Accident insurance in % C/A',
                key: 'accidentInsurancePer'
              },
              {
                type: 'input',
                label: 'Maternity benefits in number D',
                key: 'maternityBenefitsNumber',
                inputType: 'number'
              },
              {
                type: 'input',
                label: 'Maternity benefits in % D/A',
                key: 'maternityBenefitsPercent',
                inputType: 'number'
              },
              {
                type: 'input',
                label: 'Paternity Benefits in number E',
                key: 'paternityBenefitsNumber',
                inputType: 'number'
              },
              {
                type: 'input',
                label: 'Paternity Benefits in % E/A',
                key: 'paternityBenefitsPercent',
                inputType: 'number'
              },
              {
                type: 'input',
                label: 'Day Care facilities in number F',
                key: 'dayCareNumber',
                inputType: 'number'
              },
              {
                type: 'input',
                label: 'Day Care facilities in % F/A',
                key: 'dayCarePercent',
                inputType: 'number'
              }
            ]
          },
          {
            type: 'table',
            label:
              'Details of retirement benefits, for current and previous Financial Year:',
            key: 'retirementBenefits',
            static: [
              'PF',
              'Gratuity',
              'ESI',
              `Others – Post
            retirement
            benefits`
            ],
            options: [
              {type: 'box', label: 'Benefits', key: 'benefits'},
              {
                type: 'input',
                label: `No of
            employees in Percent for FY`,
                key: 'noEmpPercent',
                inputType: 'number'
              },
              {
                type: 'input',
                label: `No of
            workers in Percent for FY`,
                key: 'noWorkPercent',
                inputType: 'number'
              },
              {
                type: 'text',
                label: `Deducted and
                deposited
                with the
                authority for FY (Y / N / NA)`,
                key: 'noEmpPercent'
              },
              {
                type: 'input',
                label: `No of
            employees in Percent for last FY`,
                key: 'noEmpPercent',
                inputType: 'number'
              },
              {
                type: 'input',
                label: `No of
            workers in Percent for last FY`,
                key: 'noWorkPercent',
                inputType: 'number'
              },
              {
                type: 'text',
                label: `Deducted and
                deposited
                with the
                authority for last FY (Y / N / NA)`,
                key: 'noEmpPercent'
              }
            ]
          },
          {
            type: 'textarea',
            label: `Accessibility of workplaces are the premises 
            / offices of the Company accessible to differently abled
            employees and workers, as per 
            the requirements of the Rights of Persons with Disabilities Act,
            2016? If not, whether any steps are being taken by the Company in this regard.`,
            key: 'rightOfpersonForDisability'
          },
          {
            type: 'textarea',
            label: `Does the Company have an equal opportunity policy as per the Rights of Persons with Disabilities
            Act, 2016? If so, provide a web-link to the policy.`,
            key: 'equalOpportunity'
          },
          {
            type: 'table',
            label:
              'Return to work and Retention rates of permanent employees and workers that took parental leave:',
            key: 'workRetentionRate',
            static: ['Male', 'Female'],
            options: [
              {
                type: 'box',
                label: 'Gender'
              },
              {
                type: 'input',
                label: 'Return to work rate of permanent employees',
                key: 'rateOfReturn',
                inputType: 'number'
              },
              {
                type: 'input',
                label: 'Retention rate of permanent employees',
                key: 'retentionReturn',
                inputType: 'number'
              }
            ]
          },
          {
            type: 'textarea',
            label: `Is there a mechanism available to receive and redress grievances for the following categories of
            employees and worker? If yes, give details of the mechanism in brief:`,
            key: 'mechanismAvailable'
          },
          {
            type: 'textarea',
            label: `Membership of employees and
             worker in association(s) or Unions recognised by the listed Company.`,
            key: 'membershipEmp'
          },
          {
            type: 'table',
            label: `Details of training given 
            to employees and workers:`,
            key: 'trainingDetails',
            options: [
              {type: 'box', label: 'Category', key: ''},
              {
                type: 'text',
                label: `Total
                (A)`,
                key: 'total'
              },
              {
                type: 'input',
                label: `Number of training On Health and
                safety measures in Current FY (B)`,
                key: 'noOfTrainingOnHealthCurrent',
                inputType: 'number'
              },
              {
                type: 'input',
                label: '%(B/A)',
                key: 'noOfTrainingOnHealthPercentCurrent',
                inputType: 'number'
              },
              {
                type: 'input',
                label: `On Skill
                Upgradation in Current FY (C)`,
                key: 'onSkillUpCurrent',
                inputType: 'number'
              },
              {
                type: 'input',
                label: '%(C/A)',
                key: 'onSkillUpCurrentPercent',
                inputType: 'number'
              },
              {
                type: 'text',
                label: `Total
                (D)`,
                key: 'total'
              },
              {
                type: 'input',
                label: `Number of training On Health and
                safety measures in Last FY (E)`,
                key: 'noOfTrainingOnHealthLast',
                inputType: 'number'
              },
              {
                type: 'input',
                label: '%(E/A)',
                key: 'noOfTrainingOnHealthPercentLast',
                inputType: 'number'
              },
              {
                type: 'input',
                label: `On Skill
                Upgradation in Last FY (F)`,
                key: 'onSkillUpLast',
                inputType: 'number'
              },
              {
                type: 'input',
                label: '%(F/A)',
                key: 'onSkillUpLastPercent',
                inputType: 'number'
              }
            ]
          },
          {
            type: 'table',
            label: `Details of performance and career 
            development reviews of employees and worker`,
            key: 'performanceReview',
            static: ['Male', 'Female', 'Total'],
            options: [
              {type: 'box', label: 'Employees', key: ''},
              {
                type: 'input',
                label: 'Total in Current FY (A)',
                key: 'performanceReviewTotalMaleCurr',
                inputType: 'number'
              },
              {
                type: 'input',
                label: 'No. (B) in Current FY',
                key: 'performanceReviewNoMaleCurr',
                inputType: 'number'
              },
              {
                type: 'input',
                label: '% (B/A) in Current FY',
                key: 'performanceReviewMaleCurrPercent',
                inputType: 'number'
              },
              {
                type: 'input',
                label: 'Total in Last FY (A)',
                key: 'performanceReviewTotalMaleLast',
                inputType: 'number'
              },
              {
                type: 'input',
                label: 'No. (B) in Last FY',
                key: 'performanceReviewNoMaleLast',
                inputType: 'number'
              },
              {
                type: 'input',
                label: '% (B/A) in Last FY',
                key: 'performanceReviewMaleLastPercent',
                inputType: 'number'
              }
            ]
          },
          {
            type: 'div',
            label: 'Health and safety management system',
            key: ''
          },
          {
            type: 'textarea',
            label: `Implementation of occupational health and safety management system by the Company along with extent
            of coverage.`,
            key: 'implementationOfhealthAndSafety'
          },
          {
            type: 'textarea',
            label: `Processes used to identify work-related 
            hazards and assess risks on a routine and non-routine basis by the
            Company.`,
            key: 'processForWorkHazard'
          },
          {
            type: 'textarea',
            label: `Processes for identification and reporting of 
            occupational hazards by workers and action to be taken.`,
            key: 'processForIdendificationWorkHazard'
          },
          {
            type: 'textarea',
            label: `Do the employees/ workers of the Company have access to non-occupational medical and healthcare
            services?`,
            key: 'nonOccupationalMedication'
          },
          {
            type: 'table',
            label:
              'Details of safety related incidents, in the following format',
            key: 'safetyRelatedDetails',
            options: [
              {
                type: 'box',
                label: 'Safety Incident/Number',
                key: 'safetyIncident'
              },
              {
                type: 'input',
                label: 'Current FY',
                inputType: 'number',
                key: 'currentFY'
              },
              {
                type: 'input',
                label: 'Last FY',
                inputType: 'number',
                key: 'lastFY'
              }
            ],
            static: [
              `Lost Time Injury Frequency Rate (LTIFR) (per
                one million-person hours worked)`,
              'Total recordable work-related injuries',
              'No. of fatalities',
              `High consequence work-related injury or
              ill-health (excluding fatalities)`
            ]
          },
          {
            type: 'textarea',
            label:
              'Measures taken by the Company to ensure a safe and healthy workplace',
            key: 'SafeMeasures'
          },
          {
            type: 'table',
            label:
              'Number of Complaints on the following made by employees and workers',
            key: 'noOfComplaints',
            static: [
              `Working
            Conditions`,
              'Health and Safety'
            ],
            options: [
              {type: 'box', label: 'Type', key: 'type'},
              {
                type: 'input',
                label: `Filed during
            the Current FY`,
                key: 'filedCurrent'
              },
              {
                type: 'input',
                label: `Pending
                resolution at
                the end of
                Current FY`,
                key: 'pendingCurrent'
              },
              {
                type: 'text',
                label: 'Remarks',
                key: 'remarks'
              },
              {
                type: 'input',
                label: `Filed during
            the Last FY`,
                key: 'filedLast'
              },
              {
                type: 'input',
                label: `Pending
                resolution at
                the end of
                Last FY`,
                key: 'pendingLast'
              },
              {
                type: 'text',
                label: 'Remarks',
                key: 'remarks'
              }
            ]
          },
          {
            type: 'table',
            label: 'Assessments for the year',
            key: 'assessmentsYear',
            static: ['Health and safety practices', 'Working Conditions'],
            options: [
              {type: 'box', label: 'Type', key: 'type'},
              {
                type: 'input',
                label: `% of Company’s plants and offices that were assessed (by Company or
                statutory authorities or third parties)`,
                key: 'percent',
                inputType: 'number'
              }
            ]
          },
          {
            type: 'textarea',
            label: `Provide details of any corrective action taken or underway to address safety-related incidents (if
            any) and on significant risks / concerns arising from assessments of health and safety practices and
            working conditions.`,
            key: 'workingConditions'
          },
          {type: 'div', label: 'LEADERSHIP INDICATORS', key: ''},
          {
            type: 'textarea',
            label: `Does the Company extend any life insurance or any compensatory package in the event of death of
            employees (Y/N)?`,
            key: 'lifeInsurance'
          },
          {
            type: 'textarea',
            label: `Provide the measures undertaken by the Company to ensure that statutory dues have been deducted
            and deposited by the value chain partners.`,
            key: 'valueChain'
          },
          {
            type: 'table',
            label: `Provide the number of employees / workers 
            having suffered high consequence work-related injury / illhealth
            / fatalities (as reported in Q11 of Essential Indicators above), who have been rehabilitated and
            placed in suitable employment or whose family members have been placed in suitable employment`,
            key: 'illhealth',
            static: ['Employees', 'Workers'],
            options: [
              {
                type: 'box',
                label: 'Type',
                key: 'type'
              },
              {
                type: 'input',
                label: 'Total no. of affected employees/ workers in current FY',
                key: 'affectedEmployeesCurrent',
                inputType: 'number'
              },
              {
                type: 'input',
                label: 'Total no. of affected employees/ workers in last FY',
                key: 'affectedEmployeesLast',
                inputType: 'number'
              },
              {
                type: 'input',
                label: `No. of employees/workers that are
              rehabilitated and placed in suitable
              employment or whose family members
              have been placed in suitable employment in current FY`,
                key: 'rehabilitatedCurrent',
                inputType: 'number'
              },
              {
                type: 'input',
                label: `No. of employees/workers that are
              rehabilitated and placed in suitable
              employment or whose family members
              have been placed in suitable employment in last FY`,
                key: 'rehabilitatedLast',
                inputType: 'number'
              }
            ]
          },
          {
            type: 'textarea',
            label: `Does the Company provide transition assistance programs to facilitate continued employability
            and the management of career endings resulting from retirement or termination of employment?
            (Yes/ No).`,
            key: 'termination'
          },
          {
            type: 'table',
            label: 'Details on assessment of value chain partners',
            key: 'valueChainAssessment',
            static: ['Health and safety practices', 'Working Conditions'],
            options: [
              {
                type: 'box',
                label: 'Type',
                key: ''
              },
              {
                type: 'textarea',
                label: `% of value chain partners (by value of business done with such partners) that
                were assessed`,
                key: 'valeuChainPartner'
              }
            ]
          },
          {
            type: 'textarea',
            label: `Provide details of any corrective actions taken or underway to address significant risks / concerns
            arising from assessments of health and safety practices and working conditions of value chain
            partners.`,
            key: 'correctiveActions'
          }
        ]}
        title='Principle 3 : Businesses Should Respect And Promote The Well-being Of All
        Employees, Including Those In Their Value Chains'
        onSubmit={handleSubmit}
      />
    </Box>
  );
};

export default Principle3;
