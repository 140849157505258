/* eslint-disable no-console */
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TextField,
  IconButton,
  Box,
  Grid,
  Select,
  MenuItem,
  Checkbox
} from '@mui/material';
import {options} from 'Components/Shared/Constants/FormBuilder';
import {Field, OptionsField} from 'Components/Shared/Interfaces/FormBuilder';
import LinearLoader from 'Components/Shared/LinearLoader';
import React, {useEffect, useState} from 'react';
import {Controller, useFieldArray} from 'react-hook-form';

const DynamicTable: React.FC<{config: Field; control: any; name: string}> = ({
  config,
  control,
  name
}: any) => {
  const [isLoading, setIsLoading] = useState(true);
  const {fields, append, remove} = useFieldArray({
    control,
    name
  });

  const renderTableInput = (option: OptionsField, index: number) => {
    switch (option.type) {
      case 'text':
        return (
          <Grid item xs={12} key={index}>
            <Controller
              name={`${name}[${index}].${option.key}`}
              control={control}
              defaultValue=''
              render={({field}) => <TextField {...field} variant='outlined' />}
            />
          </Grid>
        );
      case 'textarea':
        return (
          <Grid item xs={12} key={index}>
            <Controller
              name={`${name}[${index}].${option.key}`}
              control={control}
              defaultValue=''
              render={({field}) => (
                <TextField {...field} fullWidth multiline variant='outlined' />
              )}
            />
          </Grid>
        );
      case 'input':
        return (
          <Grid item xs={12} key={index}>
            <Controller
              name={`${name}.[${index}].${option.key}`}
              disabled={
                Array.isArray(option.value) && option.value[index] === 'NA'
              }
              control={control}
              defaultValue={
                Array.isArray(option.value) && option.value[index] === 'NA'
                  ? 0
                  : ''
              }
              render={({field}) => (
                <TextField
                  {...field}
                  fullWidth
                  variant='outlined'
                  type={option.inputType ? option.inputType : 'text'}
                />
              )}
            />
          </Grid>
        );
      case 'select':
        return (
          <Grid item xs={12} key={index}>
            <Controller
              name={`${name}[${index}].${option.key}`}
              control={control}
              defaultValue={''}
              render={({field: {onChange, value}}) => (
                <Select
                  fullWidth
                  placeholder='Select'
                  value={value || ''}
                  onChange={onChange}
                  variant='outlined'
                >
                  {option.selectOption &&
                    option?.selectOption?.map((el: string, i: number) => {
                      return (
                        <MenuItem key={i} value={el}>
                          {el}
                        </MenuItem>
                      );
                    })}
                </Select>
              )}
            />
          </Grid>
        );
      case 'box':
        // const Key = option.key;
        // fields.forEach((item, index) => {
        //   return (item[Key] = config.static[index]);
        // });

        return <Box>{option.label}</Box>;
      case 'checkBox':
        return (
          <Grid item xs={12} key={index}>
            <Controller
              name={`${name}[${index}].${option.key}`}
              control={control}
              render={({field: {onChange, value}}) => (
                <Checkbox
                  onChange={onChange}
                  defaultChecked={false}
                  checked={value}
                />
              )}
            />
          </Grid>
        );
      default:
        return <></>;
    }
  };

  useEffect(() => {
    fields.push(
      config.options.reduce((acc: any, option: any) => {
        acc[option.key] = '';

        return acc;
      }, {})
    );
    setIsLoading(false);
  }, []);

  const {Sr_no} = options;

  return (
    <TableContainer>
      <Box sx={{ml: '18px'}}>{config.label}</Box>
      {isLoading ? (
        <LinearLoader />
      ) : (
        <Table>
          <TableHead>
            <TableRow>
              {config.options.map((option: OptionsField, index: number) => (
                <TableCell key={`${option.key}.${index}`}>
                  {option.label}
                </TableCell>
              ))}

              {!config.static && (
                <TableCell>
                  <IconButton
                    onClick={() =>
                      append(
                        config.options.reduce((acc: any, option: any) => {
                          if (option.label !== Sr_no) {
                            acc[option.key] = '';
                          }

                          return acc;
                        }, {})
                      )
                    }
                  >
                    <AddCircleIcon />
                  </IconButton>
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {!!config.static
              ? config.static.map((staticMap: string, index: number) => {
                  return (
                    <TableRow key={index}>
                      {config.options.map(
                        (option: OptionsField, rowIndex: number) => {
                          return config.options[0].label === option.label ? (
                            <TableCell key={`${index}.${rowIndex}`}>
                              {staticMap}
                            </TableCell>
                          ) : (
                            <TableCell
                              key={`${option.key}.${index}.${rowIndex}`}
                            >
                              {renderTableInput(option, index)}
                            </TableCell>
                          );
                        }
                      )}
                    </TableRow>
                  );
                })
              : fields.map((item, index) => (
                  <TableRow key={item.id || index}>
                    {config.options.map(
                      (option: OptionsField, rowIndex: number) =>
                        option.label === Sr_no ? (
                          <TableCell key={`${option.key}.${index}.${rowIndex}`}>
                            {index + 1}
                          </TableCell>
                        ) : (
                          <TableCell key={`${option.key}.${index}.${rowIndex}`}>
                            {renderTableInput(option, index)}
                          </TableCell>
                        )
                    )}
                    {index > 0 && !config.static && (
                      <TableCell align='center' key={1}>
                        <IconButton onClick={() => remove(index)}>
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    )}
                  </TableRow>
                ))}
          </TableBody>
        </Table>
      )}
    </TableContainer>
  );
};

export default DynamicTable;
