import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import WidgetDataState from 'Components/Widgets/Constants/WidgetData';
import WidgetData from 'Components/Widgets/Interfaces/WidgetData';

/**
 * Redux slice for the widget Data store.
 */
const createWidgetDataStore = createSlice({
    name: 'widgetData',
    initialState: WidgetDataState,
    /**
     * Sets the widget Data in the state.
     * @param {WidgetDataState} state - The current state.
     * @param {PayloadAction<WidgetData[]>} action - The action containing the widget data payload.
     */
    reducers: {
        setWidgetData: (state, action: PayloadAction<WidgetData[]>) => {
            action?.payload?.forEach(i => {
                state.push(i);
            });
        }
    }
});

export default createWidgetDataStore;
