import {Box} from '@mui/material';
import FormBuilder from 'Components/Shared/FormBuilder';
import React from 'react';

const Principle9: React.FC = () => {
  const companyId = window?.bharatCarbon?.company?._id;
  const handleSubmit = (data: any) => {
    data.companyId = companyId;
    // eslint-disable-next-line no-console
    console.log(data);
  };

  return (
    <Box sx={{padding: '30px 24px 24px 24px'}}>
      <FormBuilder
        fields={[
          {type: 'div', label: 'ESSENTIAL INDICATORS', key: ''},
          {
            type: 'textarea',
            label: `Describe the mechanisms 
            in place to receive and respond 
            to consumer complaints and feedback.`,
            key: 'mechanismsDetail'
          },
          {
            type: 'table',
            label: `Turnover of products and/ services as a percentage of turnover from all products/service that carry
            information about:`,
            key: 'Turnover',
            static: [
              'Environmental and social parameters relevant to the product',
              'Safe and responsible usage, Recycling and/or safe disposal'
            ],
            options: [
              {
                type: 'box',
                label: 'Parameter',
                key: 'para'
              },
              {
                type: 'input',
                label: 'As a percentage to total turnover',
                key: 'percentageTurnover',
                inputType: 'number'
              }
            ]
          },
          {
            type: 'table',
            label: `Number of consumer complaints in 
            respect of the following:`,
            key: 'noOfConsumerCompliants',
            static: [
              'Data privacy',
              'Advertising',
              'Cyber-security',
              'Delivery of essential services',
              'Restrictive Trade Practices',
              'Unfair Trade Practices',
              'Other'
            ],
            options: [
              {type: 'box', label: 'Parameters', key: 'Parameters'},
              {
                type: 'input',
                label: `Received
                during
                the Current FY`,
                key: 'receivedCurrent',
                inputType: 'number'
              },
              {
                type: 'input',
                label: `Pending
                resolution at
                end of Current FY`,
                key: 'pendingCurrent',
                inputType: 'number'
              },
              {
                type: 'text',
                label: 'Remarks',
                key: 'remarks',
                inputType: 'number'
              },
              {
                type: 'input',
                label: `Received
                during
                the Last FY`,
                key: 'receivedLast',
                inputType: 'number'
              },
              {
                type: 'input',
                label: `Pending
                resolution at
                end of Last FY`,
                key: 'pendingLast',
                inputType: 'number'
              },
              {
                type: 'text',
                label: 'Remarks',
                key: 'remarks',
                inputType: 'number'
              }
            ]
          },
          {
            type: 'textarea',
            label: `Details of instances of product recalls 
            on account of safety issues.`,
            key: 'detailsInstances'
          },
          {
            type: 'textarea',
            label: `Does the Company have a framework/ policy on cyber security and risks related to data privacy?
            (Yes/No) If available, provide a web-link of the policy.`,
            key: 'frameworkPolicy'
          },
          {
            type: 'textarea',
            label: `Provide details of any corrective actions taken or underway on issues relating to advertising, and
            delivery of essential services; cyber security and data privacy of customers; re-occurrence of instances
            of product recalls; penalty / action taken by regulatory authorities on safety of products / services.`,
            key: 'correctiveActions'
          },
          {type: 'div', label: 'LEADERSHIP INDICATORS', key: ''},
          {
            type: 'textarea',
            label: `Channels / platforms where information on products and services of the Company can be accessed
            (provide web link, if available).`,
            key: 'channelsInformation'
          },
          {
            type: 'textarea',
            label: `Steps taken to inform and educate consumers about safe and responsible usage of products and/or
            services.`,
            key: 'steps'
          },
          {
            type: 'textarea',
            label: `Mechanisms in place to inform consumers of any risk of disruption/discontinuation of essential
            services.`,
            key: 'mechanisms'
          },
          {
            type: 'textarea',
            label: `Does the Company display product information on the product over and above what is mandated as
            per local laws? (Yes/No/Not Applicable) If yes, provide details in brief.`,
            key: 'localLaws'
          },
          {
            type: 'textarea',
            label: `Did your Company carry out any survey with regard to consumer satisfaction relating to the major
            products / services of the Company, significant locations of operation of the Company or the
            Company as a whole? (Yes/No).`,
            key: 'significantLocations'
          },
          {
            type: 'textarea',
            label: `Provide the following information 
            relating to data breaches:`,
            key: 'informationBreaches'
          }
        ]}
        title='Principle 9 : Businesses Should Engage With And Provide Value To Their
        Consumers In A Responsible Manner'
        onSubmit={handleSubmit}
      />
    </Box>
  );
};

export default Principle9;
