/* eslint-disable no-console */
import React, {useEffect, useState} from 'react';
import Joyride, {STATUS} from 'react-joyride';

import getHelp from './Utils/getHelp';
// import { BorderStyle } from '@mui/icons-material';
// import { colors } from '@mui/material';

const Help: React.FC<{
  currentPath: string;
  isGuest: boolean;
  setShowTour: React.Dispatch<React.SetStateAction<boolean>>;
  showTour: boolean;
}> = ({currentPath, isGuest, setShowTour, showTour}) => {
  const [show, setShow] = useState(false);
  const [steps, setSteps] = useState<any>([
    {target: '#try', content: 'This is my super awesome feature!'}
  ]);

  useEffect(() => {
    setShow(false);
    if (showTour) {
      setSteps(getHelp(currentPath, isGuest));
      setShow(true);
    }
  }, [showTour, steps]);

  const handleJoyride = ({status}: any) => {
    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      return setShowTour(false);
    }
  };

  return show ? (
    <Joyride
      run={showTour}
      steps={steps}
      styles={{
        options: {
          arrowColor: '#024064',
          backgroundColor: '#024064',
          textColor: '#ffffff',
          zIndex: 100
        },
        buttonNext: {
          backgroundColor: '#20E2BD',
          borderColor: '#ffffff',
          borderStyle: 'solid',
          borderWidth: '0px',
          fontFamily: '"Quicksand"',
          color: '#024064',
          borderRadius: '4px',
          fontSize: '14px',
          textTransform: 'uppercase',
          padding: '8px 20px'
        },
        buttonBack: {
          backgroundColor: '#20E2BD',
          borderColor: '#ffffff',
          borderStyle: 'solid',
          fontFamily: '"Quicksand"',
          color: '#024064',
          borderWidth: '0px',
          borderRadius: '4px',
          fontSize: '14px',
          textTransform: 'uppercase',
          padding: '8px 20px'
        },
        buttonSkip: {
          backgroundColor: '#024064',
          borderColor: '#ffffff',
          borderStyle: 'solid',
          borderWidth: '1px',
          borderRadius: '4px',
          padding: '8px 20px',
          fontSize: '14px',
          textTransform: 'uppercase',
          fontFamily: '"Quicksand"'
        },
        buttonClose: {
          backgroundColor: '#20E2BD',
          borderColor: '#ffffff',
          borderStyle: 'solid',
          borderWidth: '1px'
        },
        tooltipContainer: {
          textAlign: 'left'
        },
        tooltipContent: {
          padding: '0px 0px 20px 0px'
        }
      }}
      callback={handleJoyride}
      continuous={true}
      disableOverlay={false}
      disableScrolling
      // showProgress={true}
      showSkipButton={true}
      hideCloseButton={true}
      spotlightClicks={false}
      locale={{back: 'Back', next: 'Next', close: 'Cancel', last: 'Finish'}}
    />
  ) : (
    <></>
  );
};

export default Help;
