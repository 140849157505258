import {Box, Typography} from '@mui/material';
import TooltipMessage from 'Components/Shared/Constants/TooltipMessages';
import Tooltip from 'Components/Shared/Tooltip';
import React, {useState, useEffect} from 'react';
import {useTranslation} from 'react-i18next';

import BenchMark from '../Interfaces/BenchMark';
import {getBenchMarkData} from '../Utils/getBenchMarkData';

/**
 * Industry Benchmark Component renders the kpis and benchmark set for respective kpi
 * with respect to the industry
 * @component
 */
const IndustryBenchmark: React.FC = () => {
  const {t} = useTranslation();
  const [data, setData] = useState<BenchMark[] | undefined>();

  /**
   * gentBenchMarkData return the data for the benchmark as per user industry
   * setData sets the data received from getBenchmarkData
   * data is shown to user
   */
  useEffect(() => {
    getBenchMarkData().then((res) => {
      setData(res);
    });
  }, []);

  return (
    <>
      <Box className='tile'>
        <Box display='flex' justifyContent='space-between'>
          <Typography variant='h2'>{t('OUR_BENCHMARK')}</Typography>
          <Tooltip message={TooltipMessage.GUEST_BENCHMARK} />
        </Box>
        {!data ? (
          <Box sx={{display: 'block', textAlign: 'center', margin: '95px'}}>
            {t('ERROR')}
          </Box>
        ) : (
          <Box className='data-type'>
            {data?.map((item, index) => {
              if (item?.display) {
                return (
                  <Box key={index} className='data-list'>
                    <Box className='data-title'>{item.emissionCategory}</Box>
                    <Box className='text-wicon'>
                      <img src={item.icon} alt='' />
                      {item.carbonEmission} T
                    </Box>
                    <Box className='short-info'>{item.description}</Box>
                  </Box>
                );
              } else {
                return null;
              }
            })}
          </Box>
        )}
      </Box>
    </>
  );
};

export default IndustryBenchmark;
