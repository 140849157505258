import React from 'react';
import {
  PieChart,
  Pie,
  Cell,
  ResponsiveContainer,
  Tooltip,
  Legend
} from 'recharts';

import PieChartScopeProps from './Interfaces/PieChartScopeProps';
import PiechartTooltip from './Layouts/PiechartTooltip';
import PieScopeCustomizedLegend from './Layouts/PieScopeCustomizedLegend';
import RenderCustomizedLabelScope from './Layouts/RenderCustomizedLabelScope';

const PieChartScope: React.FC<{data: PieChartScopeProps[]}> = ({data}) => {
  return (
    <ResponsiveContainer width='100%' aspect={1} className='pieChartScope'>
      <PieChart>
        <Pie
          data={data}
          cx='150'
          cy='120'
          innerRadius={60}
          outerRadius={80}
          paddingAngle={4}
          dataKey='value'
          label={(props) => <RenderCustomizedLabelScope {...props} />}
          nameKey='value'
          startAngle={90}
          endAngle={450}
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={entry.fill} />
          ))}
        </Pie>
        <Legend
          iconSize={7}
          layout='vertical'
          verticalAlign='middle'
          iconType='circle'
          content={<PieScopeCustomizedLegend data={data} />}
        />
        <Tooltip
          content={({active, payload, label}) =>
            PiechartTooltip({active, payload, label})
          }
        />
      </PieChart>
    </ResponsiveContainer>
  );
};

export default PieChartScope;
