import React from 'react';
import {PopupModal} from 'react-calendly';
import {useSelector} from 'react-redux';
import StoreInterface from 'State/Interfaces/StoreInterface';

import Calendly from '../ScheduleCall/Constants/Calendly';

const CalendlyPopUp: React.FC<{
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  meetingType: string;
}> = ({open, setOpen, meetingType}) => {
  const loggedUser = useSelector((state: StoreInterface) => state.user);

  return (
    <PopupModal
      url={`${Calendly.MEETING_URL}${meetingType}`}
      pageSettings={{
        backgroundColor: Calendly.PAGE_SETTING_BG_COLOR,
        hideEventTypeDetails: Calendly.PAGE_SETTING_HIDE_EVENT,
        hideLandingPageDetails: Calendly.PAGE_SETTING_HIDE_DETAILS,
        primaryColor: Calendly.PAGE_SETTING_PRIMARY_COLOR,
        textColor: Calendly.PAGE_SETTING_TEXT_COLOR
      }}
      prefill={{
        email: loggedUser?.email,
        firstName: '',
        lastName: '',
        name: loggedUser?.firstName + ' ' + loggedUser?.lastName
      }}
      onModalClose={() => setOpen(false)}
      open={open}
      rootElement={document.getElementById('root') as HTMLElement}
    />
  );
};

export default CalendlyPopUp;
