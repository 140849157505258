import {Box, CardMedia, Typography} from '@mui/material';
import * as React from 'react';

import CommunityProps from '../Interfaces/CommunityProp';

/**
 * CommunityBox component represents a box displaying community-related information.
 * @component
 */
const CommunityBox: React.FC<CommunityProps> = ({
  header,
  author,
  description,
  date,
  read_time,
  link,
  img
}) => {
  return (
    <>
      <a href={link} target='_blank' rel='noreferrer'>
        <Box className='community-box'>
          <Box className='community-header'>
            <CardMedia component='img' image='' alt='click here' src={img} />
            <Box className='heading'>
              <Typography component='div'>{header}</Typography>
              <Typography
                variant='subtitle1'
                color='text.secondary'
                component='div'
              >
                {author}
              </Typography>
            </Box>
          </Box>
          <Box className='community-data' id='splitreporting2'>
            <Box className='description'>{description}</Box>
            <Box
              className='datenauth'
              sx={{display: 'flex', justifyContent: 'space-between'}}
            >
              <Typography
                variant='subtitle1'
                color='text.secondary'
                component='div'
              >
                {date}
              </Typography>
              <Typography
                variant='subtitle1'
                color='text.secondary'
                component='div'
              >
                {`${read_time} min read`}
              </Typography>
            </Box>
          </Box>
        </Box>
      </a>
    </>
  );
};

export default CommunityBox;
