import {AppState, Auth0Provider} from '@auth0/auth0-react';
import RoutesURLs from 'Components/Shared/Constants/Routes';
import history from 'Components/Shared/Utils/history';
import React from 'react';
import ReactDOM from 'react-dom/client';
import {Provider} from 'react-redux';
import {BrowserRouter} from 'react-router-dom';
import createState from 'State/createState';

import App from './App';
import './Styles/style.css';

const onRedirectCallback = (appState?: AppState | undefined) => {
  history.push(appState?.returnTo || window.location.pathname);
};

const reDirect = {
  redirect_uri: `${window.location.origin}${RoutesURLs.DASHBOARD}`
};
const providerConfig = {
  domain: `${process.env.REACT_APP_AUTH_DOMAIN}`,
  clientId: `${process.env.REACT_APP_AUTH_CLIENT_ID}`,
  authorizationParams: {...reDirect},
  onRedirectCallback
};

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <BrowserRouter>
    <Auth0Provider {...providerConfig}>
      <Provider store={createState()}>
        <App />
      </Provider>
    </Auth0Provider>
  </BrowserRouter>
);
