import axios, {AxiosResponse} from 'axios';
import {InitiativeScore} from 'Components/Shared/Interfaces/Initiatives';

interface ResponseType {
    data: InitiativeScore[];
}

/**
 * Retrieves InitiativeScore .
 * @returns {Promise<InitiativeScore[]>} A promise that resolves to an array of InitiativeScore.
 */
const getInitiativeScore = async (): Promise<InitiativeScore[]> => {
	const API_BASE_PATH = process.env.REACT_APP_API_PATH;
	const API_GET_INITIATIVE_SCORE = `${API_BASE_PATH}${process.env.REACT_APP_API_GET_INITIATIVE_SCORE}`;
	try {
		/** Use AxiosResponse to add type to response data */ 
		const response: AxiosResponse<ResponseType> = await axios.get(API_GET_INITIATIVE_SCORE, {
			headers: {
				authorization: `Bearer ${window?.bharatCarbon?.auth?.token}`
			}
		});

		return response.data.data;
	} catch (error) {
		console.error('Error fetching data:', error);
		/** Throw the error to be caught by the calling function */ 
	}

	return [];
};

export default getInitiativeScore;
