import {Typography} from '@mui/material';
import React from 'react';

import TakeActionTinyChart from '../../Charts/TakeActionTinyChart';
import ActionChartProps from '../Interface/ActionChartProps';

const OnReductionChart: React.FC<{chartData: ActionChartProps[]}> = ({
  chartData
}) => {
  return (
    <>
      <Typography
        sx={{
          position: 'absolute',
          color: '#F27260',
          font: 'normal normal 600 13px/16px Quicksand',
          textTransform: 'capitalize',
          top: '5px',
          left: '20px'
        }}
      >
        Total Emissions
      </Typography>
      <Typography
        sx={{
          position: 'absolute',
          color: '#26AF5F',
          font: 'normal normal 600 13px/16px Quicksand',
          textTransform: 'capitalize',
          bottom: '100px',
          right: '10px'
        }}
      >
        Net Zero
      </Typography>
      <TakeActionTinyChart data={chartData} />
    </>
  );
};

export default OnReductionChart;
