import {Grid, Box} from '@mui/material';
import Loader from 'Components/Shared/Loader';
import PageId from 'Components/Widgets/Constants/PageId';
import Widget from 'Components/Widgets/Interfaces/Widget';
import Widgets from 'Components/Widgets/Widgets';
import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import StoreInterface from 'State/Interfaces/StoreInterface';

import Community from './Layout/Community';

/**
 * Dashboard component represents the main dashboard page.
 * It displays a grid of widgets and a community section.
 * @component
 */
const Dashboard: React.FC = () => {
  const [widgets, setWidgets] = useState<Widget[]>([]);
  const widgetsStoreInfo = useSelector(
    (state: StoreInterface) => state.widgets
  );
  const widgetData = useSelector((state: StoreInterface) => state.widgetData);

  /**  Update the widgets when the store information changes */
  useEffect(() => {
    setWidgets(widgetsStoreInfo);
  }, [widgetsStoreInfo]);

  /**  Display a loader if widget data is not available yet */
  return widgetData.length === 0 ? (
    <>
      <Loader />
    </>
  ) : (
    <>
      <Box className='dash-container' id='dashhelp'>
        <Grid container spacing={{xs: 2, md: 3}}>
          <Grid item xs={12} sm={10} sx={{margin: '0px'}}>
            <Grid container spacing={{xs: 2, md: 3}}>
              {widgets
                ?.filter((item) => item.pageId === PageId.DASHBOARD)
                .map((item, index) => {
                  return item?.visible ? (
                    <Grid
                      key={index}
                      item
                      sm={item.displayWidthWeb}
                      xs={item.displayWidthMobile}
                    >
                      <Widgets {...item} />
                    </Grid>
                  ) : (
                    <></>
                  );
                })}
            </Grid>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Community />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Dashboard;
