import {Box, Grid, Typography} from '@mui/material';
import TooltipMessage from 'Components/Shared/Constants/TooltipMessages';
import {Kpi, Kpis} from 'Components/Shared/Interfaces/Kpis';
import Loader from 'Components/Shared/Loader';
import Tooltip from 'Components/Shared/Tooltip';
import React from 'react';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import StoreInterface from 'State/Interfaces/StoreInterface';

import ScatterQuadrant from '../Charts/ScatterQuadrant';

import ProgressTabs from './Layouts/ProgressTabs';
import Sustainability from './Layouts/Sustainability';
import getKPIData from './Utils/getKPIData';

/**
 * Progress Component shows all the kpi and its initiatives.
 * It takes user data and show analytics on basis of that
 * @component
 */
const Progress: React.FC = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const [data, setData] = useState<Kpi[]>([]);

  const selectedKpis = useSelector((state: StoreInterface) => state.kpiValues);

  useEffect(() => {
    getKPIData(dispatch, selectedKpis).then((res) => {
      if (res) {
        setData(res as Kpi[]);
      }
    });
  }, []);

  return data?.length === 0 ? (
    <Loader />
  ) : (
    <Box className='progress-container'>
      <Grid container sx={{margin: '0px'}}>
        <Grid container spacing={{xs: 2, md: 3}} >
          <Grid item xs={12} sm={7} id='yp-first'>
            <ProgressTabs kpis={data} />
          </Grid>
          <Grid item xs={12} sm={5} id='yp-second'>
            <Box className='card-title head-i-icon'>
              <Typography>{t('SUSTAINIBILITY_SCORE')}</Typography>
              <Tooltip message={TooltipMessage?.PROGRESS_SUSTANIBILITY_SCORE} />
            </Box>
            <Box className='card-data speedometer'>
              <Sustainability />
            </Box>
          </Grid>
        </Grid>
        <Grid container className='impactCard' id='yp-third'>
          <Grid item xs={12}>
            <Box className='dash-cards'>
              <Box className='card-title'>
                <Typography>{t('IMPACT_PROGRESS')}</Typography>
                <Tooltip message={TooltipMessage?.PROGRESS_KPI} />
              </Box>
              <Box className='card-data flex'>
                <Box className='impact-listing'>
                  {selectedKpis
                    .map((value: Kpis, index: number) => {
                      return (
                        <Box
                          key={index}
                          className={`il-list color-${index + 1}`}
                        >
                          <Box>
                            {`${value.kpiName}    ${
                              value.initiativeValues?.filter(
                                (i) => i.checked === true
                              ).length
                            }`}
                            {/* <svg width='13' height='17' style={{fill: 'white'}}>
                              
                            </svg> */}
                          </Box>
                          <img
                            src={value.icon}
                            style={{fill: 'white'}}
                            alt=''
                          />
                        </Box>
                      );
                    })
                    .reverse()}
                </Box>
                <ScatterQuadrant />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Progress;
