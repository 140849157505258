import {
  Box,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Typography
} from '@mui/material';
import TooltipMessage from 'Components/Shared/Constants/TooltipMessages';
import Tooltip from 'Components/Shared/Tooltip';
import {t} from 'i18next';
import React from 'react';
import {useSelector} from 'react-redux';
import StoreInterface from 'State/Interfaces/StoreInterface';

import Continent from '../Interface/Continent';
import Country from '../Interface/Country';
import MarkerProps from '../Interface/MarkersProps';
import setMarkers from '../Interface/SetMarkers';
import UpdatePosition from '../Interface/UpdatePosition';

const ContinentSelector: React.FC<{
  markerRefs: any[];
  updatePosition: UpdatePosition;
  markers: MarkerProps[];
  setMarkers: setMarkers;
  setLabelContinent: React.Dispatch<React.SetStateAction<string>>;
}> = ({markerRefs, updatePosition, markers, setMarkers, setLabelContinent}) => {
  const storeContinent = useSelector(
    (state: StoreInterface) => state.continent
  );
  const storeCountry = useSelector((state: StoreInterface) => state.country);
  const storeIndustries = useSelector(
    (state: StoreInterface) => state.industryTypes
  );
  const storeProject = useSelector((state: StoreInterface) => state.projects);

  const [continentData, setcontinentData] = React.useState<Continent[]>([]);
  const [countries, setCountries] = React.useState<Country[]>([]);
  const [industry, setIndustry] = React.useState<string[]>([]);
  const [project, setProject] = React.useState<MarkerProps[]>([]);

  const [continentValue, setContinentValue] = React.useState('All');
  const [countryValue, setCountryValue] = React.useState('All');
  const [industryType, setIndustryType] = React.useState('All');

  const handleMarkerClick = (id: number) => {
    markerRefs[id].fire('click');
  };

  React.useEffect(() => {
    setcontinentData(storeContinent);
    setCountries(storeCountry);
    setIndustry(storeIndustries);
    setProject(storeProject);
  }, [storeContinent, storeCountry, storeIndustries, storeProject, setMarkers]);

  React.useEffect(() => {
    setMarkers(
      project
        .filter((i) =>
          continentValue === 'All' ? true : i.continent === continentValue
        )
        .filter((i) =>
          countryValue === 'All' ? true : i.country === countryValue
        )
        .filter((i) =>
          industryType === 'All' ? true : i.industryType === industryType
        )
    );
  }, [continentValue, countryValue, industryType]);

  return (
    <>
      <Box
        className='card-title'
        display={'flex'}
        justifyContent={'space-between'}
        sx={{borderBottom: 'none !important'}}
      >
        <Typography>{t('PROJECTS')}</Typography>
        <Tooltip message={TooltipMessage?.ACTION_UNF_PROJECT} />
      </Box>
      <Box className='card-data' sx={{padding: '0px 15px'}}>
        <FormControl fullWidth>
          <Select value={continentValue} className='bc-override-dropdown'>
            <MenuItem
              onClick={() => {
                updatePosition({lat: 42, lng: 0}, 1.52);
                setContinentValue('All');
                setCountryValue('All');
                setIndustryType('All');
              }}
              key={-1}
              value={'All'}
            >
              All Continents
            </MenuItem>
            {continentData.map((item, index) =>
              item ? (
                <MenuItem
                  onClick={() => {
                    updatePosition({lat: item.lat, lng: item.long}, item.zoom);
                    setLabelContinent(item.name);
                    setContinentValue(item.name);
                  }}
                  key={index}
                  value={item.name}
                >
                  {item?.name}
                </MenuItem>
              ) : null
            )}
          </Select>
        </FormControl>
        {continentValue === 'All' &&
          continentData.map((item, index) => {
            return (
              <Grid
                className={`locationList ${'continent'}`}
                container
                key={index}
              >
                <Grid item xs={1}>
                  {index + 1}
                </Grid>
                <Grid item xs={6}>
                  {item.name}
                </Grid>
                <Grid item xs={5}>
                  {item.projectCount}
                </Grid>
              </Grid>
            );
          })}

        {continentValue !== 'All' && (
          <>
            <Grid container>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <Select value={countryValue} className='bc-override-dropdown'>
                    <MenuItem
                      onClick={() => setCountryValue('All')}
                      key={-1}
                      value={'All'}
                    >
                      All
                    </MenuItem>
                    {countries
                      .filter((item) => item.continent === continentValue)
                      .map((item, index) =>
                        item ? (
                          <MenuItem
                            onClick={() => setCountryValue(item.name)}
                            key={index}
                            value={item.name}
                          >
                            {item?.name}
                          </MenuItem>
                        ) : null
                      )}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <Select value={industryType} className='bc-override-dropdown'>
                    <MenuItem
                      onClick={() => setIndustryType('All')}
                      key={-1}
                      value={'All'}
                    >
                      All
                    </MenuItem>
                    {industry.map((item, index) =>
                      item ? (
                        <MenuItem
                          onClick={() => setIndustryType(item)}
                          key={index}
                          value={item}
                        >
                          {item}
                        </MenuItem>
                      ) : null
                    )}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </>
        )}

        {continentValue !== 'All' &&
          markers.map((item, index) => {
            return (
              <Grid
                className={`locationList ${'continent'}`}
                container
                key={index}
                onClick={() => {
                  handleMarkerClick(index);
                }}
              >
                <Grid item xs={1}>
                  {index + 1}
                </Grid>
                <Grid item xs={6}>
                  {item.title}
                </Grid>
                <Grid item xs={5}>
                  {item.country}
                </Grid>
              </Grid>
            );
          })}
      </Box>
    </>
  );
};

export default ContinentSelector;
