import {Box, Grid, Typography} from '@mui/material';
import RoutesURLs from 'Components/Shared/Constants/Routes';
import React from 'react';
import {useTranslation} from 'react-i18next';
import ReactPlayer from 'react-player';
import {useSelector} from 'react-redux';
import {Link} from 'react-router-dom';

import StoreInterface from '../../State/Interfaces/StoreInterface';

import {Header} from './Constants/Header';
import FootPrintCalculator from './Layout/FootPrintCalculator';
import IndustryBenchmark from './Layout/IndustryBenchmark';

/**
 * GuestDash component represents the guest dashboard page.
 * It displays a video player, banner text, and dashboard tiles.
 * @component
 */
const GuestDash: React.FC = () => {
  const loggedUser = useSelector((state: StoreInterface) => state.user);
  const {t} = useTranslation();

  return (
    <>
      {/* Video Player */}
      <ReactPlayer
        className='videoPlayer'
        width={'100%'}
        height='100%'
        playing={true}
        loop={true}
        muted={true}
        url='https://files-downloadweb.s3.ap-south-1.amazonaws.com/videos/environment-95071.mp4'
      />
      {/* Content Box */}
      <Box className='content-box guest-dashboard' id='splitreporting'>
        {/* Banner Text */}
        <Box className='banner-text'>
          <Typography variant='h1'>{Header.QUOTE}</Typography>
          <Box>{Header.AUTHOR}</Box>
          <Box
          display={loggedUser.isGuest ? '' : 'none'}
          className='text-left updatebtn'
        >
          <Link className='btn ml-auto' to={RoutesURLs.SCHEDULE_CALL}>
          {t('UPGRADE_TO_PRE')}
          </Link>
        </Box>
        </Box>
        
        {/* Dashboard Tiles */}
        <Grid container className='dashboard-tiles' spacing={{xs: 2, md: 3}}>
          {/* FootPrint Calculator */}
          <Grid item md={4} id='try'>
            <FootPrintCalculator />
          </Grid>
          {/* Industry Benchmark */}
          <Grid item md={8} id='knowwhere'>
            <IndustryBenchmark />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default GuestDash;
