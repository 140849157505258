import Loader from 'Components/Shared/Loader';
import React, {useEffect, useState} from 'react';
import {PieChart, Pie, Cell, LabelList, Legend} from 'recharts';

import {SPEEDOMETER_CONSTANTS} from './Constants/Speedometer';
import SpeedometerProps from './Interfaces/Speedometer';
import SpeedometerValueProps from './Interfaces/SpeedometerValueProps';
import SpeedometerLegend from './Layouts/SpeedometerLegend';
import getSpeedometerValues from './Utils/getSpeedometerValues';

const Speedometer: React.FC<SpeedometerProps> = ({value}) => {
  const [sp, setSp] = useState<SpeedometerValueProps>();

  useEffect(() => {
    setSp(getSpeedometerValues(value));
  }, [value]);

  return !sp ? (
    <Loader />
  ) : (
    <PieChart className='pie-chart' width={400} height={600}>
      {/* Render the pie chart */}
      <Pie
        dataKey='value'
        startAngle={180}
        endAngle={0}
        data={SPEEDOMETER_CONSTANTS.data}
        cx={SPEEDOMETER_CONSTANTS.cx}
        cy={SPEEDOMETER_CONSTANTS.cy}
        //innerRadius={SPEEDOMETER_CONSTANTS.iR}
        //outerRadius={SPEEDOMETER_CONSTANTS.oR}
        fill='#8884d8'
        stroke='none'
        paddingAngle={1.5}
        outerRadius='85%'
        innerRadius='75%'
      >
        {/* Render each cell (slice) of the pie */}
        {SPEEDOMETER_CONSTANTS.data.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={entry.color} fillOpacity='1' />
        ))}

        {SPEEDOMETER_CONSTANTS.data.map((entry, index) => (
          <LabelList
            key={index}
            dataKey='label'
            position='outside'
            fill='#5e5c5c'
            offset={8}
            values={entry.name}
            style={{
              fontSize: '12px',
              fontFamily: 'sans-serif'
            }}
          />
        ))}
      </Pie>
      <Legend
        wrapperStyle={{
          height: 30,
          width: '100%',
          display: 'flex',
          justifyContent: 'center'
        }}
        verticalAlign='bottom'
        content={<SpeedometerLegend />}
      />
      <Pie
        dataKey='value'
        startAngle={180}
        endAngle={0}
        data={SPEEDOMETER_CONSTANTS.data}
        cx={SPEEDOMETER_CONSTANTS.cx}
        cy={SPEEDOMETER_CONSTANTS.cy}
        //innerRadius={SPEEDOMETER_CONSTANTS.iR}
        //outerRadius={SPEEDOMETER_CONSTANTS.oR}
        fill='#8884d8'
        stroke='none'
        paddingAngle={1.5}
        outerRadius='80%'
        innerRadius='70%'
      >
        {/* Render each cell (slice) of the pie */}
        {SPEEDOMETER_CONSTANTS.data.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={entry.color} fillOpacity='0.7' />
        ))}
      </Pie>
      {/* Render a circle at the center */}
      <circle
        cx={sp?.x0}
        cy={sp?.y0}
        r={sp?.r}
        fill={SPEEDOMETER_CONSTANTS.color}
        stroke='none'
      />
      {/* Render a path (arrow) indicating the value */}
      <path
        d={`M${sp?.xba || 0} ${sp?.yba || 0}L${sp?.xbb || 0} ${sp?.ybb || 0} L${
          sp?.xp || 0
        } ${sp?.yp || 0} L${sp?.xba || 0} ${sp?.yba || 0}`}
        stroke='none'
        fill={SPEEDOMETER_CONSTANTS.color}
      />
    </PieChart>
  );
};

export default Speedometer;
