import Standard from '../Constants/Standard';

const returnFlagColour: (priority: string) => {
  color: string;
  background: string;
} = (priority: string) => {
  switch (priority) {
    case Standard.HIGH:
      return {color: '#26AF5F', background: '#D0FCE2'};
    case Standard.LOW:
      return {color: '#8C2822', background: '#F9E3E2'};
    case Standard.MEDIUM:
      return {color: '#BE6D0C', background: '#FFE6C9'};
    default:
      return {color: '', background: ''};
  }
};

export default returnFlagColour;
