import ActionChartProps from 'Components/Action/Interface/ActionChartProps';
import TinyChartTooltip from 'Components/Action/Layouts/TinyChartTooltip';
import React from 'react';
import {AreaChart, Area, Tooltip, Label, ReferenceLine} from 'recharts';

const TakeActionTinyChart: React.FC<{data: ActionChartProps[]}> = ({data}) => {
  const colors = ['#F27260', '#CBBB92', '#A9D287'];

  return (
    <AreaChart
      width={500}
      height={296}
      data={data}
      margin={{
        top: 24,
        right: 24,
        left: 24,
        bottom: 24
      }}
    >
      <defs>
        <linearGradient id='colorUv' x1='0%' y1='0%' x2='100%' y2='0%'>
          <stop offset='0%' stopColor='#f27260' />
          <stop offset='50%' stopColor='#d6b496' />
          <stop offset='100%' stopColor='#b2cd8a' />
        </linearGradient>
      </defs>
      <Area
        type='monotone'
        dataKey='emissionState'
        stroke='url(#colorUv)'
        fill='url(#colorUv)'
        opacity={0.8}
        strokeWidth={3}
        activeDot={{fill: '#20E2BD', r: 5, strokeWidth: 3}}
      >
        <Label value='name' />
      </Area>
      <Tooltip
        cursor={false}
        content={({payload}) => <TinyChartTooltip payload={payload} />}
      />
      {data?.map((item, index) => {
        return (
          <ReferenceLine
            key={index}
            stroke='#05406529'
            label={{
              position:
                index === data?.length - 1
                  ? 'insideBottom'
                  : 'insideBottomLeft',
              angle: -90,
              value: `${item.emissionState} ${item.unit}`,
              offset: index === data?.length - 1 ? 50 : 20,
              fill: colors[index]
            }}
            segment={[
              {x: index, y: 0},
              {x: index, y: item.emissionState}
            ]}
            strokeWidth={0.8}
            strokeOpacity={0.5}
          />
        );
      })}
    </AreaChart>
  );
};

export default TakeActionTinyChart;
