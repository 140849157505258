/* eslint-disable max-len */

import RoutesURLs from 'Components/Shared/Constants/Routes';
import {Step} from 'react-joyride';

interface State {
  location: string;
  steps: Step[];
}

interface HelpConstant {
  type: string;
  state: State[];
}

const helpConstants: HelpConstant[] = [
  {
    type: 'guest',
    state: [
      {
        location: RoutesURLs.DASHBOARD,
        steps: [
          {
            target: '#none',
            content: 'This is my super awesome feature!',
            disableBeacon: true
          }
        ]
      }
    ]
  },
  {
    type: 'premium',
    state: [
      {
        location: RoutesURLs.DASHBOARD,
        steps: [
          {
            content:
              'A “snapshot”! Your performance is based on data input till date. Your company`s total emissions by category based on a monthly/yearly percentage total.',
            locale: {skip: 'Skip'},
            placement: 'left',
            target: '#TotalGHGEmissions',
            disableOverlay: false,
            disableBeacon: true
          },
          {
            target: '#GHGEmissionsByScope',
            locale: {
              // next: 'Start',
              skip: 'Skip'
            },
            disableOverlay: false,
            disableBeacon: true,
            content:
              'Break it down! Split for reporting ease. Your company`s total emissions by scope based on a monthly/yearly percentage total.'
          },
          {
            target: '#dash-third',
            locale: {
              next: 'Start',
              skip: 'Skip'
            },
            disableScrolling: false,
            disableOverlay: false,
            disableBeacon: true,
            content:
              'Know where you stand! Measure yourself against the industry average. Your company’s carbon emissions performance compared to industry data.'
          }
        ]
      },
      {
        location: RoutesURLs.FOOTPRINT,
        steps: [
          {
            content:
              'See the trends! Identify what date produced the least/most emissions. Shown is your company’s GHG emissions over time.',
            locale: {skip: 'Skip'},
            placement: 'left',
            target: '#fp-first',
            disableOverlay: false,
            disableBeacon: true
          },
          {
            target: '#fp-second',
            locale: {
              next: 'Next',
              skip: 'Skip'
            },
            disableOverlay: false,
            content:
              'Get Specific! Quickly map your carbon emissions activities according to scope and location.'
          },
          {
            target: '#fp-third',
            locale: {
              next: 'Next',
              skip: 'Skip'
            },
            disableOverlay: false,
            content:
              'Bring it all together! Your total carbon emissions per activity, location, and scope, and benchmark if applicable. Compare and contrast different activities side by side.'
          }
        ]
      },
      {
        location: RoutesURLs.YOUR_PROGRESS,
        steps: [
          {
            content:
              'Keep track of how your initiatives improve over time with an easy progression from needs improvement to prioritizing earth.',
            locale: {skip: 'Skip'},
            placement: 'left',
            target: '#yp-first',
            disableOverlay: false,
            disableBeacon: true
          },
          {
            content:
              'How do you measure up? The goal is Zero Carbon (Net-Zero), and the initiatives provided will help you achieve that. Here you can see how far into the journey your organization is.',

            locale: {skip: 'Skip'},
            placement: 'left',
            target: '#yp-second',
            disableOverlay: false,
            disableBeacon: true
          },
          {
            target: '#yp-third',
            locale: {
              // next: 'Start',
              skip: 'Skip'
            },
            disableScrolling: false,
            disableOverlay: false,
            content:
              'Keep track of how your initiatives improve over time with an easy progression from needs improvement to prioritizing earth.'
          }
        ]
      },
      {
        location: RoutesURLs.ACTION,
        steps: [
          {
            content:
              'Monitor your carbon emissions and witness the impact of your reduction activities on your carbon footprint.',
            locale: {skip: 'Skip'},
            placement: 'left',
            target: '#tc-first',
            disableOverlay: false,
            disableBeacon: true
          },
          {
            content:
              'Keep track of your progress through an interactive graph, showing your journey to achieving net-zero and the remaining distance.',

            locale: {skip: 'Skip'},
            placement: 'left',
            target: '#tc-second',
            disableOverlay: false,
            disableBeacon: true
          },
          {
            target: '#tc-third',
            locale: {
              // next: 'Start',
              skip: 'Skip'
            },
            disableScrolling: false,
            disableOverlay: false,
            content:
              'Explore global projects available under the UNFCCC marketplace to accelerate your footprint reduction by investing in initiatives that align with your company values. Let’s work together towards a greener future!'
          }
        ]
      },
      {
        location: RoutesURLs.SUBSCRIPTION_INFO,
        steps: [
          {
            target: '#none',
            content: 'This is my super awesome feature!',
            disableBeacon: true
          }
        ]
      }
    ]
  }
];

export default helpConstants;
