import Box from '@mui/material/Box';
import LinearProgress, {
  LinearProgressProps
} from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import * as React from 'react';

const LinearProgressWithLabel = (
  props: LinearProgressProps & {value: number}
) => {
  return (
    <Box sx={{display: 'flex', alignItems: 'center'}}>
      <Box sx={{width: '100%', mr: 1}}>
        <LinearProgress
          variant='determinate'
          {...props}
          color='success'
          sx={{backgroundColor: '#dbedda'}}
        />
      </Box>
      <Box sx={{minWidth: 35}}>
        <Typography variant='body2' color='text.secondary'>{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
};

const LinearWithValueLabel: React.FC<{progress: number}> = ({progress}) => {
  return (
    <Box sx={{width: '100%'}}>
      <LinearProgressWithLabel value={progress} />
    </Box>
  );
};

export default LinearWithValueLabel;
