import WidgetType from './Constants/WidgetType';
import Widget from './Interfaces/Widget';
import BarsWidget from './Layouts/BarsWidget';
import CardsWidget from './Layouts/CardsWidget';
import ListsWidget from './Layouts/ListsWidget';
import MultiGraphWidget from './Layouts/MultiGraphWidget';
import TabsWidget from './Layouts/TabsWidget';

/**
 * Widgets Component render the respective widget according to the widget type received.
 * @component
 */
const Widgets: React.FC<Widget> = (item) => {

    switch (item?.type) {
        case WidgetType.TABS:
            return <TabsWidget {...item}  />;
        case WidgetType.MULTIGRAPH:
            return <MultiGraphWidget {...item} />;
        case WidgetType.BARS:
            return <BarsWidget {...item} />;
        case WidgetType.CARDS:
            return <CardsWidget {...item} />;
        case WidgetType.LISTS:
            return <ListsWidget {...item} />;
        default:
            return <></>;
    }
};

export default Widgets;