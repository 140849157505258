/* eslint-disable max-len */
import React from 'react';

import RadialCustomizedLabelProps from '../Interfaces/RadialCustomizedLabelProps';

const RadialCustomizedLabel: React.FC<RadialCustomizedLabelProps> = ({
  viewBox,
  fill,
  value
}) => {
  const RADIAN = Math.PI / 180;
  const radius =
    viewBox.innerRadius + (viewBox.outerRadius - viewBox.innerRadius) * 0.5;
  const x = -6 + viewBox.cx + radius * Math.cos(-viewBox.endAngle * RADIAN);
  const y = 10 + viewBox.cy + radius * Math.sin(-viewBox.endAngle * RADIAN);

  return (
    <>
      <text x={x} y={y} fontSize={16} fill={fill} textAnchor='end'>
        {value}%
      </text>
      ;
    </>
  );
};

export default RadialCustomizedLabel;
