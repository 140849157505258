/* eslint-disable no-console */

import ActionChartProps from '../Interface/ActionChartProps';
import ActionDataInterface from '../Interface/ActionData';

const getActionChart: (
  actionData: ActionDataInterface,
  TotalCarbonEmission: {
    total: number;
    unit: string;
  }
) => ActionChartProps[] = (actionData, TotalCarbonEmission) => {
  const mainData: ActionChartProps[] = [];

  mainData[0] = {
    emissionState: TotalCarbonEmission.total,
    unit: TotalCarbonEmission.unit.split(' ')[1],
    emissionReduced: TotalCarbonEmission.total
  };

  const initiatorCount = 0;

  const totalIntiator = actionData?.initiators?.reduce(
    (acc, {emissions}) => acc + emissions || 0,
    initiatorCount
  );

  const totalReduction = totalIntiator + (actionData?.creditPurchased || 0);

  mainData.push(
    {
      emissionState: TotalCarbonEmission.total - totalReduction,
      percentReduced:
        ((TotalCarbonEmission.total - totalReduction) /
          TotalCarbonEmission.total) *
        100,
      emissionReduced: totalReduction,
      unit: TotalCarbonEmission.unit.split(' ')[1]
    },
    {
      emissionState: 0,
      unit: TotalCarbonEmission.unit.split(' ')[1]
    }
  );

  return mainData;
};

export default getActionChart;
