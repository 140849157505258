import getBenchMark from 'Services/BenchMark';

import BenchMark from '../Interfaces/BenchMark';

/**
 * Retrieves the benchmark data asynchronously.
 *
 * @returns A Promise that resolves to an array of benchmark data or undefined if an error occurs.
 */
export const getBenchMarkData = async (): Promise<BenchMark[] | undefined> => {
  try {
    const res = await getBenchMark();
    
return res;
  } catch (error) {
    console.error('Error fetching benchmark data:', error);
    
return undefined;
  }
};
