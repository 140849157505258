import {
  Box,
  FormControl,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent
} from '@mui/material';
import {Locations} from 'Components/Footprint/Interfaces/FootPrintData';
import LinearLoader from 'Components/Shared/LinearLoader';
import MultiSelect from 'Components/Shared/MultiSelect';
import filterBenchmarksByIndustry from 'Components/Widgets/Utils/filterBenchmarksByIndustry';
import getLocationsGroup from 'Components/Widgets/Utils/getLocationsGroup';
import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import StoreInterface from 'State/Interfaces/StoreInterface';

import ListWidgetData from './Interfaces/ListWidgetData';
import RenderListProps from './Interfaces/RenderListProps';
import RenderList from './Layouts/RenderList';
import getFilterData from './Utils/getFilterData';

const ListItems: React.FC<{data: ListWidgetData[]}> = ({data}) => {
  const category = useSelector(
    (state: StoreInterface) => state.company.category
  );
  const locations = useSelector((state: StoreInterface) => state.locations);

  const industryBenchmark = useSelector(
    (state: StoreInterface) => state.widgetConfig?.industryBenchmark
  );
  const filteredindustryBenchmark = industryBenchmark.map((item) => {
    return filterBenchmarksByIndustry(category, item);
  });

  const [selectedLocation, setSelectedLocations] =
    useState<Locations[]>(locations);
  const [scopeValue, setScopeValue] = useState<string>(
    industryBenchmark[0]?.id
  );
  const [benchmark, setBenchmark] = useState<string | undefined>('0');
  const [renderData, setRenderData] = useState<RenderListProps[]>([]);

  const handleScopeChange = (event: SelectChangeEvent) => {
    setScopeValue(event.target.value);
  };

  const handleBenchmarkChange = (event: SelectChangeEvent) => {
    setBenchmark(event.target.value);
  };

  useEffect(() => {
    setRenderData(getFilterData(data, scopeValue, selectedLocation));
  }, [data, scopeValue, selectedLocation]);

  useEffect(() => {
    setSelectedLocations(locations);
  }, [locations]);

  return !locations ? (
    <LinearLoader />
  ) : (
    <Box>
      <Grid container className='flex'>
        <Grid item xs={4}>
          <FormControl fullWidth>
            <Select
              onChange={handleScopeChange}
              value={scopeValue}
              className='bc-override-dropdown'
            >
              {filteredindustryBenchmark?.map((item, index: number) =>
                item ? (
                  <MenuItem key={index} value={item?.id}>
                    {item?.name}
                  </MenuItem>
                ) : null
              )}
            </Select>
          </FormControl>
        </Grid>
        <Grid
          item
          xs={4}
          textAlign='center'
          className='bc-override-multiselect'
        >
          <MultiSelect
            selectedValues={selectedLocation}
            setValues={setSelectedLocations}
            options={getLocationsGroup(locations)}
          />
        </Grid>
        <Grid item xs={4}>
          <FormControl fullWidth>
            <Select
              value={benchmark}
              onChange={handleBenchmarkChange}
              className='bc-override-dropdown'
            >
              <MenuItem value={'0'}>All</MenuItem>
              <MenuItem value={'1'}>Above Benchmark</MenuItem>
              <MenuItem value={'-1'}>Below Benchmark</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Grid container className='listBox'>
        <RenderList data={renderData} benchmark={benchmark} />
      </Grid>
    </Box>
  );
};

export default ListItems;
