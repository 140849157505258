/* eslint-disable no-console */
import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import CommunityProps from 'Components/Dashboard/Interfaces/CommunityProp';

import CommunityDataState from '../../../Components/Widgets/Constants/CommunityConstant';

/**
 * Redux slice for the widget Data store.
 */
const createCommunityStore = createSlice({
  name: 'communityStore',
  initialState: CommunityDataState,

  reducers: {
    community: (state, action: PayloadAction<CommunityProps[]>) => {
      action?.payload?.forEach((i) => {
        state.push(i);
      });
    }
  }
});

export default createCommunityStore;
