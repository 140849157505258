import calculateTotal from 'Components/Dashboard/Utils/calulateTotal';
import FootprintInProgress from 'Components/Shared/FootprintInProgress';
import {useSelector} from 'react-redux';
import StoreInterface from 'State/Interfaces/StoreInterface';

import BarChart from '../../Charts/BarChart';
import ComposedBarChart from '../../Charts/ComposedBarChart';
import ListItems from '../../Charts/ListItems';
import PieChartScope from '../../Charts/PieChartScope';
import PieDiagram from '../../Charts/PieDiagram';
import RadialChart from '../../Charts/RadialChart';
import SimpleCard from '../../Charts/SimpleCard';
import ChartType from '../Constants/ChartType';
import WidgetType from '../Constants/WidgetType';
import ReturnChartComponentProps from '../Interfaces/ReturnChartComponentProps';
import WidgetData from '../Interfaces/WidgetData';
import buildChartData from '../Utils/buildChartData';
import checkFootprinting from '../Utils/checkFootprinting';
import getChartConfiguration from '../Utils/getChartConfiguration';

import TotalCarbonBanner from './TotalCarbonBanner';

/**
 * ReturnChartComponent returns the chart based on the type of chart type.
 * @component
 */
const ReturnChartComponent: React.FC<ReturnChartComponentProps> = ({
  year,
  item,
  widget,
  locationId
}) => {
  const widgetConfig = useSelector(
    (state: StoreInterface) => state.widgetConfig
  );

  const widgetData = useSelector(
    (state: StoreInterface) => state.widgetData
  ).find((i) => i.year === year) as WidgetData;

  const footPrintData = useSelector(
    (state: StoreInterface) => state.footprintData
  );

  const chartConfig = getChartConfiguration(item, widgetConfig);

  const chartData = buildChartData({
    item,
    chartConfig,
    widgetData,
    year,
    widget,
    footPrintData,
    locationId
  });

  const notFootprinting = checkFootprinting(
    widgetData?.emissionsData.scopeList
  );

  if (!Array.isArray(item)) {
    switch (item?.chartType) {
      case ChartType.RADIAL_BAR_CHART:
        return !notFootprinting ? (
          <FootprintInProgress />
        ) : (
          <>
            <TotalCarbonBanner TotalandUnit={calculateTotal(chartData)} />
            <RadialChart data={chartData} />
          </>
        );
      case ChartType.PIE_CHART_WITH_CUSTOM_LABEL:
        return !notFootprinting ? (
          <FootprintInProgress />
        ) : (
          <>
            <TotalCarbonBanner TotalandUnit={calculateTotal(chartData)} />
            <PieDiagram data={chartData} />
          </>
        );
      case ChartType.PIE_CHART_WITH_PADDING_ANGLE:
        return !notFootprinting ? (
          <FootprintInProgress />
        ) : (
          <PieChartScope data={chartData} />
        );
      case ChartType.COMPOSED_BAR_CHART:
        return !notFootprinting ? (
          <FootprintInProgress />
        ) : (
          <ComposedBarChart data={chartData} />
        );
      default:
        return <></>;
    }
  } else {
    switch (widget?.type) {
      case WidgetType.BARS:
        return !notFootprinting ? (
          <FootprintInProgress />
        ) : (
          <BarChart data={chartData} />
        );
      case WidgetType.CARDS:
        return !notFootprinting ? (
          <FootprintInProgress />
        ) : (
          <SimpleCard data={chartData} />
        );
      case WidgetType.LISTS:
        return !notFootprinting ? (
          <FootprintInProgress />
        ) : (
          <ListItems data={chartData} />
        );
      default:
        return <></>;
    }
  }
};

export default ReturnChartComponent;
