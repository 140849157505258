import FootPrintData from 'Components/Footprint/Interfaces/FootPrintData';

import ChartType from '../Constants/ChartType';
import WidgetType from '../Constants/WidgetType';
import BuildChartDataProps from '../Interfaces/BuildChartDataProps';

import getBarChartData from './getBarChartData';
import getCardsData from './getCardsData';
import getComposedBarChartData from './getComposedBarChartData';
import getListWidgetData from './getListWidgetData';
import getPieChartData from './getPieChartData';
import getPieChartScopeData from './getPieChartScopeData';
import getRadialChartData from './getRadialChartData';

/**
 * Builds the chart data based on the provided configuration and widget data.
 *
 * @param item - The chart configuration item or an array of chart configuration items.
 * @param chartConfig - The chart configuration.
 * @param widgetData - The data for the widget.
 * @param year - The selected year.
 * @param widget - The widget for which data is to be built.
 * @param footPrintData - The footprint data.
 * @param locationId - The selected location ID.
 * @returns The built chart data.
 */
const buildChartData = ({
  item,
  chartConfig,
  widgetData,
  year,
  widget,
  footPrintData,
  locationId
}: BuildChartDataProps): any => {
  if (!Array.isArray(item)) {
    const commonData = {item, chartConfig, widgetData, year};
    switch (item?.chartType) {
      case ChartType.RADIAL_BAR_CHART:
        return getRadialChartData({...commonData});
      case ChartType.PIE_CHART_WITH_CUSTOM_LABEL:
        return getPieChartData({...commonData});
      case ChartType.PIE_CHART_WITH_PADDING_ANGLE:
        return getPieChartScopeData({...commonData});
      case ChartType.COMPOSED_BAR_CHART:
        return getComposedBarChartData({...commonData});
      default:
        return [];
    }
  } else {
    switch (widget?.type) {
      case WidgetType.BARS:
        return getBarChartData(item, chartConfig, widgetData, year);
      case WidgetType.CARDS:
        return getCardsData(
          item,
          chartConfig,
          year,
          footPrintData as FootPrintData,
          locationId as string
        );
      case WidgetType.LISTS:
        return getListWidgetData(year, footPrintData as FootPrintData);
      default:
        return [];
    }
  }
};

export default buildChartData;
