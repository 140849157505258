import ComposedChartProps from '../Interfaces/ComposedChartProps';
import getChartDataProps from '../Interfaces/getChartDataProps';

/**
 * Retrieves the composed bar chart data based on the provided item, chart configuration, widget data, and year.
 *
 * @param item - The item configuration.
 * @param chartConfig - The chart configuration.
 * @param widgetData - The widget data.
 * @param year - The year.
 * @returns The composed bar chart data.
 */
const getComposedBarChartData = ({
  item,
  chartConfig,
  widgetData,
  year
}: getChartDataProps): ComposedChartProps => {
  let arrayData: any[] = [];
  arrayData = (widgetData?.emissionsData as any)[item?.dataType];

  const composedChartData: ComposedChartProps = {
    name: '',
    benchmark: 0,
    value: 0,
    unit: '',
    year: '',
    fill: ''
  };

  composedChartData.name = chartConfig[0]?.name;
  composedChartData.fill = chartConfig[0]?.color;
  composedChartData.unit = arrayData.find(
    (o: {dataId: string}) => o.dataId === item.dataId
  )?.unit;
  composedChartData.value = arrayData.find(
    (o: {dataId: string}) => o.dataId === item.dataId
  )?.emissionIntensity;
  composedChartData.benchmark = arrayData.find(
    (o: {dataId: string}) => o.dataId === item.dataId
  )?.benchmark;
  composedChartData.year = year as string;

  return composedChartData;
};

export default getComposedBarChartData;
