/* eslint-disable no-console */
import {Box, Button} from '@mui/material';
import Calendly from 'Components/ScheduleCall/Constants/Calendly';
import CalendlyPopUp from 'Components/Shared/CalendlyPopUp';
import React, {useState} from 'react';
import {Marker, Popup, useMap} from 'react-leaflet';

import icon from '../Constants/Icon';
import MarkersProps from '../Interface/MarkersProps';

const Markers: React.FC<{data: MarkersProps[]; markerRefs: any[]}> = ({
  data,
  markerRefs
}) => {
  const [openCalendly, setOpenCalendly] = useState(false);
  const map = useMap();

  return (
    <>
      {data.map((marker, index) => {
        return (
          <Marker
            ref={(ref) => markerRefs.push(ref)}
            eventHandlers={{
              click: () => {
                map.setView(
                  [Number(marker.latitude), Number(marker.longitude)],
                  3
                );
              },
              popupclose: () => {
                map.setView([42, 0], 1.52);
              }
            }}
            key={index}
            position={{
              lat: Number(marker.latitude),
              lng: Number(marker.longitude)
            }}
            icon={icon}
          >
            <Popup>
              <Box className='mapMarker'>
                <Box className='mm-title'>{marker.title}</Box>
                <Box>
                  <img src={marker.posterImage} alt='' />
                </Box>
                <Box className='mm-emm'>{marker.emissions}</Box>
                <Box className='mm-listing'>
                  <Box className='mm-list'>
                    <Box>Industry</Box>
                    <Box>{marker.industryType}</Box>
                  </Box>
                  <Box className='mm-list'>
                    <Box>Continent</Box>
                    <Box>{marker.continent}</Box>
                  </Box>
                  <Box className='mm-list'>
                    <Box>Country</Box>
                    <Box>{marker.country}</Box>
                  </Box>
                  <Box className='mm-list'>
                    <Box>Vintage</Box>
                    <Box>{marker.vintage}</Box>
                  </Box>
                  <Box className='mm-list mm-break'>
                    <Box>Benefits</Box>
                    <Box>{marker.benefits}</Box>
                  </Box>
                </Box>

                <Box>
                  <Button
                    onClick={() => setOpenCalendly(true)}
                    className='btn btn-primary'
                  >
                    BUY THIS CREDIT
                  </Button>
                </Box>
                <CalendlyPopUp
                  open={openCalendly}
                  setOpen={setOpenCalendly}
                  meetingType={Calendly.PAYMENT_QUERY}
                />
              </Box>
            </Popup>
          </Marker>
        );
      })}
    </>
  );
};

export default Markers;
