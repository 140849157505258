import {BarChartProps} from '@charts/Interfaces/BarChartProps';
import {ToggleButton, ToggleButtonGroup} from '@mui/material';
import React, {useEffect} from 'react';

const BarCustomLegend: React.FC<{
    data: BarChartProps[];
    filterArray: React.Dispatch<React.SetStateAction<BarChartProps[]>>
}> = ({data, filterArray}) => {
    const uniqueDataIds = Array.from(new Set(data.map((item: { dataId: string; }) => item.dataId)));
    const [formats, setFormats] = React.useState<string[]>(uniqueDataIds);

    const handleFormat = (event: React.MouseEvent<HTMLElement>, newFormats: string[]) => { 
        if (newFormats.length > 0) {
            setFormats(newFormats);
        }
    };
    useEffect(() => {
        const filteredData = data.filter(item => formats?.includes(item.dataId));
        filterArray(filteredData);
    }, [formats]);

    useEffect(() => {
        const newUniqueDataIds = Array.from(new Set(data.map((item: { dataId: string; }) => item.dataId)));
        setFormats(newUniqueDataIds);
    }, [data]);

    return (
        <ToggleButtonGroup value={formats} onChange={handleFormat}>
            {data.map((entry: BarChartProps, index: number) => {
                return (
                    <ToggleButton
                        key={index}
                        value={entry.dataId}
                        disableRipple
                        disableFocusRipple
                        sx={{
                            '&.Mui-selected': {backgroundColor: 'transparent', opacity: '1'},
                            '&:focus': {backgroundColor: 'transparent'},
                            border: 'none !important',
                            color: '#677489',
                            opacity: '0.5'
                        }}
                    >
                        <li
                            style={{
                                display: 'flex',
                                marginRight: '10px',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}
                        >
                            <span
                                style={{
                                    width: '10px',
                                    height: '10px',
                                    backgroundColor: `${entry?.fill}`,
                                    borderRadius: '50%',
                                    marginRight: '5px'
                                }}
                            ></span>
                            <span className="recharts-legend-item-text">{entry?.name}</span>
                        </li>
                    </ToggleButton>
                );
            })}
        </ToggleButtonGroup>
    );
};

export default BarCustomLegend;
