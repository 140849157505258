import ActionChart from 'Services/ActionChart';

const getActionData = async (): Promise<any> => {
  const companyId = window?.bharatCarbon?.company?._id;
  try {
    const res = await ActionChart(companyId);
    if (res) {
      return res;
    }
  } catch (error) {
    console.error('Error fetching:', error);
  }
};

export default getActionData;
