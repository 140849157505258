import FootPrintData from 'Components/Footprint/Interfaces/FootPrintData';

import ListWidgetData from '../../Charts/Interfaces/ListWidgetData';

const getListWidgetData: (year: string, footPrintData: FootPrintData) => ListWidgetData[] = (year, footPrintData) => {
    const arrayData: ListWidgetData[] = footPrintData?.emissionsData[year];

    if(arrayData){
        return arrayData;
    }

    return [];
};

export default getListWidgetData;