import {Box, Typography} from '@mui/material';
import React from 'react';

import icon from '../../../Assets/Group 19633@2x.png';
import {Initiator} from '../Interface/ActionData';

const Initiators: React.FC<{initiator: Initiator}> = ({initiator}) => {
  return (
    <Box
      sx={{
        width: '275px',
        height: '70px',
        background: '#D0FCE2 0% 0% no-repeat padding-box',
        borderRadius: '8px',
        margin: '5px 0px 5px 0px',
        padding: '10px'
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          color: '#26AF5F'
        }}
      >
        <img src={icon} alt='new' style={{width: '43px', height: '43px'}} />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            letterSpacing: '0px'
          }}
        >
          <Typography
            sx={{
              font: 'normal normal 600 12px/15px Quicksand',
              textTransform: 'uppercase'
            }}
          >
            {initiator.name}
          </Typography>
          <Typography
            sx={{
              font: 'normal normal 600 16px/20px Quicksand',
              textTransform: 'uppercase'
            }}
          >
            {`${initiator.emissions} ${initiator.emissionsUnit}`}
          </Typography>
        </Box>
        <Typography sx={{font: 'normal normal normal 32px/40px Quicksand'}}>
          {`${initiator.percentage} %`}
        </Typography>
      </Box>
    </Box>
  );
};

export default Initiators;
