import React from 'react';

import CustomizedLabel from '../Interfaces/CustomizedLabel';

const RenderCustomizedLabel: React.FC<CustomizedLabel> = ({
	cx,
	cy,
	midAngle,
	innerRadius,
	outerRadius,
	percent
}) => {
	const RADIAN = Math.PI / 180;
	const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
	const x = cx + radius * Math.cos(-midAngle * RADIAN);
	const y = cy + radius * Math.sin(-midAngle * RADIAN);

	return (
		<text x={x} y={y} fill="#000" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
			{`${(percent * 100).toFixed(0)}%`}
		</text>
	);
};

export default RenderCustomizedLabel;
