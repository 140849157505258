import {SPEEDOMETER_CONSTANTS} from '../Constants/Speedometer';
import SpeedometerValueProps from '../Interfaces/SpeedometerValueProps';

/**
 * Calculates the values for the speedometer based on the provided input value.
 *
 * @param value - The input value for the speedometer.
 * @returns The speedometer values calculated based on the input value.
 */
const getSpeedometerValues = (value: number): SpeedometerValueProps => {
  const RADIAN = Math.PI / 180;
  const total = 100;

  const ang = 180.0 * (1 - (value / total));
  const length = (SPEEDOMETER_CONSTANTS.iR + 2 * SPEEDOMETER_CONSTANTS.oR) / 3;
  const sin = Math.sin(-RADIAN * ang);
  const cos = Math.cos(-RADIAN * ang);
  const r = 5;
  const x0 = SPEEDOMETER_CONSTANTS.cx + 5;
  const y0 = SPEEDOMETER_CONSTANTS.cy + 5;
  const xba = x0 + r * sin;
  const yba = y0 - r * cos;
  const xbb = x0 - r * sin;
  const ybb = y0 + r * cos;
  const xp = x0 + length * cos;
  const yp = y0 + length * sin;

  return {total, r, x0, y0, xba, yba, xbb, ybb, xp, yp};
};

export default getSpeedometerValues;
