import ProjectService from 'Services/Projects';

const getAllProjects = async (): Promise<any> => {
  try {
    const res = await ProjectService();
    if (res) {
      
      return res;
    }
  } catch (error) {
    console.error('Error fetching continents:', error);
  }
};

export default getAllProjects;
