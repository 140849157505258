import {createSlice, PayloadAction} from '@reduxjs/toolkit';

/**
 * Redux slice for the progress page store.
 */
const createIndustryTypeStore = createSlice({
  name: 'industryTypes',
  initialState: [] as String[],
  reducers: {
    /**
     * Updates the values in the state for a industry types.
     * @param {ProgressState} state - The current state.
     * @param {PayloadAction<String[]>} action - The action containing the industryTypes payload.
     */
    setIndustryTypes: (state, action: PayloadAction<String[]>) => {
        action?.payload?.forEach(i => {
            state.push(i);
        });
    }
  }
});

// Export the store
export default createIndustryTypeStore;
