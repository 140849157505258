import {createBrowserHistory} from 'history';

/**
 * Creates a browser history instance.
 * This history object can be used to navigate and manipulate the browser history.
 * Read more about history package: https://www.npmjs.com/package/history
 */
const browserHistory = createBrowserHistory();

export default browserHistory;
