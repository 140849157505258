const Calendly = {
  MEETING_URL: 'https://calendly.com/bharatcarbon/',
  PAGE_SETTING_BG_COLOR: 'ffffff',
  PAGE_SETTING_HIDE_EVENT: false,
  PAGE_SETTING_HIDE_DETAILS: false,
  PAGE_SETTING_PRIMARY_COLOR: '20e2bd',
  PAGE_SETTING_TEXT_COLOR: '4d5055',
  PAYMENT_QUERY: 'paymentquery',
  OFFSET_QUERY: 'offsetquery'
};

export default Calendly;
