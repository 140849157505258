import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import InfoIcon from '@mui/icons-material/Info';
import Slide from '@mui/material/Slide';
import Snackbar from '@mui/material/Snackbar';
import Stack from '@mui/material/Stack';
import * as React from 'react';

import Alert from './Alert';
import SnackbarStatus from './Constants/SnackbarStatus';
import SnackbarProps from './Interfaces/SnackbarProps';

/**
 * Snackbar component
 * @component
 */
const Snackbars: React.FC<SnackbarProps> = ({open, setOpen}) => {
	const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
		if (reason === 'clickaway') {
			return;
		}
		setOpen({...open, open: false});
	};

	return (
		<Stack spacing={2} sx={{width: '100%', position: 'absolute'}}>
			<Snackbar
				open={open?.open}
				autoHideDuration={5000}
				anchorOrigin={{vertical: 'top', horizontal: 'center'}}
				TransitionComponent={Slide}
				onClose={() => {
					handleClose();
				}}
			>
				<Alert
					icon={
						open?.status === SnackbarStatus.SUCCESS ? (
							<CheckIcon fontSize="inherit" />
						) : open?.status === SnackbarStatus.ERROR ? (
							<CloseIcon fontSize="inherit" />
						) : open?.status === SnackbarStatus.WARNING ? (
							<ErrorOutlineIcon fontSize="inherit" />
						) : (
							<InfoIcon fontSize="inherit" />
						)
					}
					severity={open?.status || SnackbarStatus.INFO}
					sx={{width: '100%'}}
				>
					{open?.message}
				</Alert>
			</Snackbar>
		</Stack>
	);
};

export default Snackbars;