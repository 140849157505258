import {Box} from '@mui/material';
import {options} from 'Components/Shared/Constants/FormBuilder';
import FormBuilder from 'Components/Shared/FormBuilder';
import React from 'react';

const Principle6: React.FC = () => {
  const companyId = window?.bharatCarbon?.company?._id;
  const handleSubmit = (data: any) => {
    data.companyId = companyId;
    // eslint-disable-next-line no-console
    console.log(data);
  };

  const {Sr_no} = options;

  return (
    <Box sx={{padding: '30px 24px 24px 24px'}}>
      <FormBuilder
        fields={[
          {type: 'div', label: 'ESSENTIAL INDICATORS', key: ''},
          {
            type: 'table',
            label: `Details of total energy consumption (in Joules
                 or multiples) and energy intensity:`,
            key: 'totalEnergyConsumption',
            static: [
              'Total electricity consumption (A)',
              'Total fuel consumption (B)',
              'Energy consumption through other sources (C)',
              'Total energy consumption (A+B+C)',
              'Energy intensity per ` million of turnover'
            ],
            options: [
              {
                type: 'box',
                label: 'Parameters',
                key: 'Parameters'
              },
              {
                type: 'textarea',
                label: 'For Current FY',
                key: 'consumptionsCurrent'
              },
              {
                type: 'textarea',
                label: 'For Last FY',
                key: 'consumptionsLast'
              }
            ]
          },
          {
            type: 'textarea',
            label: `Does the Company have any sites / facilities identified as designated consumers (DCs) under the
            Performance, Achieve and Trade (PAT) Scheme of the Government of India? (Y/N) If yes, disclose
            whether targets set under the PAT scheme have been achieved. In case targets have not been
            achieved, provide the remedial action taken, if any.`,
            key: 'targets'
          },
          {
            type: 'textarea',
            label: 'Details of disclosures related to water.',
            key: 'waterDisclosures'
          },
          {
            type: 'textarea',
            label: `Has the Company implemented a mechanism for Zero Liquid Discharge? If yes, provide details of its
            coverage and implementation.`,
            key: 'zeroLiquidDischarge'
          },
          {
            type: 'textarea',
            label:
              'Details of air emissions (other than GHG emissions) by the Company.',
            key: 'airEmissions'
          },
          {
            type: 'table',
            label: `Details of greenhouse gas emissions (Scope 1 and Scope 2 emissions) and its intensity, in the
              following format:`,
            key: 'greenhouseGas',
            static: [
              `Total Scope 1 emissions (Break-up of the GHG into
                CO2, CH4, N2O, HFCs, PFCs, SF6, NF3, if available)`,
              `Total Scope 2 emissions (Break-up of the GHG into
                CO2, CH4, N2O, HFCs, PFCs, SF6, NF3, if available)`,
              `Total Scope 1 and Scope 2 emissions per rupee of
                turnover`
            ],
            options: [
              {
                type: 'box',
                label: 'Parameters',
                key: 'parameters'
              },
              {
                type: 'textarea',
                label: 'Unit',
                key: 'units'
              },
              {
                type: 'input',
                label: 'Current FY',
                key: 'current',
                inputType: 'number'
              },
              {
                type: 'input',
                label: 'Last FY',
                key: 'last',
                inputType: 'number'
              }
            ]
          },
          {
            type: 'textarea',
            label: `Does the Company have any project related to reducing Green House Gas emission? If Yes, then
            provide details.`,
            key: 'projectReducing'
          },
          {
            type: 'table',
            label:
              'Provide details related to waste management by the Company, in the following format',
            key: 'wasteManagement',
            static: [
              'Total Waste generated (in metric tonnes)',
              'Plastic waste (A)',
              'E-waste (B)',
              'Bio-medical waste (C)',
              'Construction and demolition waste (D)',
              'Battery waste (E)',
              'Radioactive waste (F)',
              'Other Hazardous waste. Please specify, if any. (G)',
              'Other Non-hazardous waste generated (H).',
              'Total (A+B + C + D + E + F + G + H)'
            ],
            options: [
              {
                type: 'box',
                label: 'Parameters',
                key: 'Parameters'
              },
              {
                type: 'input',
                label: 'Current FY',
                key: 'current',
                inputType: 'number'
              },
              {
                type: 'input',
                label: 'Last FY',
                key: 'last',
                inputType: 'number'
              }
            ]
          },
          {
            type: 'table',
            label: 'Category of waste',
            key: 'categoryOfWaste',
            static: [
              'Recycled',
              'Re-used',
              'Other recovery operations',
              'Incineration',
              'Landfilling',
              'Other disposal operations',
              'Total'
            ],
            options: [
              {
                type: 'box',
                label: 'Parameters',
                key: 'Parameters'
              },
              {
                type: 'input',
                label: 'Dry waste for Current FY',
                key: 'current',
                inputType: 'number'
              },
              {
                type: 'input',
                label: 'Wet waste for Last FY',
                key: 'last',
                inputType: 'number'
              }
            ]
          },
          {
            type: 'textarea',
            label: `Briefly describe the waste management practices adopted in your establishments. Describe the
      strategy adopted by your Company to reduce usage of hazardous and toxic chemicals in your
      products and processes and the practices adopted to manage such wastes.`,
            key: 'processesPractices'
          },
          {
            type: 'textarea',
            label: `If the Company has operations/offices in/around 
            ecologically sensitive areas (such as national parks,
                wildlife sanctuaries, biosphere reserves, wetlands, biodiversity hotspots, forests, coastal regulation
                zones etc.) where environmental approvals / clearances are required, please specify details in the
                following format.`,
            key: 'sanctuariesPractices'
          },
          {
            type: 'textarea',
            label: `Details of environmental impact assessments of projects undertaken by the Company based on
            applicable laws, in the current financial year.`,
            key: 'environmentalPractices'
          },
          {
            type: 'textarea',
            label: `Is the Company compliant with the applicable environmental law/ regulations/ guidelines in India,
            such as the Water (Prevention and Control of Pollution) Act, Air (Prevention and Control of Pollution)
            Act, Environment protection act and rules 
            thereunder (Y/N). If not, provide details of all such noncompliances,
            in the following format.`,
            key: 'environmentalPractices'
          },
          {
            type: 'div',
            label: 'LEADERSHIP INDICATORS',
            key: ''
          },
          {
            type: 'table',
            label: `Provide break-up of the total energy consumed 
            (in Joules or multiples) from renewable
            sources, in the following format`,
            key: 'renewableSources',
            static: [
              'Total electricity consumption (A)',
              'Total fuel consumption (B)',
              'Energy consumption through other sources (C)',
              `Total energy consumed from renewable sources
              (A+B+C)`
            ],
            options: [
              {
                type: 'box',
                label: 'Parameters',
                key: 'Parameters'
              },
              {
                type: 'input',
                label: 'Current FY',
                key: 'current',
                inputType: 'number'
              },
              {
                type: 'input',
                label: 'Last FY',
                key: 'last',
                inputType: 'number'
              }
            ]
          },
          {
            type: 'table',
            label: `Provide break-up of the total energy consumed 
            (in Joules or multiples) from non-renewable
            sources, in the following format`,
            key: 'nonRenewableSources',
            static: [
              'Total electricity consumption (D)',
              'Total fuel consumption (E)',
              'Energy consumption through other sources (F)',
              `Total energy consumed from renewable sources
              (D+E+F)`
            ],
            options: [
              {
                type: 'box',
                label: 'Parameters',
                key: 'Parameters'
              },
              {
                type: 'input',
                label: 'Current FY',
                key: 'current',
                inputType: 'number'
              },
              {
                type: 'input',
                label: 'Last FY',
                key: 'last',
                inputType: 'number'
              }
            ]
          },
          {
            type: 'textarea',
            label: 'Details related to water discharged.',
            key: 'detailsWaterDischarged.'
          },
          {
            type: 'table',
            label: `Please provide details of total Scope 3 
            emissions and its intensity, in the following format`,
            key: 'scope3',
            static: [
              `Total Scope 3 emissions (Break-up of the GHG into
                CO2, CH4, N2O, HFCs, PFCs, SF6, NF3, if available)`,
              'Total Scope 3 emissions per rupee of turnover'
            ],
            options: [
              {
                type: 'box',
                label: 'Parameters',
                key: 'parameters'
              },
              {
                type: 'textarea',
                label: 'Unit',
                key: 'units'
              },
              {
                type: 'input',
                label: 'Current FY',
                key: 'current',
                inputType: 'number'
              },
              {
                type: 'input',
                label: 'Last FY',
                key: 'last',
                inputType: 'number'
              }
            ]
          },
          {
            type: 'textarea',
            label: `With respect to the ecologically sensitive areas reported at Question 10 of Essential Indicators
            above, provide details of significant direct and indirect impact of the Company on biodiversity in
            such areas along-with prevention and remediation activities.`,
            key: 'remediationActivities'
          },
          {
            type: 'table',
            label: `If the Company has undertaken any specific initiatives or used innovative technology or solutions
              to improve resource efficiency, or reduce impact due to emissions / effluent discharge / waste
              generated, please provide details of the same as well as outcome of such initiatives, as per the
              following format:`,
            key: 'innovativeInitiatives',
            options: [
              {type: 'text', label: Sr_no, key: ''},
              {
                type: 'textarea',
                label: 'Initiative undertaken',
                key: 'initiativeUndertaken'
              },
              {
                type: 'textarea',
                label: `Details of the initiative (Web-link, if any,
                            may be provided along-with summary)`,
                key: 'detailsInitiative'
              },
              {
                type: 'textarea',
                label: 'Outcome of the initiative',
                key: 'initiativeOutcome'
              }
            ]
          },
          {
            type: 'textarea',
            label: `Does the Company have a business continuity and disaster management plan? Give details in 100
                  words/ web link.`,
            key: 'businessActivities'
          },
          {
            type: 'textarea',
            label: `Disclose any significant adverse impact to the environment, arising from the value chain of the
            Company. What mitigation or adaptation measures have been taken by the Company in this regard?`,
            key: 'adaptationActivities'
          },
          {
            type: 'textarea',
            label: `Percentage of value chain partners (by value of business done with such partners) that were assessed
            for environmental impacts.`,
            key: 'environmentalActivities'
          }
        ]}
        title='Principle 6 : Businesses Should Respect And Make Efforts To Protect And Restore The Environment'
        onSubmit={handleSubmit}
      />
    </Box>
  );
};

export default Principle6;
