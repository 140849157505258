/* eslint-disable no-console */
import axios, {AxiosResponse} from 'axios';

/**
 * Retrieves continents
 * @returns {Promise<any>} A promise that resolves to an array of continents.
 */

/** Add Promise type and define return type as BenchMark[] */
const getCommunityData = async (): Promise<any> => {
  const API_BASE_PATH = process.env.REACT_APP_COMMUNITY;

  try {
    // Use AxiosResponse to add type to response data
    const response: AxiosResponse<any> = await axios.get(`${API_BASE_PATH}`);

    return response;
  } catch (error) {
    console.error('Error fetching data:', error);
    /** Throw the error to be caught by the calling function */
    throw error;
  }
};

export default getCommunityData;
