import getWidgetsService from 'Services/Widget';

import Widget from '../Interfaces/Widget';

/**
/**
 * Retrieves the Widget array data by calling getWidgetService asynchronously.
 *
 * @returns A Promise that resolves to the Widget data or undefined if an error occurs.
 */
const getWidgets = async (): Promise<Widget[] | undefined> => {
    try {
        const res = await getWidgetsService();
        if (res) {
            return res;
        }
    } catch (error) {
        console.error('Error fetching benchmark data:', error);
    }
};

export default getWidgets;
