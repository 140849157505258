import {Box} from '@mui/material';
import TotalAndUnitTypes from 'Components/Shared/Interfaces/TotalAndUnitTypes';
import React from 'react';

const TotalCarbonBanner: React.FC<{
  TotalandUnit: TotalAndUnitTypes;
}> = ({TotalandUnit}) => {
  return (
    <Box className='dashFloat-data'>
      <Box>{TotalandUnit.total}</Box>
      <Box className='amount-txt'>{TotalandUnit.unit}</Box>
    </Box>
  );
};

export default TotalCarbonBanner;
