/* eslint-disable no-console */
import moment from 'moment';
import getCommunity from 'Services/getCommunity';

import BlogImg from '../../../Assets/logo.svg';

const getCommunityData = async (): Promise<any> => {
  try {
    const res = await getCommunity();
    if (res) {
      const data = [...res.data];
      data.shift();

      const fiteredData = data.map((item: any) => {
        return {
          header: item?.title?.rendered,
          author: 'Anonymous',
          description: item?.excerpt?.rendered?.replace(
            /<\/?p>|\[.*?\]|\n/g,
            ''
          ),
          date: moment(item?.date).format('MMM YYYY'),
          read_time: Math.round(Math.random() * 3 + 2),
          link: item?.link,
          img:
            item?._embedded?.['wp:featuredmedia']?.[0]?.media_details?.sizes
              ?.medium?.source_url || BlogImg
        };
      });

      return fiteredData;
    }
  } catch (error) {
    console.error('Error fetching:', error);
  }
};

export default getCommunityData;
