import {Box} from '@mui/material';
import FormBuilder from 'Components/Shared/FormBuilder';
import React from 'react';

const Principle1: React.FC = () => {
  const companyId = window?.bharatCarbon?.company?._id;
  const handleSubmit = (data: any) => {
    data.companyId = companyId;
    // eslint-disable-next-line no-console
    console.log(data);
  };

  return (
    <Box sx={{padding: '30px 24px 24px 24px'}}>
      <FormBuilder
        fields={[
          {type: 'div', label: 'ESSENTIAL INDICATORS', key: ''},
          {
            type: 'table',
            label: `Percentage coverage by training and awareness programmes 
            on any of the Principles during the financial year:`,
            key: 'trainingPercent',
            static: [
              'Board of Directors',
              'Key Managerial Personnel',
              `Employees other than Board of Directors
            and KMPs`
            ],
            options: [
              {type: 'box', label: 'Segment', key: ''},
              {
                type: 'textarea',
                label: `Total number
            of training and
            awareness
            programs held`,
                key: 'totalTrainingProgramDetails'
              },
              {
                type: 'textarea',
                label: `Topics / principles
                covered under the
                training and its
                impact`,
                key: 'trainingTopicsDetails'
              },
              {
                type: 'textarea',
                label: `% age of persons in
                respective category
                covered by awareness
                program`,
                key: 'agePercent'
              }
            ]
          },
          {
            type: 'textarea',
            label: `Details of fines/penalties /punishment /award /compounding fees/settlement amount paid in
            proceedings (by the Company or by directors / KMPs) with regulators/ law enforcement agencies/
            judicial institutions, in the financial year, in the following format`,
            key: 'penaltiesDetails'
          },
          {
            type: 'textarea',
            label: `Of the instances disclosed in above question, details of the Appeal/ Revision preferred in cases
            where monetary or non-monetary action has been appealed.`,
            key: 'penaltiesMonetaryDetails'
          },
          {
            type: 'textarea',
            label: `Does the Company have an anti-corruption or anti-bribery policy? If
             yes, provide details in brief and
            if available, provide a web-link to the policy.`,
            key: 'antiCorruptionDetails'
          },
          {
            type: 'table',
            label: `Number of Directors/KMPs/employees/workers against whom disciplinary action was taken by any
            law enforcement agency for the charges of bribery/ corruption`,
            key: 'noDirectorPenalties',
            static: ['Directors', 'KMPs', 'Employees'],
            options: [
              {
                type: 'input',
                label: 'Last FY',
                key: 'lastFYNo',
                inputType: 'number'
              },
              {
                type: 'input',
                label: 'Current FY',
                key: 'currentFYNo',
                inputType: 'number'
              }
            ]
          },
          {
            type: 'table',
            label: 'Details of complaints regarding conflict of interest:',
            key: 'complaintsConflictOfinterest',
            static: [
              `Number of complaints received in relation to issues of
            Conflict of Interest of the Directors`,
              `Number of complaints received in relation to issues of
            Conflict of interest of the KMPs`
            ],
            options: [
              {
                type: 'input',
                label: 'Number in Last FY',
                key: 'noOfComplaints',
                inputType: 'number'
              },
              {
                type: 'text',
                label: 'Remarks for last FY',
                key: 'complaintsRemark',
                inputType: 'number'
              },
              {
                type: 'input',
                label: 'Number in current FY',
                key: 'noOfComplaints',
                inputType: 'number'
              },
              {
                type: 'text',
                label: 'Remarks for Current FY',
                key: 'complaintsRemark',
                inputType: 'number'
              }
            ]
          },
          {
            type: 'textarea',
            label: `Details of any corrective action taken or 
            underway on issues related to fines / penalties / action taken
            by regulators/ law enforcement agencies/ judicial institutions, on cases of corruption and conflicts
            of interest`,
            key: 'issueRelatedPenalties'
          },
          {type: 'div', label: 'LEADERSHIP INDICATORS', key: ''},
          {
            type: 'textarea',
            label: `Awareness programmes conducted for value chain partners on any of the Principles during the
          financial year:`,
            key: 'awarenessPrograms'
          },
          {
            type: 'textarea',
            label: `Company’s processes in place to avoid/manage 
            conflict of interests involving members of the Board?`,
            key: 'conflictsProcess'
          }
        ]}
        title='Principle 1 : Businesses Should Conduct And Govern Themselves With
        Integrity, And In A Manner That Is Ethical, Transparent And Accountable.'
        onSubmit={handleSubmit}
      />
    </Box>
  );
};

export default Principle1;
