import TotalAndUnitTypes from 'Components/Shared/Interfaces/TotalAndUnitTypes';

const calculateTotal: (array: any) => TotalAndUnitTypes = (array) => {
  const filteredArray = array.filter((obj: any) => {
    return obj.name ? obj.name !== '' : true;
  });

  const totalValue: number = filteredArray.reduce((acc: number, item: any) => {
    return item?.emissionIntensity >= 0
      ? acc + item?.emissionIntensity
      : acc + item?.value;
  }, 0);

  const unitBool: boolean = filteredArray.some(
    (obj: any) => obj.unit === 'TCo2'
  );

  return {
    total: Math.round(Number(totalValue.toFixed(2))),
    unit: unitBool ? 'Total TCo2' : 'Total KgCo2'
  };
};

export default calculateTotal;
