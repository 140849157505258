import axios from 'axios';

const API_BASE_PATH = process.env.REACT_APP_API_PATH;
export const generatePaymentLink: (
  allpaymentData: any
) => Promise<string> = async (allpaymentData) => {
  const data = {paymentData: {...allpaymentData}};

  return await axios
    .post(`${API_BASE_PATH}/pay`, data)
    .then((res) => {
      return res.data.res.url;
    })
    .catch((err) => {
      return err;
    });
};
