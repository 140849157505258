import {Box} from '@mui/material';
import FormBuilder from 'Components/Shared/FormBuilder';
import React from 'react';

const Principle4: React.FC = () => {
  const companyId = window?.bharatCarbon?.company?._id;
  const handleSubmit = (data: any) => {
    data.companyId = companyId;
    // eslint-disable-next-line no-console
    console.log(data);
  };

  return (
    <Box sx={{padding: '30px 24px 24px 24px'}}>
      <FormBuilder
        fields={[
          {type: 'div', label: 'ESSENTIAL INDICATORS', key: ''},
          {
            type: 'textarea',
            label: `Describe the processes for identifying key 
            stakeholder groups of the Company.`,
            key: 'processesStakeholder'
          },
          {
            type: 'table',
            label: `List stakeholder groups identified as key for your Company and the frequency of engagement with
            each stakeholder group.`,
            key: 'listOfStakeholders',
            options: [
              {
                type: 'box',
                label: `Stakeholder
            Group`,
                key: 'groupd'
              },
              {
                type: 'textarea',
                label: `Whether identified
                as Vulnerable and
                Marginalised Group (Yes/
                No)`,
                key: 'vulnerableGroup'
              },
              {
                type: 'textarea',
                label: `Channels of
                communication
                (Email,SMS,
                Newspaper, Pamphlets,
                Advertisement,
                Community, Meetings,
                Notice Board, Website),
                Others`,
                key: 'communicationChannels'
              },
              {
                type: 'textarea',
                label: `Frequency of engagement
                (Annually/ Half yearly/
                Quarterly /others – please
                specify)`,
                key: 'frequencyOfEngagement'
              },
              {
                type: 'textarea',
                label: `Purpose and scope of
                engagement including
                key topics and concerns
                raised during such
                engagement`,
                key: 'scopeOfEngagement'
              }
            ],
            static: [
              `Shareholders
            and Investors`,
              `Government
            and
            Regulators`,
              `Business
            Partners and
            Vendors`,
              'Employees',
              'Communities',
              'Customers'
            ]
          },
          {type: 'div', label: 'LEADERSHIP INDICATORS', key: ''},
          {
            type: 'textarea',
            label: `Provide the processes for consultation between stakeholders and the Board on economic,
            environmental, and social topics or if consultation is delegated, how is feedback from such
            consultations provided to the Board.`,
            key: 'processesForConsultation'
          },
          {
            type: 'textarea',
            label: `Whether stakeholder consultation is used to support the identification and management of
            environmental, and social topics (Yes / No). If so, provide details of instances as to how the inputs
            received from stakeholders on these topics were incorporated into policies and activities of the
            Company.`,
            key: 'stakeholderConsultation'
          },
          {
            type: 'textarea',
            label: `Provide details of instances of engagement with, and actions taken to, address the concerns of
            vulnerable/ marginalised stakeholder groups.`,
            key: 'instancesOfEngagement'
          }
        ]}
        title='Principle 4 : Businesses Should Respect The Interests Of And Be Responsive To All Its Stakeholders'
        onSubmit={handleSubmit}
      />
    </Box>
  );
};

export default Principle4;
