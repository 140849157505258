import {Box} from '@mui/material';
import React from 'react';

import {SCATTER_QUADRANT_CONSTANTS} from '../Constants/ScatterQuadrant';

const ScatterQuadrantLegend: React.FC = () => {
	return (
		<Box display="flex">
			{SCATTER_QUADRANT_CONSTANTS.map((entry, index) => (
				<Box className="legendBox" key={index}>
					<span>{entry?.name}</span>
				</Box>
			))}
		</Box>
	);
};

export default ScatterQuadrantLegend;
