/* eslint-disable @typescript-eslint/quotes */
/* eslint-disable no-console */
import {Box} from '@mui/material';
import {options} from 'Components/Shared/Constants/FormBuilder';
import FormBuilder from 'Components/Shared/FormBuilder';
import moment from 'moment';
import React from 'react';

const SectionA: React.FC = () => {
  const companyId = window?.bharatCarbon?.company?._id;
  // const [financialYear, setFinancialYear] = useState<number>(
  //   moment().get('year')
  // );
  // const [lastYear, setlastYear] = useState<number>(moment().get('year') - 1);
  // const [secondLastYear, setSecondLastYear] = useState<number>(
  //   moment().get('year') - 2
  // );
  const handleSubmit = (data: any) => {
    data.companyId = companyId;
    // eslint-disable-next-line no-console
    console.log(data);
  };

  // useEffect(() => {
  //   setlastYear(financialYear - 1);
  //   setSecondLastYear(financialYear - 2);
  // }, [financialYear]);

  const {Sr_no} = options;

  return (
    <Box sx={{padding: '30px 24px 24px 24px'}}>
      <FormBuilder
        fields={[
          {type: 'div', label: 'Details', key: ''},
          {type: 'text', label: 'CIN', key: 'cin'},
          {type: 'text', label: 'Company Name', key: 'entityName'},
          {
            type: 'date',
            label: 'Year of incorporation',
            key: 'incorpYear',
            options: {views: ['year'], inputFormat: 'YYYY', maxDate: moment()}
          },
          {
            type: 'textarea',
            label: 'Registered office address',
            key: 'officeAddr'
          },
          {
            type: 'textarea',
            label: 'Corporate office address',
            key: 'corpAddr'
          },
          {
            type: 'input',
            inputType: 'email',
            label: 'Email ID',
            key: 'email'
          },
          {
            type: 'input',
            label: 'Telephone',
            key: 'phone',
            inputType: 'number'
          },
          {
            type: 'text',
            label: 'Website',
            key: 'website'
          },
          {
            type: 'date',
            label: 'Financial year for which reporting is being done',
            key: 'financialYear',
            options: {views: ['year'], inputFormat: 'YYYY', maxDate: moment()}
          },
          {
            type: 'textarea',
            label: 'Name of stock exchange where shares are listed',
            key: 'stockExchanges'
          },
          {
            type: 'input',
            label: 'Paid-up Capital',
            key: 'paidUpCapital',
            inputType: 'number'
          },
          {
            type: 'table',
            label: 'Name and contact details of whom to contact',
            key: 'contactPersonInfo',
            options: [
              {type: 'text', label: Sr_no, key: ''},
              {type: 'text', label: 'Name', key: 'name'},
              {type: 'text', label: 'Designation', key: 'designation'},
              {type: 'text', label: 'Email', key: 'email'},
              {type: 'text', label: 'Phone', key: 'phone'}
            ]
          },
          {
            type: 'textarea',
            label: 'Reporting boundary',
            key: 'reportingBoundary'
          },
          {type: 'div', label: 'Products / Services', key: ''},
          {
            type: 'table',
            label: 'Details of business activites',
            options: [
              {type: 'text', label: Sr_no, key: ''},
              {
                type: 'text',
                label: 'Description of main activity',
                key: 'mainDesc'
              },
              {
                type: 'text',
                label: 'Description of business activity',
                key: 'BusinessDesc'
              },
              {
                type: 'text',
                label: '% of turnover of the company',
                key: 'turnoverPercentage'
              }
            ],
            key: 'businessActivities'
          },
          {
            type: 'table',
            label:
              'Products/Services sold by the Company (accounting for 90% of the Companys turnover)',
            options: [
              {type: 'text', label: Sr_no, key: ''},
              {type: 'text', label: 'Product/Service', key: 'product'},
              {
                type: 'input',
                label: 'NIC Code',
                key: 'nicCode',
                inputType: 'number'
              },
              {
                type: 'input',
                label: '% of total turnover contributed',
                key: 'turnoverContributed',
                inputType: 'number'
              }
            ],
            key: 'soldProducts'
          },
          {
            type: 'table',
            label:
              'Number of locations where plants and/or operations/offices of the Company are situated',
            static: ['National', 'International'],
            options: [
              {
                type: 'box',
                label: 'Locations',
                key: 'location'
              },
              {
                type: 'text',
                label: 'Number of plants',
                key: 'NumPlants'
              },
              {
                type: 'text',
                label: 'Number of offices',
                key: 'NumOffices'
              },
              {type: 'text', label: 'Total', key: 'total'}
            ],
            key: 'situatedLocations'
          },
          {
            type: 'table',
            label: 'Markets served by the Company:',
            static: ['National', 'International'],
            options: [
              {
                type: 'box',
                label: 'Locations',
                key: 'location'
              },
              {
                type: 'text',
                label: 'Number',
                key: 'NumStates'
              }
            ],
            key: 'marketServedLocations'
          },
          {
            type: 'input',
            label:
              'What is the contribution of exports as a percentage of the total turnover of the Company?',
            key: 'exportTurnover',
            inputType: 'number'
          },
          {
            type: 'textarea',
            label: 'A brief on types of customers?',
            key: 'briefCustomerTypes'
          },

          {
            type: 'div',
            label: 'Details as at the end of Financial Year:,',
            key: ''
          },
          {
            type: 'table',
            label: 'Employees (including differently abled):',
            static: [
              'Permanent (D)',
              'Other than Permanent employees (on fixed term contract) (E)',
              'Total employees (D + E)'
            ],
            options: [
              {
                type: 'box',
                label: 'Particulars',
                key: 'type'
              },
              {
                type: 'text',
                label: 'Total (A)',
                key: 'total'
              },
              {
                type: 'text',
                label: 'Number of Employees Male (B)',
                key: 'maleCount'
              },
              {
                type: 'text',
                label: '% (B/A)',
                key: 'malePercent'
              },
              {
                type: 'text',
                label: 'Number of Employees female (C)',
                key: 'femaleCount'
              },
              {
                type: 'text',
                label: '% (C/A)',
                key: 'femalePercent'
              }
            ],
            key: 'detailsOfEndOfFinancialYear'
          },
          {
            type: 'table',
            label: 'Differently abled employees:',
            static: [
              'Permanent (D)',
              'Other than Permanent (E)',
              'Total differently abled employees (D + E)'
            ],
            options: [
              {
                type: 'text',
                label: 'Particulars',
                key: 'type'
              },
              {
                type: 'text',
                label: 'Total (A)',
                key: 'total'
              },
              {
                type: 'text',
                label: 'Number of Employees Male (B)',
                key: 'maleCount'
              },
              {
                type: 'text',
                label: '% (B/A)',
                key: 'malePercent'
              },
              {
                type: 'text',
                label: 'Number of Employees female (C)',
                key: 'femaleCount'
              },
              {
                type: 'text',
                label: '% (C/A)',
                key: 'femalePercent'
              }
            ],
            key: 'disabledEmployees'
          },
          {
            type: 'table',
            label: 'Participation/Inclusion/Representation of women:',
            static: ['Board of Directors', 'Key Management Personnel'],
            options: [
              {
                type: 'text',
                label: 'Particulars',
                key: 'type'
              },
              {
                type: 'text',
                label: 'Total (A)',
                key: 'total'
              },
              {
                type: 'text',
                label: 'Number of Employees female (B)',
                key: 'femaleCount'
              },
              {
                type: 'text',
                label: '% (B/A)',
                key: 'femalePercent'
              }
            ],
            key: 'womenRepresentation'
          },
          {
            type: 'table',
            label: 'Turnover rate for permanent employees:',
            options: [
              {type: 'text', label: Sr_no, key: ''},
              {
                type: 'text',
                label: 'Particulars',
                key: 'type'
              },
              {
                type: 'text',
                label: `Male in Second Last FY ( % )`,
                key: 'maleSecondLastYear'
              },
              {
                type: 'text',
                label: `Female in Second Last FY ( % )`,
                key: 'femaleSecondLastYear'
              },
              {
                type: 'text',
                label: `Total in Second Last FY ( % )`,
                key: 'totalSecondLastYear'
              },
              {
                type: 'text',
                label: `Male in Last FY ( % )`,
                key: 'maleLastYear'
              },
              {
                type: 'text',
                label: `Female in Last FY ( % )`,
                key: 'femaleLastYear'
              },
              {
                type: 'text',
                label: `Total in Last FY ( % )`,
                key: 'totalLastYear'
              },
              {
                type: 'text',
                label: `Male in FY ( % )`,
                key: 'maleCurrentYear'
              },
              {
                type: 'text',
                label: `Female in FY ( % )`,
                key: 'femaleCurrentYear'
              },
              {
                type: 'text',
                label: `Total in FY ( % )`,
                key: 'totalCurrentYear'
              }
            ],
            key: 'employeeAndWorkerTurnoverRate'
          },
          {
            type: 'div',
            label:
              'HOLDING, SUBSIDIARY AND ASSOCIATE COMPANIES (INCLUDING JOINT VENTURES).',
            key: ''
          },
          {
            type: 'table',
            label:
              'Names of holding / subsidiary / associate companies / joint ventures (As on 31 March 2022):',
            options: [
              {type: 'text', label: Sr_no, key: ''},
              {
                type: 'text',
                label: `Name of the holding /
              subsidiary / associate
              companies / joint
              ventures
              (A)`,
                key: 'holdingName'
              },
              {
                type: 'text',
                label: `Indicate whether
                holding/ Subsidiary/
                Associate/
                Joint Venture`,
                key: 'holdingType'
              },
              {
                type: 'text',
                label: `% of shares held by
                listed entity`,
                key: 'holdingPercent'
              },
              {
                type: 'checkBox',
                label: `Does the Company indicated
                at column A, participate in
                the Business Responsibility
                initiatives of the listed
                Company?
                (Yes/No)`,
                key: 'isInitiatives'
              }
            ],
            key: 'associateCompanies'
          },
          {type: 'div', label: 'CSR DETAILS', key: ''},
          {
            type: 'checkBox',
            label: `Whether Corporate Social Responsibility
          (CSR) is applicable as per section 135 of Companies Act,
             2013 (Yes/No)`,
            key: 'isCsrApplicable'
          },
          {type: 'text', label: 'Turnover :', key: 'csrTurnover'},
          {type: 'text', label: 'Net worth :', key: 'csrNetWorth'},
          {
            type: 'div',
            label: 'TRANSPARENCY AND DISCLOSURES COMPLIANCES',
            key: ''
          },
          {
            type: 'table',
            label: `Complaints/Grievances on any of the principles (Principles 1 to 9) under
            the National Guidelines on Responsible
          Business Conduct :`,
            options: [
              {type: 'text', label: Sr_no, key: ''},
              {
                type: 'text',
                label: `Stakeholder
              group from whom
              complaint is
              received`,
                key: 'stakeholderGroup'
              },
              {
                type: 'checkBox',
                label: `Grievance
                Redressal Mechanism in Place
                (Yes/No) (If Yes, then provide
                web-link for grievance
                redress policy)`,
                key: 'isGrievanceMechanism'
              },

              {
                type: 'text',
                label: `Number of
                complaints
                filed
                during FY`,
                key: 'cfyComplaintsFiled'
              },
              {
                type: 'text',
                label: `Number of
                complaints
                pending
                resolution
                at close of
                FY`,
                key: 'cfyPendingComplaints'
              },
              {
                type: 'text',
                label: `Remarks for FY`,
                key: 'cfyRemarks'
              },
              {
                type: 'text',
                label: `Number of
                complaints
                filed
                during Last FY`,
                key: 'prevComplaintsFiled'
              },
              {
                type: 'text',
                label: `Number of
                complaints
                pending
                resolution
                at close of
                Last FY`,
                key: 'prevPendingComplaints'
              },
              {
                type: 'text',
                label: `Remarks for Last FY`,
                key: 'prevRemarks'
              }
            ],
            key: 'complaints'
          },
          {
            type: 'table',
            label:
              "Overview of the Company's material responsible business conduct issues",
            options: [
              {type: 'text', label: Sr_no, key: ''},
              {
                type: 'textarea',
                label: `Material issues
                Identified`,
                key: 'materialIssued'
              },
              {
                type: 'textarea',
                label: `Indicate
                whether
                risk or
                opportunity`,
                key: 'riskOrOpportunity'
              },

              {
                type: 'textarea',
                label: `Rationale for identifying the
                risk / opportunity`,
                key: 'identifyRiskOrOpportunity'
              },
              {
                type: 'text',
                label: `In case of risk, Approach to
                adapt or mitigate`,
                key: 'inRisk'
              },
              {
                type: 'text',
                label: `Financial implications of
                the risk or opportunity
                (Indicate positive or
                negative implications)`,
                key: 'implications'
              }
            ],
            key: 'complaints'
          }
        ]}
        onSubmit={handleSubmit}
        title='Section A'
        // setValues={{key: 'financialYear', func: setFinancialYear}}
      />
    </Box>
  );
};

export default SectionA;
