import {Box} from '@mui/material';
import React from 'react';

import LogoImg from '../../../Assets/logo.svg';

/**
 * Pure component to render the logo
 * @component
 */
const Logo: React.FC = () => {
	return (
		<Box className="logo">
			<img alt="" src={LogoImg}></img>
		</Box>
	);
};

export default Logo;
