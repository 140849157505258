import React from 'react';

import PieChartScopeProps from '../Interfaces/PieChartScopeProps';

const PieScopeCustomizedLegend: React.FC<{data: PieChartScopeProps[]}> = ({data}) => {
    return (
        <>
            <div className="custom-heading">TOP 3 FROM EACH</div>
            <ul className="recharts-default-legend" style={{padding: '0px', margin: '0px', textAlign: 'left'}}>
                {data?.map((e: PieChartScopeProps, index: number) => {
                    return e.name ? (
                        <div key={index} className="inner-data">
                            <li style={{display: 'flex', marginRight: '10px', alignItems: 'center'}}>
                                <span
                                    style={{
                                        width: '7px',
                                        height: '7px',
                                        backgroundColor: `${e.fill}`,
                                        borderRadius: '50%',
                                        marginRight: '5px'
                                    }}
                                ></span>
                                <span className="recharts-legend-item-text" style={{color: 'rgb(200, 162, 252)'}}>
                                    {e.name}
                                </span>
                            </li>
                            <div className="customText">
                                {e?.labelData
                                    .map((label, index) => {
                                        return (
                                            <div key={index}>
                                                {label?.name} ({label?.emissionIntensity}
                                                {label?.unit})
                                            </div>
                                        );
                                    })
                                }
                            </div>
                        </div>
                    ) : (
                        <div key={index}></div>
                    );
                })}
            </ul>
        </>
    );
};

export default PieScopeCustomizedLegend;
